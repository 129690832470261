/* Import Inventory */
import InventoryView from '../../components/superadmin/inventory/InventoryView.vue'
import Inventory from '../../components/superadmin/inventory/Inventory.vue'
import InventoryOp from '../../components/superadmin/inventory/InventoryOp.vue'

const routes = {
    path: 'inventory',
    component: InventoryView,
    children: [
        {
            path: '/',
            component: Inventory,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
        {
            path: 'inventory-op',
            component: InventoryOp,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
    ],
    meta: {
        requiresAuth: true,
        requiresAdmin: true
    }
}

export default routes;