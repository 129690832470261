/* Import Regions */
import BrandsView from '../../components/superadmin/brands/BrandsView.vue'
import Brands from '../../components/superadmin/brands/Brands.vue'
import BrandOp from '../../components/superadmin/brands/BrandOp.vue'
import ModelOp from '../../components/superadmin/brands/ModelOp.vue'

const routes = {
    path: 'brands',
    component: BrandsView,
    children: [
        {
            path: '/',
            component: Brands,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
        {
            path: 'brand-op',
            component: BrandOp,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
        {
            path: 'model-op',
            component: ModelOp,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
    ],
    meta: {
        requiresAuth: true,
        requiresAdmin: true
    }
}

export default routes;