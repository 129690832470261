<template>
    <div class="dashboard-component dashboard-component--gray dashboard-component--big">
        <div ref="inventoryItemsPopup" class="popup">
            <div ref="inventoryItemsPopupContainer" class="popup__container popup__container--big">
                <a @click.prevent="closeInventoryItemDialog" href="" class="popup__close-btn"><img :src="closeBtnImg" alt=""></a>
                <div class="popup__text popup__text--heading">Parça Ekle</div>
                <form action="" class="sidebyside__form" style="text-align: center">
                    <div class="sidebyside__form-group">
                        <input v-model="inventorySerialNumber" type="text" name="add-inventory-serial-number" id="add-inventory-serial-number" placeholder="Parça Seri No" autocomplete="off">
                        <div class="dropdown" :class="{'dropdown--active': inventoryItemsDropdown.length > 0}">
                            <div @click.prevent="selectInventoryItem(inventoryItem)" v-for="inventoryItem in inventoryItemsDropdown" :key="inventoryItem.inventorySerialNumber" class="dropdown__item">{{ inventoryItem.inventorySerialNumber }}</div>
                        </div>
                    </div>
                    <div class="sidebyside__form-group">
                        <input v-model="inventoryQuantity" type="number" name="add-inventory-equipment-quantity" id="add-inventory-equipment-quantity" placeholder="Miktar" autocomplete="off">
                    </div>
                    <h1 v-if="selectedInventoryItem" class="popup__text popup__text--bottom">İşlemden sonra kalacak stok: <span :class="{'success': selectedInventoryItem.inventoryQuantity - inventoryQuantity > 0, 'danger': selectedInventoryItem.inventoryQuantity - inventoryQuantity <= 0}">{{ selectedInventoryItem.inventoryQuantity - inventoryQuantity }}</span></h1>
                    <button @click.prevent="addNewInventoryItem" class="sidebyside__submit" style="margin-top: 1rem">Parçayı Ekle</button>
                </form>
            </div>
        </div>
        <div class="form">
            <div class="form-head">
                <img :src="tableImg" class="form-head__img">
                <h1 class="form-head__heading">Teknik Servis Formu<br></h1>
            </div>
            <div class="form-content">
                <div class="form-content__group">
                    <div class="form-content__selection-group form-content__selection-group--half">
                        <input class="form-content__input" v-model="hospitalName" type="text" name="create-form-hospital" id="create-form-hospital" placeholder="Müşteri Adı" autocomplete="off">
                        <div class="dropdown" :class="{'dropdown--active': hospitalsDropdown.length > 0}">
                            <div @click.prevent="selectHospital(hospital)" v-for="hospital in hospitalsDropdown" :key="hospital.hospitalID" class="dropdown__item">{{ hospital.hospitalName }}</div>
                        </div>
                    </div>
                    <div class="form-content__selection-group form-content__selection-group--half form-content__selection-group--half-end">
                        <input class="form-content__input" v-model="brandName" type="text" name="create-form-brand" id="create-form-brand" placeholder="Marka" autocomplete="off">
                        <div class="dropdown" :class="{'dropdown--active': brandsDropdown.length > 0}">
                            <div @click.prevent="selectBrand(brand)" v-for="brand in brandsDropdown" :key="brand.brandID" class="dropdown__item">{{ brand.brandName }}</div>
                        </div>
                    </div>
                </div>
                <div class="form-content__group">
                    <div class="form-content__selection-group form-content__selection-group--half">
                        <input class="form-content__input" v-model="modelName" type="text" name="create-form-model" id="create-form-model" placeholder="Model" autocomplete="off">
                        <div class="dropdown" :class="{'dropdown--active': modelsDropdown.length > 0}">
                            <div @click.prevent="selectModel(model)" v-for="model in modelsDropdown" :key="model.modelID" class="dropdown__item">{{ model.modelName }}</div>
                        </div>
                    </div>
                    <div class="form-content__selection-group form-content__selection-group--half form-content__selection-group--half-end">
                        <input class="form-content__input" v-model="deviceSerialNumber" type="text" name="create-form-device" id="create-form-device" placeholder="Cihaz Seri No." autocomplete="off">
                        <div class="dropdown" :class="{'dropdown--active': devicesDropdown.length > 0}">
                            <div @click.prevent="selectDevice(device)" v-for="device in devicesDropdown" :key="device.deviceID" class="dropdown__item">{{ device.deviceSerialNumber }}</div>
                        </div>
                    </div>
                </div>
                <div class="form-content__group">
                    <input v-model="formObj.staffInputObj.problem" type="text" class="form-content__input" placeholder="Problem" autocomplete="off">
                </div>
                <div class="form-content__group">
                    <textarea v-model="formObj.staffInputObj.solution" name="" id="" cols="30" rows="5" class="form-content__input" placeholder="Yürütülen Çalışma" autocomplete="off"></textarea>
                </div>
                <div class="form-content__group">
                    <input v-model="formObj.staffInputObj.dateInput" type="datetime-local" class="form-content__input form-content__input--third" placeholder="Tarih" autocomplete="off">
                    <input v-model="formObj.staffInputObj.onService" type="number" class="form-content__input form-content__input--third" placeholder="Servis Süresi" autocomplete="off">
                    <input v-model="formObj.staffInputObj.onRoad" type="number" class="form-content__input form-content__input--third form-content__input--third-end" placeholder="Seyahat Süresi" autocomplete="off">
                </div>
            </div>
            <div class="form-table">
                <div class="table-content">
                    <div class="table-content__header table-content__header--small">
                        <div class="table-content__header-item"><a @click.prevent="openInventoryItemDialog" href=""><img :src="addImg"></a>Parça Kodu</div>
                        <div class="table-content__header-item">Parça Adı</div>
                        <div class="table-content__header-item">Adet</div>
                    </div>
                    <transition name="fade" mode="out-in" v-if="formObj.equipmentsObj.length === 0">
                        <div style="padding-bottom: 0" class="table-not-found" key="1">
                            <img class="table-not-found__img table-not-found__img--small" :src="equipmentNotFoundImg" alt="Parçalar Bulunamadı">
                            <p class="table-not-found__text">Parça eklemek için parça kodu yanındaki artıya basın</p>
                        </div>
                    </transition>
                    <transition name="fade" mode="out-in" v-else>
                        <div>
                            <div v-for="equipment in formObj.equipmentsObj" :key="equipment.inventorySerialNumber" class="table-content__row table-content__header--small">
                                <div class="table-content__row-item">{{ equipment.inventorySerialNumber }}</div>
                                <div class="table-content__row-item">{{ equipment.equipmentName }}</div>
                                <div class="table-content__row-item">{{ equipment.inventoryQuantity }} 
                                    <a @click.prevent="formObj.equipmentsObj.splice(formObj.equipmentsObj.findIndex(equipmentItem => equipmentItem == equipment), 1);" href="" class="pocket__action-item pocket__action-item--red">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.89" height="17.654" viewBox="0 0 15.89 17.654">
                                            <path class="pocket__svg" d="M1711.531-8588.383a2.573,2.573,0,0,1-2.6-2.5l-.112-1.112-.2-2.155-.2-2.367-.229-2.84a.654.654,0,0,1,.592-.713.645.645,0,0,1,.68.516l.028.262.216,2.663.235,2.761c.106,1.194.2,2.161.27,2.84a1.282,1.282,0,0,0,1.343,1.319c2.164.048,4.393.05,6.713.007a1.3,1.3,0,0,0,1.4-1.333l.112-1.105c.032-.339.067-.715.106-1.125l.235-2.713.283-3.486a.656.656,0,0,1,.609-.606l.086,0a.653.653,0,0,1,.594.622l-.009.182-.279,3.4-.289,3.317c-.063.663-.117,1.223-.164,1.661a2.592,2.592,0,0,1-2.661,2.507q-1.711.032-3.36.032Q1713.2-8588.347,1711.531-8588.383Zm7.9-13.214h-11.784a.654.654,0,0,1-.646-.661.658.658,0,0,1,.557-.656l.089-.007h2.812a.774.774,0,0,0,.732-.537l.026-.1.218-1.114a1.759,1.759,0,0,1,1.551-1.324l.138-.007h3.644a1.755,1.755,0,0,1,1.654,1.229l.043.143.21,1.073a.78.78,0,0,0,.659.629l.1.006h2.814a.656.656,0,0,1,.646.663.661.661,0,0,1-.559.657l-.086,0Zm-1.913-1.324a2.088,2.088,0,0,1-.076-.23l-.032-.146-.2-1.032a.454.454,0,0,0-.365-.339l-.076-.009h-3.644a.468.468,0,0,0-.41.246l-.022.061-.209,1.073a2.122,2.122,0,0,1-.109.376Z" transform="translate(-1707 8606.001)" fill="#8d909d"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
            <div class="form-submit">
                <a :class="{'disabled-btn': dbController}" class="form-submit__btn" @click.prevent="createServiceForm"><div v-if="dbController" class="loading"></div>oluştur <img :src="downloadImg"></a>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap'
import store from '../../../store/store'
import { db, functions } from '../../../firebase/firebase'

export default {
    data() {
        return {
            dbController: false,

            /* Form Object */
            formObj: {
                staffObj: {
                    staffID: '',
                    staffName: ''
                },
                hospitalObj: {
                    hospitalID: '',
                    hospitalName: ''
                },
                brandObj: {
                    brandID: '',
                    brandName: ''
                },
                modelObj: {
                    modelID: '',
                    modelName: ''
                },
                deviceObj: {
                    deviceID: '',
                    deviceHospital: '',
                    deviceSerialNumber: '',
                    deviceProductionYear: ''
                },
                staffInputObj: {
                    problem: '',
                    solution: '',
                    dateInput: '',
                    date: '',
                    onService: '',
                    onRoad: ''
                },
                equipmentsObj: []
            },

            staffObj: {
                staffID: '',
                staffName: ''
            },

            /* Hospitals */
            hospitalsDropdown: [],
            hospitalName: '',

            /* Brands */
            brandsDropdown: [],
            brandName: '',

            /* Models */
            modelsDropdown: [],
            modelName: '',

            /* Devices */
            devicesDropdown: [],
            deviceSerialNumber: '',

            /* Equipments */
            inventoryItemsDropdown: [],
            selectedInventoryItem: null,
            inventorySerialNumber: '',
            inventoryQuantity: null,

            addImg: require('../../../assets/img/util/add-circle.svg'),
            tableImg: require('../../../assets/img/form/form.svg'),
            downArrowImg: require('../../../assets/img/util/down-arrow.svg'),
            downloadImg: require('../../../assets/img/util/green-download.svg'),
            closeBtnImg: require('../../../assets/img/util/close-btn.svg'),
            equipmentNotFoundImg: require('../../../assets/img/equipment/equipment-main.svg')
        }
    },
    computed: {
        hospitals() { return store.getters.hospitals },
        staffs() { return store.getters.staffs },
        brands() { return store.getters.brands },
        models() { return store.getters.models },
        devices() { return store.getters.devices },
        user() { return store.getters.user }
    },
    async created() {
        this.formObj.staffObj.staffID = this.user.uid;
        this.staffObj.staffID = this.user.uid;
        const staff =  await db.collection('staff_new').doc(this.user.uid).get();
        this.formObj.staffObj.staffName = staff.data().staffName;
        this.staffObj.staffName = staff.data().staffName;
    },
    destroyed() {
        store.commit('clearDevices');
    },
    methods: {
        async createServiceForm() {
            this.dbController = true;
            if(!this.validateStaff()) {
                store.commit('triggerError', 'Personel bilgileri alınamadı');
                this.dbController = false;
                return;
            }

            if(!this.validateStaff() || !this.validateHospital() || !this.validateBrand() || !this.validateModel() || !this.validateDevice() || !this.validateStaffInput()) {
                store.commit('triggerError', 'Lütfen tüm alanları doldurun');
                this.dbController = false;
                return;
            }

            if(this.formObj.staffInputObj.onRoad <= 0 || this.formObj.staffInputObj.onService <= 0) {
                store.commit('triggerError', 'Negatif süreler girilemez');
                this.dbController = false;
                return;
            }

            if(this.formObj.staffInputObj.date > new Date()) {
                store.commit('triggerError', 'Lütfen geçerli bir tarih girin');
                this.dbController = false;
                return;
            }

            if(!this.formObj || !this.formObj.equipmentsObj || this.formObj.equipmentsObj.length <= 0) {
                store.commit('triggerError', 'Lütfen en az bir parça seçin');
                this.dbController = false;
                return;
            }

            if(!this.validateInventoryItems()) {
                store.commit('triggerError', "Girilen bazı parçaların miktarı 0'dan küçük");
                this.dbController = false;
                return;
            }

            try {
                const createFormResponse = await functions.httpsCallable('createServiceForm')(this.formObj);
                if(createFormResponse.data.type == 'error')
                    throw new Error(createFormResponse.data.message);

                store.dispatch('generateServiceFormPDF', this.formObj);
                store.commit('triggerSuccess', createFormResponse.data.message);
                this.clearForm();
            } catch(err) { console.log(err); store.commit('triggerError', err)}

            this.dbController = false;
        },
        /* Validation */
        validateStaff() {
            return this.formObj && this.formObj.staffObj && this.formObj.staffObj.staffID && this.formObj.staffObj.staffName;
        },
        validateHospital() {
            return this.formObj && this.formObj.hospitalObj && this.formObj.hospitalObj.regionID && this.formObj.hospitalObj.hospitalID && this.formObj.hospitalObj.hospitalName;
        },
        validateBrand() {
            return this.formObj && this.formObj.brandObj && this.formObj.brandObj.brandID && this.formObj.brandObj.brandName;
        },
        validateModel() {
            return this.formObj && this.formObj.modelObj && this.formObj.modelObj.modelID && this.formObj.modelObj.modelName;
        },
        validateDevice() {
            return this.formObj && this.formObj.deviceObj && this.formObj.deviceObj.deviceID &&
            this.formObj.deviceObj.deviceHospital && this.formObj.deviceObj.deviceSerialNumber && this.formObj.deviceObj.deviceProductionYear;
        },
        validateStaffInput() {
            return this.formObj && this.formObj.staffInputObj && this.formObj.staffInputObj.problem &&
            this.formObj.staffInputObj.solution && this.formObj.staffInputObj.date &&
            this.formObj.staffInputObj.onService && this.formObj.staffInputObj.onRoad;
        },
        validateInventoryItems() {            
            for(let i = 0; i < this.formObj.equipmentsObj.length; i++)
                if(this.formObj.equipmentsObj[i].inventoryQuantity <= 0)
                    return false;

            return true;
        },
        /* Creating Dropdowns */
        createDropdownBrands() {
            if(!this.brandName || this.brandName === this.formObj.brandObj.brandName)
                this.brandsDropdown = [];
            else
                this.brandsDropdown = this.brands.filter(brand => brand.brandName.toLocaleLowerCase('tr-TR').search(this.brandName.toLocaleLowerCase('tr-TR')) > -1);
        },
        createDropdownModels() {
            if(!this.modelName || this.modelName === this.formObj.modelObj.modelName)
                this.modelsDropdown = [];
            else
                this.modelsDropdown = this.models.filter(model => model.modelName.toLocaleLowerCase('tr-TR').search(this.modelName.toLocaleLowerCase('tr-TR')) > -1 && model.brandID === this.formObj.brandObj.brandID);
        },
        createDropdownHospitals() {
            if(!this.hospitalName || this.hospitalName === this.formObj.hospitalObj.hospitalName)
                this.hospitalsDropdown = [];
            else
                this.hospitalsDropdown = this.hospitals.filter(hospital => hospital.hospitalName.toLocaleLowerCase('tr-TR').search(this.hospitalName.toLocaleLowerCase('tr-TR')) > -1);
        },
        async createDropdownDevices() {           
            try {
                if(!this.deviceSerialNumber || this.deviceSerialNumber === this.formObj.deviceObj.deviceSerialNumber) {
                    this.devicesDropdown = [];
                    return;
                }

                await store.dispatch('readDevices', { 
                    searchDevice: this.deviceSerialNumber,
                    hospitalID: this.formObj.hospitalObj.hospitalID,
                    brandID: this.formObj.brandObj.brandID,
                    modelID: this.formObj.modelObj.modelID
                });

                this.devicesDropdown = this.devices;
            } catch { store.commit('triggerError', 'Cihazlar okunamadı') }
        },
        async createDropdownInventoryItems() {
            try {
                if(!this.inventorySerialNumber || (this.selectedInventoryItem 
                && (this.inventorySerialNumber === this.selectedInventoryItem.inventorySerialNumber))) {
                    this.inventoryItemsDropdown = [];
                    return;
                }
                
                const query = await db.collectionGroup('inventory').orderBy('inventorySerialNumber')
                .where('inventorySerialNumber', '>=', this.inventorySerialNumber)
                .where('inventorySerialNumber', '<=', this.inventorySerialNumber + '\uf8ff')
                .where('inventoryBrand', '==', this.formObj.brandObj.brandID)
                .where('inventoryModel', '==', this.formObj.modelObj.modelID)
                .where('inventoryStaff', '==', this.formObj.staffObj.staffID).limit(5).get();

                this.inventoryItemsDropdown = [];
                query.forEach(inventoryItem => {
                    this.inventoryItemsDropdown.push({
                        ...inventoryItem.data()
                    });
                });

            } catch(err) { store.commit('triggerError', 'Parçalar okunamadı') }
        },
        /* Inventory Item Dialog */
        openInventoryItemDialog() {
            if(!this.formObj.brandObj.brandID || !this.formObj.modelObj.modelID) {
                store.commit('triggerError', 'Lütfen marka ve model seçin');
                return;
            }

            let tl = gsap.timeline();
            tl.to(this.$refs.inventoryItemsPopup, {duration: 0, display: 'flex'})
            .to(this.$refs.inventoryItemsPopup, {duration: .5, opacity: 1})
            .to(this.$refs.inventoryItemsPopupContainer, {duration: .5, opacity: 1, yPercent: -20}, '-=.4');
        },
        closeInventoryItemDialog() {
            let tl = gsap.timeline();
            tl.to(this.$refs.inventoryItemsPopupContainer, {duration: .5, opacity: 0, yPercent: 20})
            .to(this.$refs.inventoryItemsPopup, {duration: .5, opacity: 0}, '-=.4')
            .to(this.$refs.inventoryItemsPopup, {duration: 0, display: 'none', onComplete: () => { 
                this.inventoryItemsDropdown = [];
                this.selectedInventoryItem = null;
                this.inventorySerialNumber = '';
            }});
        },
        /* Selecting Elements */
        selectHospital(selectedHospital) {
            if(!selectedHospital) return;
            this.formObj.hospitalObj = {};
            this.formObj.hospitalObj.hospitalID = selectedHospital.hospitalID;
            this.formObj.hospitalObj.hospitalName = selectedHospital.hospitalName;
            this.formObj.hospitalObj.regionID = selectedHospital.regionID;
            this.hospitalName = selectedHospital.hospitalName;
            this.hospitalsDropdown = [];
        },
        selectBrand(selectedBrand) {
            if(!selectedBrand) return;
            this.formObj.brandObj = {};
            this.formObj.brandObj.brandID = selectedBrand.brandID;
            this.formObj.brandObj.brandName = selectedBrand.brandName;
            this.brandName = selectedBrand.brandName;
            this.brandsDropdown = [];
        },
        selectModel(selectedModel) {
            if(!selectedModel) return;
            this.formObj.modelObj = {};
            this.formObj.modelObj.modelID = selectedModel.modelID;
            this.formObj.modelObj.modelName = selectedModel.modelName;
            this.modelName = selectedModel.modelName;
            this.modelsDropdown = [];
        },
        selectDevice(selectedDevice) {
            if(!selectedDevice) return;
            this.formObj.deviceObj = {};
            this.formObj.deviceObj.deviceID = selectedDevice.deviceID;
            this.formObj.deviceObj.deviceHospital = selectedDevice.deviceHospital;
            this.formObj.deviceObj.deviceProductionYear = selectedDevice.deviceProductionYear;
            this.formObj.deviceObj.deviceSerialNumber = selectedDevice.deviceSerialNumber;
            this.deviceSerialNumber = selectedDevice.deviceSerialNumber;
            this.devicesDropdown = [];
        },
        selectInventoryItem(selectedInventoryItem) {
            if(!selectedInventoryItem) return;
            this.selectedInventoryItem = selectedInventoryItem;
            this.inventorySerialNumber = selectedInventoryItem.inventorySerialNumber;
            this.inventoryItemsDropdown = [];
        },
        async addNewInventoryItem() {
            try {
                if(!this.selectedInventoryItem || !this.inventoryQuantity)
                    return;

                if(this.inventoryQuantity <= 0) {
                    store.commit('triggerError', "Parça miktarı 0' büyük olmalı");
                    return;
                }

                if(this.formObj.equipmentsObj.find(inventoryItem => inventoryItem.inventorySerialNumber === this.selectedInventoryItem.inventorySerialNumber)) {
                    store.commit('triggerError', 'Bu parça zaten ekli');
                    return;
                }

                const equipment = await db.collectionGroup('equipments').where('equipmentID', '==', this.selectedInventoryItem.inventoryEquipment).get();
                if(equipment.empty) {
                    store.commit('triggerError', 'Eklemek istediğiniz parça bulunamadı');
                    return;
                }

                this.formObj.equipmentsObj.push({ ...this.selectedInventoryItem, inventoryQuantity: parseInt(this.inventoryQuantity), equipmentName: equipment.docs[0].data().equipmentName, equipmentCode: equipment.docs[0].data().equipmentCode});
                this.clearInventoryItem();
                this.closeInventoryItemDialog();
            } catch {
                store.commit('triggerError', 'Veritabanı hatası');
            }
        },
        /* Clear Methods */
        clearForm() {
            this.formObj = {
                staffObj: {
                    staffID: this.staffObj.staffID,
                    staffName: this.staffObj.staffName
                },
                hospitalObj: {
                    hospitalID: '',
                    hospitalName: ''
                },
                brandObj: {
                    brandID: '',
                    brandName: ''
                },
                modelObj: {
                    modelID: '',
                    modelName: ''
                },
                deviceObj: {
                    deviceID: '',
                    deviceHospital: '',
                    deviceSerialNumber: '',
                    deviceProductionYear: ''
                },
                staffInputObj: {
                    problem: '',
                    solution: '',
                    dateInput: '',
                    date: '',
                    onService: '',
                    onRoad: ''
                },
                equipmentsObj: []
            };

            /* Hospitals */
            this.hospitalsDropdown = [];
            this.hospitalName = '';

            /* Brands */
            this.brandsDropdown = [];
            this.brandName = '';

            /* Models */
            this.modelsDropdown = [];
            this.modelName = '';

            /* Devices */
            this.devicesDropdown = [];
            this.deviceSerialNumber = '';

            /* Equipments */
            this.inventoryItemsDropdown = [];
            this.selectedInventoryItem = null;
            this.inventorySerialNumber = '';
            this.inventoryQuantity = null;
        },
        clearBrand() {
            this.formObj.brandObj.brandName = '';
            this.formObj.brandObj.brandID = '';
        },
        clearModel() {
            this.formObj.modelObj.modelName = '';
            this.formObj.modelObj.modelID = '';
        },
        clearDevice() {
            this.formObj.deviceObj.deviceID = '';
            this.formObj.deviceObj.deviceHospital = '';
            this.formObj.deviceObj.deviceSerialNumber = '';
            this.formObj.deviceObj.deviceProductionYear = '';
        },
        clearInventoryItem() {
            this.selectedInventoryItem = null;
            this.inventoryItemsDropdown = [];
            this.inventoryQuantity = null;
        }
    },
    watch: {
        hospitalName() {
            this.createDropdownHospitals();
        },
        brandName() {
            if(this.formObj.brandObj.brandName !== this.brandName) {
                this.clearBrand();
                this.clearModel();
                this.clearDevice();
                this.modelName = '';
                this.deviceSerialNumber = '';
            }
            this.createDropdownBrands();
        },
        modelName() {
            if(this.formObj.modelObj.modelName !== this.modelName) {
                this.clearModel();
                this.clearDevice();
                this.deviceSerialNumber = '';
            }
            this.createDropdownModels();
        },
        async deviceSerialNumber() {
            await this.createDropdownDevices();
        },
        async inventorySerialNumber() {
            await this.createDropdownInventoryItems();
        },
        'formObj.staffInputObj.dateInput'() {
            this.formObj.staffInputObj.date = new Date(this.formObj.staffInputObj.dateInput);
        }
    }
}
</script>