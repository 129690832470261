/* Import Dashboards */
import GeneralDashboard from '../../components/general/dashboards/GeneralDashboard.vue'
import AdminDashboard from '../../components/general/dashboards/AdminDashboard.vue'
import StaffDashboard from '../../components/general/dashboards/StaffDashboard.vue'

/* Import Hospitals Devices And Fixeds */
import HospitalDevicesAndFixedsMain from '../../components/general/hospitals/HospitalDevicesAndFixedsMain.vue'

/* Import Staff And Inventory */
import StaffAndInventory from '../../components/general/staffs/StaffAndInventory.vue'

const routes = [
    {
        path: '/',
        component: GeneralDashboard,
        children: [
            {
                path: '/admin',
                component: AdminDashboard,
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true
                }
            },
            {
                path: '/staff',
                component: StaffDashboard,
                meta: {
                    requiresAuth: true,
                    requiresStaff: true
                }
            },
            {
                path: '/hospital/:hospitalID',
                component: HospitalDevicesAndFixedsMain,
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: '/staff/:staffID',
                component: StaffAndInventory,
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true
                }
            }
        ],
        meta: {
            requiresAuth: true,
        }
    },
]

export default routes;