<template>
    <div class="brands">
        <transition v-if="brands.length === 0" name="fade" mode="out-in">
            <div class="getting-started">
                <img class="getting-started__bg" :src="gettingStartedBackgroundImg" alt="">
                <div class="getting-started__container">
                    <img :src="gettingStartedIcon" alt="" class="getting-started__icon">
                    <h1 class="getting-started__heading">Markalar</h1>
                    <p class="getting-started__paragraph">Markalar'a hoşgeldiniz, yeni marka<br>eklemek için aşağıdaki butona tıklayın.</p>
                    <router-link to="/superadmin/brands/brand-op" class="getting-started__btn">marka ekle</router-link>
                </div>
            </div>
        </transition>
        <transition name="fade" mode="out-in" v-else>
            <div class="table" key="2">
                <div class="table-head">
                    <div class="table-head__left">
                        <img :src="tableImg" class="table-head__img">
                        <h1 class="table-head__heading">Markalar
                            <a @click.prevent="createBrand"><img :src="createImg" alt=""></a>
                            <a @click.prevent="updateBrand(brands[selectedBrand])"><img :src="updateImg" alt=""></a>
                            <a @click.prevent="deleteBrand(brands[selectedBrand])"><img :src="deleteImg" alt=""></a></h1>
                    </div>
                    <div class="table-head__right">
                        <div class="table-head__selection-group">
                            <img :src="downArrowImg" alt="">
                            <select v-model="selectedBrand" class="table-head__selection">
                                <option v-for="(brand, index) in brands" :key="brand.brandID" :value="index">{{ brand.brandName }}</option>
                            </select>
                        </div>
                        <input v-model="searchModel" type="text" class="table-head__input" placeholder="Ara...">
                    </div>
                </div>
                <div class="table-content">
                    <div class="table-content__header table-content__header--xlarge">
                        <div class="table-content__header-item">Model Adı <a @click.prevent="createModel(brands[selectedBrand])"><img :src="createImg" alt=""></a></div>
                        <div class="table-content__header-item">İşlemler</div>
                    </div>
                    <transition name="fade" mode="out-in" v-if="selectedModels.length === 0">
                        <div class="table-not-found" key="1">
                            <img class="table-not-found__img" :src="modelNotFountImg" alt="Modeller Bulunamadı">
                            <p class="table-not-found__text">Model eklemek için model adı yanındaki artıya basın</p>
                        </div>
                    </transition>
                    <transition name="fade" mode="out-in" v-else>
                        <div>
                            <div v-for="model in selectedModels" :key="model.modelID" class="table-content__row table-content__row--xlarge">
                                <div class="table-content__row-item">{{ model.modelName }}</div>
                                <div class="table-content__row-item">
                                    <a @click.prevent="updateModel(brands[selectedBrand], model)" href="" class="pocket__action-item pocket__action-item--blue">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                            <path class="pocket__svg" d="M1716.889-8589a.635.635,0,0,1-.622-.646.638.638,0,0,1,.539-.639l.084,0h6.489a.632.632,0,0,1,.622.644.638.638,0,0,1-.536.642l-.086,0Zm-9.267,0a.6.6,0,0,1-.449-.2.65.65,0,0,1-.173-.462l.1-3.942a2.942,2.942,0,0,1,.82-1.98l9.153-9.5a2.943,2.943,0,0,1,2.132-.916,2.943,2.943,0,0,1,2.132.916l1.222,1.271a3.168,3.168,0,0,1,.885,2.214,3.168,3.168,0,0,1-.885,2.214l-9.187,9.535a2.73,2.73,0,0,1-1.981.851Zm1.173-5.671a1.645,1.645,0,0,0-.455,1.1l-.08,3.278h3.125a1.53,1.53,0,0,0,1-.378l.1-.095,7.6-7.888-3.729-3.869Zm12.172-4.892.71-.737a1.869,1.869,0,0,0,.518-1.3,1.866,1.866,0,0,0-.518-1.3l-1.224-1.268a1.727,1.727,0,0,0-1.252-.54,1.728,1.728,0,0,0-1.253.54l-.71.737Z" transform="translate(-1707 8606.001)" fill="#8d909d"/>
                                        </svg>
                                    </a>
                                    <a @click.prevent="deleteModel(brands[selectedBrand], model)" href="" class="pocket__action-item pocket__action-item--red">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.89" height="17.654" viewBox="0 0 15.89 17.654">
                                            <path class="pocket__svg" d="M1711.531-8588.383a2.573,2.573,0,0,1-2.6-2.5l-.112-1.112-.2-2.155-.2-2.367-.229-2.84a.654.654,0,0,1,.592-.713.645.645,0,0,1,.68.516l.028.262.216,2.663.235,2.761c.106,1.194.2,2.161.27,2.84a1.282,1.282,0,0,0,1.343,1.319c2.164.048,4.393.05,6.713.007a1.3,1.3,0,0,0,1.4-1.333l.112-1.105c.032-.339.067-.715.106-1.125l.235-2.713.283-3.486a.656.656,0,0,1,.609-.606l.086,0a.653.653,0,0,1,.594.622l-.009.182-.279,3.4-.289,3.317c-.063.663-.117,1.223-.164,1.661a2.592,2.592,0,0,1-2.661,2.507q-1.711.032-3.36.032Q1713.2-8588.347,1711.531-8588.383Zm7.9-13.214h-11.784a.654.654,0,0,1-.646-.661.658.658,0,0,1,.557-.656l.089-.007h2.812a.774.774,0,0,0,.732-.537l.026-.1.218-1.114a1.759,1.759,0,0,1,1.551-1.324l.138-.007h3.644a1.755,1.755,0,0,1,1.654,1.229l.043.143.21,1.073a.78.78,0,0,0,.659.629l.1.006h2.814a.656.656,0,0,1,.646.663.661.661,0,0,1-.559.657l-.086,0Zm-1.913-1.324a2.088,2.088,0,0,1-.076-.23l-.032-.146-.2-1.032a.454.454,0,0,0-.365-.339l-.076-.009h-3.644a.468.468,0,0,0-.41.246l-.022.061-.209,1.073a2.122,2.122,0,0,1-.109.376Z" transform="translate(-1707 8606.001)" fill="#8d909d"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import store from '../../../store/store'
import router from '../../../router/router'

export default {
    data() {
        return {
            /* Brands */
            selectedBrand: 0,

            /* Searching */
            searchModel: '',
            searchModels: [],

            /* Images */
            tableImg: require('../../../assets/img/brand/brand.svg'),
            createImg: require('../../../assets/img/util/add-circle.svg'),
            updateImg: require('../../../assets/img/util/edit-dark.svg'),
            deleteImg: require('../../../assets/img/util/delete-dark.svg'),
            downArrowImg: require('../../../assets/img/util/down-arrow.svg'),
            gettingStartedBackgroundImg: require('../../../assets/img/brand/brand-bg.svg'),
            gettingStartedIcon: require('../../../assets/img/brand/brand-icon.svg'),
            modelNotFountImg: require('../../../assets/img/model/model-main.svg'),
        }
    },
    computed: {
        brands() { return store.getters.brands },
        models() { return store.getters.models },
        selectedModels() {
            if(!this.models) 
                return [];

            if(this.searchModel)
                return this.searchModels;

            return this.models.filter(model => model.brandID === this.brands[this.selectedBrand].brandID);
        }
    },
    methods: {
        /* Brand Operations */
        createBrand() {
            router.push({ path: '/superadmin/brands/brand-op' });
        },
        updateBrand(brand) {
            router.push({
                path: '/superadmin/brands/brand-op',
                query: { brandID: brand.brandID }});
        },
        async deleteBrand(brand) {
            try {
                if(!await store.dispatch('triggerPopup', 
                brand.brandName + " markasını silmek istiyor musunuz?"))
                    return;

                await store.dispatch('deleteBrand', brand.brandID);
                this.selectedBrand = 0;
                store.commit('triggerSuccess', 'Marka silindi');
            } catch(err) {
                store.commit('triggerError', err);
            }
        },
        /* Model Operations */
        createModel(brand) {
            router.push({ 
                path: '/superadmin/brands/model-op',
                query: { 
                    brandID: brand.brandID,
                    brandName: brand.brandName
                }
            });
        },
        updateModel(brand, model) {
            router.push({ 
                path: '/superadmin/brands/model-op',
                query: {
                    brandID: brand.brandID,
                    brandName: brand.brandName,
                    modelID: model.modelID
                }
            });
        },
        async deleteModel(brand, model) {
            try {
                if(!await store.dispatch('triggerPopup', 
                model.modelName + " modelini silmek istiyor musunuz?"))
                    return;

                await store.dispatch('deleteModel', {
                    brandID: brand.brandID,
                    modelID: model.modelID
                });

                store.commit('triggerSuccess', 'Model silindi');
            } catch(err) {
                store.commit('triggerError', err);
            }
        }
    },
    watch: {
        searchModel() {
            this.searchModels = this.models.filter(model => 
            model.modelName.toLocaleLowerCase('tr-TR').search(this.searchModel) > -1
            && model.brandID === this.brands[this.selectedBrand].brandID);
        }
    }
}
</script>