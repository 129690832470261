<template>
    <div class="add-container">
        <div class="sidebyside">
            <div class="sidebyside__left sidebyside__left--big">
                <div class="sidebyside__top sidebyside__top--center">
                    <div class="sidebyside__heading-container">
                        <img :src="fixedImg" alt="" class="sidebyside__heading-img">
                        <h1 class="sidebyside__heading">{{ operationHeadingText }}</h1>
                    </div>
                    <a @click.prevent="openExcelImportPopup" v-if="operationType === 'create'" href="" class="sidebyside__import-excel">excel import</a>
                </div>
                <form action="" class="sidebyside__form">
                    <div class="sidebyside__form-group">
                        <input v-model="fixedObj.fixedBrand" type="text" name="create-fixed-brand" id="create-fixed-brand" placeholder="Marka" autocomplete="off">
                    </div>
                    <div class="sidebyside__form-group">
                        <input v-model="fixedObj.fixedModel" type="text" name="create-fixed-model" id="create-fixed-model" placeholder="Model" autocomplete="off">
                    </div>
                    <div class="sidebyside__form-group">
                        <input v-model="fixedObj.fixedSerialNumber" type="text" name="create-fixed-serial-no" id="create-fixed-serial-no" placeholder="Seri No." autocomplete="off">
                    </div>
                    <div class="sidebyside__form-group">
                        <input v-model="hospitalName" type="text" name="create-fixed-hospital" id="create-fixed-hospital" placeholder="Bulunduğu Yer" autocomplete="off">
                        <div class="dropdown" :class="{'dropdown--active': hospitalsDropdown.length > 0}">
                            <div @click.prevent="selectHospital(hospital)" v-for="hospital in hospitalsDropdown" :key="hospital.hospitalID" class="dropdown__item">{{ hospital.hospitalName }}</div>
                        </div>
                    </div>
                    <button :class="{'disabled-btn': dbController}" @click.prevent="createUpdateFixed" class="sidebyside__submit"><div v-if="dbController" class="loading"></div>{{ operationButtonText }}</button>
                </form>
            </div>
            <div class="sidebyside__right">
                <img :src="fixedMainImg" alt="Yeni Demirbaş Ekle / Düzenle">
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'
import router from '../../../router/router'

export default {
    data() {
        return {
            dbController: false,

            /* Fixed */
            fixedObj: {
                fixedID: '',
                fixedBrand: '',
                fixedModel: '',
                fixedSerialNumber: '',
                fixedOriginalSerialNumber: '',
                hospital: {
                    hospitalName: '',
                    hospitalID: ''
                }
            },

            /* Hospitals */
            hospitalsDropdown: [],
            hospitalName: '',

            /* Operation Type */
            operationType: '',

            /* Operation Type Texts */
            operationButtonText: '',
            operationHeadingText: '',

            /* Images */
            fixedMainImg: require('../../../assets/img/fixed/fixed-main.svg'),
            fixedImg: require('../../../assets/img/fixed/fixed.svg'),
        }
    },
    async created() {
        this.fixedObj.fixedID = router.currentRoute.query.fixedID;
        if(this.fixedObj.fixedID) {
            try {
                this.operationType = 'update';
                this.fixedObj = (await store.dispatch('getFixedByID', this.fixedObj.fixedID));
                this.fixedObj.fixedOriginalSerialNumber = this.fixedObj.fixedSerialNumber;
                this.hospitalName = this.fixedObj.hospital.hospitalName;
            } catch(err) {
                store.commit('triggerError', err);
                this.redirectToFixed();
            }
        } else this.operationType = 'create';
    },
    computed: {
        hospitals() { return store.getters.hospitals }
    },
    methods: {
        createDropdownHospitals() {
            if(this.hospitalName === this.fixedObj.hospital.hospitalName)
                this.hospitalsDropdown = [];
            else
                this.hospitalsDropdown = this.hospitals.filter(hospital => hospital.hospitalName.toLocaleLowerCase('tr-TR').search(this.hospitalName.toLocaleLowerCase('tr-TR')) > -1);
        },
        selectHospital(selectedHospital) {
            if(!selectedHospital) return;
            this.fixedObj.hospital = {};
            this.fixedObj.hospital.hospitalID = selectedHospital.hospitalID;
            this.fixedObj.hospital.hospitalName = selectedHospital.hospitalName;
            this.hospitalName = selectedHospital.hospitalName;
            this.hospitalsDropdown = [];
        },        
        /* Excel Add */
        openExcelImportPopup() { 
            store.commit('updateJSONPopup', { 
                openDialog: true, 
                redirectFunction: this.redirectToFixed, 
                addFunction: this.createFixedWithoutRedirection
            });
        },
        async createFixedWithoutRedirection(fixedObj) {
            try {
                if(!fixedObj) {
                    store.commit('triggerError', 'Eklenecek demirbaş bulunamadı');
                    return;
                }

                await store.dispatch('createFixedJSON', fixedObj);
                store.commit('triggerSuccess', fixedObj.fixedSerialNumber + " seri no'lu demirbaş eklendi");
            } catch(err) { store.commit('triggerError', fixedObj.fixedSerialNumber  + " seri no'lu demirbaş eklenemedi") }
        },
        /* Fixed Operations */
        async createUpdateFixed() {
            this.dbController = true;
            if(this.operationType === 'create') await this.createFixed();
            else if(this.operationType === 'update') await this.updateFixed();
            this.dbController = false;
        },
        async createFixed() {
            try {
                await store.dispatch('createFixed', this.fixedObj);
                this.redirectToFixed();
                store.commit('triggerSuccess', 'Demirbaş eklendi');
            } catch(err) { store.commit('triggerError', err) }
        },
        async updateFixed() {
            try {
                await store.dispatch('updateFixed', this.fixedObj);
                this.redirectToFixed();
                store.commit('triggerSuccess', 'Demirbaş güncellendi');
            } catch(err) { store.commit('triggerError', err) }
        },
        /* Redirection */
        redirectToFixed() {
            router.push('/superadmin/fixed');
        }
    },
    watch: {
        operationType() {
            if(this.operationType === 'create') {
                this.operationButtonText = 'demirbaş ekle';
                this.operationHeadingText = 'Demirbaş Ekle';
            } else if(this.operationType === 'update') {
                this.operationButtonText = 'düzenlemeyi bitir';
                this.operationHeadingText = 'Demirbaşı Düzenle';
            }
        },
        hospitalName() {
            this.createDropdownHospitals();
        }
    }
}
</script>