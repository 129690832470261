/* eslint-disable no-unused-vars */
import { db } from '../firebase/firebase'
const brands = db.collection('brands_new');
const staffs = db.collection('staff_new');
const inventory = db.collectionGroup('inventory');

const inventoryStore = {
    state: () => ({
        inventoryEquipments: [],
        inventorySnapshot: null,
        inventoryLimit: 5
    }),
    mutations: {
        clearInventory(state) {
            if(state.inventorySnapshot)
                state.inventorySnapshot();
            
            state.inventoryEquipments = [];
            state.inventorySnapshot = null;
        }
    },
    actions: {
        async readInventoryEquipments({ commit, state }, queryObj = {}) {
            try {
                /* Constructing The Query */
                let query;
                if(queryObj.searchInventory) {
                    query = inventory.orderBy('inventorySerialNumber').where('inventorySerialNumber', '>=', queryObj.searchInventory)
                    .where('inventorySerialNumber', '<=', queryObj.searchInventory + '\uf8ff');
                } else query = inventory.orderBy('inventoryQuantity', 'desc');          

                if(queryObj.inventoryGeneral)
                    query = query.where('inventoryID', '==', "1");

                if(queryObj.inventoryStaff)
                    query = query.where('inventoryStaff', '==', queryObj.inventoryStaff);

                if(queryObj.inventoryBrand)
                    query = query.where('inventoryBrand', '==', queryObj.inventoryBrand);

                if(queryObj.inventoryModel)
                    query = query.where('inventoryModel', '==', queryObj.inventoryModel);

                if(queryObj.inventoryEquipment)
                    query = query.where('inventoryEquipment', '==', queryObj.inventoryEquipment);

                /* Pagination */
                if(queryObj.paginationType && state.inventoryEquipments.length > 0) {
                    const lastSeenDocument = queryObj.paginationType === 'next' ? 
                    state.inventoryEquipments[state.inventoryEquipments.length - 1].inventoryEquipmentRef : state.inventoryEquipments[0].inventoryEquipmentRef;
                    
                    if(queryObj.paginationType === 'next') query = query.startAfter(lastSeenDocument);
                    else if(queryObj.paginationType === 'prev') query = query.endBefore(lastSeenDocument);
                }

                if(!queryObj.paginationType || (queryObj.paginationType && !(await query.limit(1).get()).empty)) {
                    if(queryObj.paginationType === 'prev') query = query.limitToLast(state.inventoryLimit);
                    else query = query.limit(state.inventoryLimit);
                    
                    commit('clearInventory');
                    state.inventorySnapshot = query.onSnapshot(snapshot => {
                        snapshot.docChanges().forEach(async inventoryEquipment => {
                            const inventoryEquipmentDoc = inventoryEquipment.doc;
                            if(inventoryEquipment.type === 'added')
                                state.inventoryEquipments.push({
                                    inventoryEquipmentID: inventoryEquipmentDoc.id,
                                    inventoryEquipmentRef: inventoryEquipmentDoc,
                                    ...inventoryEquipmentDoc.data(),
                                    equipment: {
                                        equipmentID: inventoryEquipmentDoc.ref.parent.parent.id,
                                        ...(await inventoryEquipmentDoc.ref.parent.parent.get()).data()
                                    }
                                });

                            if(inventoryEquipment.type === 'removed')
                                state.inventoryEquipments.splice(state.inventoryEquipments.findIndex(inventoryEquipment => inventoryEquipment.inventoryEquipment === inventoryEquipmentDoc.data().inventoryEquipment), 1);

                            if(inventoryEquipment.type === 'modified') {
                                const equipmentIndex = state.inventoryEquipments.findIndex(inventoryEquipment => inventoryEquipment.inventoryEquipment === inventoryEquipmentDoc.data().inventoryEquipment);
                                state.inventoryEquipments.splice(equipmentIndex, 1);
                                state.inventoryEquipments.push({
                                    inventoryEquipmentID: inventoryEquipmentDoc.id,
                                    inventoryEquipmentRef: inventoryEquipmentDoc,
                                    ...inventoryEquipmentDoc.data(),
                                    equipment: {
                                        equipmentID: inventoryEquipmentDoc.ref.parent.parent.id,
                                        ...(await inventoryEquipmentDoc.ref.parent.parent.get()).data()
                                    }
                                });
                            }
                        });
                    });
                }
            } catch {
                throw new Error("Stok okunamadı");
            }
        },
        async outboundInventoryOperation({ dispatch }, inventoryObj) {
            try {
                inventoryObj.type = 'outbound';
                await dispatch('validateInventory', inventoryObj);
                const equipmentDocRef = inventoryObj.equipment.equipmentRef.ref;
                const mainInventory = equipmentDocRef.collection('inventory').doc("1");
                const targetInventory = equipmentDocRef.collection('inventory').doc(inventoryObj.inventoryStaff);

                await db.runTransaction(async transaction => {
                    let mainInventoryRead = await transaction.get(mainInventory);
                    let targetInventoryRead = await transaction.get(targetInventory);

                    if(!mainInventoryRead.exists) {
                        const mainInventoryObj = { inventoryRef: mainInventory, ...inventoryObj };
                        mainInventoryObj.inventoryStaff = null;
                        await dispatch('createInventory', mainInventoryObj);
                        mainInventoryRead = await transaction.get(mainInventory);
                    }

                    if(!targetInventoryRead.exists) {
                        await dispatch('createInventory', { inventoryRef: targetInventory, ...inventoryObj });
                        targetInventoryRead = await transaction.get(targetInventory);
                    }
                    
                    const newInventoryQuantity = mainInventoryRead.data().inventoryQuantity - inventoryObj.inventoryQuantity;
                    const newTargetInventoryQuantity = targetInventoryRead.data().inventoryQuantity + inventoryObj.inventoryQuantity;
                    transaction.update(mainInventory, { inventoryQuantity: newInventoryQuantity });
                    transaction.update(targetInventory, { inventoryQuantity: newTargetInventoryQuantity });
                });
            } catch(err) { throw new Error(err) }
        },
        async inboundInventoryOperation({ dispatch }, inventoryObj) {
            try {
                await dispatch('validateInventory', inventoryObj);
                const mainInventory = inventoryObj.equipment.equipmentRef.ref.collection('inventory').doc("1");

                await db.runTransaction(async transaction => {
                    let mainInventoryRead = await transaction.get(mainInventory);

                    if(!mainInventoryRead.exists) {
                        await dispatch('createInventory', { inventoryRef: mainInventory, ...inventoryObj });
                        mainInventoryRead = await transaction.get(mainInventory);
                    }
                    
                    const newInventoryQuantity = mainInventoryRead.data().inventoryQuantity + inventoryObj.inventoryQuantity;
                    transaction.update(mainInventory, { inventoryQuantity: newInventoryQuantity });
                });
            } catch(err) { console.log(err); throw new Error(err) }
        },
        async createInventory(context, inventoryObj) {
            try {
                await inventoryObj.inventoryRef.set({
                    inventoryID: inventoryObj.inventoryRef.id,
                    inventoryBrand: inventoryObj.equipment.equipmentRef.ref.parent.parent.parent.parent.id,
                    inventoryModel: inventoryObj.equipment.equipmentRef.ref.parent.parent.id,
                    inventoryEquipment: inventoryObj.equipment.equipmentID,
                    inventorySerialNumber: inventoryObj.equipment.equipmentSerialNumber,
                    inventoryQuantity: 0,
                    inventoryStaff: inventoryObj.inventoryStaff ? inventoryObj.inventoryStaff : null
                });
            } catch { throw new Error('Stok oluşturulamadı') }
        },
        async validateInventory(context, inventoryObj) {
            try {
                if(!inventoryObj || !inventoryObj.inventoryQuantity || (inventoryObj.type === 'outbound' && !inventoryObj.inventoryStaff) ||
                !inventoryObj.equipment || !inventoryObj.equipment.equipmentID || !inventoryObj.equipment.equipmentRef || !inventoryObj.equipment.equipmentSerialNumber)
                    throw new Error('Lütfen tüm alanları doldurun');

                inventoryObj.inventoryQuantity = parseInt(inventoryObj.inventoryQuantity);
                if(isNaN(inventoryObj.inventoryQuantity))
                    throw new Error('Stok miktarı sayı olmalı');

                if(inventoryObj.inventoryStaff && !(await staffs.doc(inventoryObj.inventoryStaff).get()).exists)
                    throw new Error('Personel bulunamadı');

                const brand = await brands.doc(inventoryObj.equipment.equipmentRef.ref.parent.parent.parent.parent.id).get();
                if(!brand.exists)
                    throw new Error('Parçanın markası bulunamadı');

                const model = await brand.ref.collection('models').doc(inventoryObj.equipment.equipmentRef.ref.parent.parent.id).get();
                if(!model.exists)
                    throw new Error('Parçanın modeli bulunamadı');

                const equipment = await model.ref.collection('equipments').doc(inventoryObj.equipment.equipmentID).get();
                if(!equipment.exists)
                    throw new Error('Parça bulunamadı');
            } catch(err) { throw new Error(err) }       
        }
    },
    getters: {
        inventoryEquipments(state) { return state.inventoryEquipments },
    }
}

export default inventoryStore;