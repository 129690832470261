/* Import Equipment */
import EquipmentsView from '../../components/superadmin/equipments/EquipmentsView.vue'
import Equipments from '../../components/superadmin/equipments/Equipments.vue'
import EquipmentOp from '../../components/superadmin/equipments/EquipmentOp.vue'

const routes = {
    path: 'equipments',
    component: EquipmentsView,
    children: [
        {
            path: '/',
            component: Equipments,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
        {
            path: 'equipment-op',
            component: EquipmentOp,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
    ],
    meta: {
        requiresAuth: true,
        requiresAdmin: true
    }
}

export default routes;