<template>
	<div id="app">
		<app-alert></app-alert>
		<app-popup></app-popup>
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
	</div>
</template>

<script>
import "@/scss/main.scss";
import Alert from './components/utils/alert/Alert.vue'
import Popup from './components/utils/popup/Popup.vue'

export default {
	name: 'App',
	components: {
		'app-alert': Alert,
		'app-popup': Popup,
	}
}
</script>