/* eslint-disable no-unused-vars */
import { db, functions } from '../firebase/firebase'
const staffs = db.collection('staff_new');

const staffsStore = {
    state: () => ({
        staffs: [],
        staffsSnapshot: null,
        createStaff: functions.httpsCallable('createStaff'),
        updateStaff: functions.httpsCallable('updateStaff'),
        deleteStaff: functions.httpsCallable('deleteStaff')
    }),
    mutations: {
        clearStaffs(state) {
            if(state.staffsSnapshot)
                state.staffsSnapshot();

            state.staffs = [];
        }
    },
    actions: {
        async readStaffs({ state }) {
            if(state.staffsSnapshot)
                state.staffsSnapshot();

            try {
                state.staffsSnapshot = staffs.orderBy('staffName').onSnapshot(snapshot => {
                    snapshot.docChanges().forEach(async staff => {
                        const staffDoc = staff.doc;
                        if(staff.type === 'added') {
                            state.staffs.push({
                                staffID: staffDoc.id,
                                ...staffDoc.data()
                            });
                        }

                        if(staff.type === 'removed')
                            state.staffs.splice(state.staffs.findIndex(staff => staff.staffID === staffDoc.id), 1);

                        if(staff.type === 'modified')
                            state.staffs[state.staffs.findIndex(staff => staff.staffID === staffDoc.id)].staffName = staffDoc.data().staffName;
                    });
                }); 
            } catch(err) {
                throw new Error("Personeller okunamadı");
            }
        },
        async createStaff({ state }, staffObj) {
            try {
                if(!staffObj.staffName || !staffObj.staffEmail || !staffObj.staffPassword)
                    throw new Error('Lütfen tüm alanları doldurun');

                if(staffObj.staffPassword.length < 6)
                    throw new Error('Şifre en az 6 karakter olmalı');

                /* eslint-disable-next-line no-useless-escape */
                const emailTest = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if(!emailTest.test(staffObj.staffEmail))
                    throw new Error('Email formatı yanlış');
                
                const createStaffResponse = await state.createStaff(staffObj);
                if(createStaffResponse.data.type === 'error')
                    throw new Error(createStaffResponse.data.message);
            } catch(err) {
                throw new Error(err);
            }
        },
        async updateStaffName(context, staffObj) {
            try {
                if(!staffObj.staffID || !staffObj.staffName)
                    throw new Error('Lütfen tüm alanları doldurun');

                const staff = await staffs.doc(staffObj.staffID).get();
                if(!staff.exists)
                    throw new Error('Personel bulunamadı');

                if(staffObj.staffOriginalName !== staffObj.staffName) {
                    let performances = await staff.ref.collection('performances')
                    .where('performanceStaffName', '==', staffObj.staffOriginalName).limit(500).get();
                    while(performances && performances.size > 0) {
                        try {
                            let batch = db.batch();
                            performances.forEach(performance => { batch.update(performance.ref, { staffName: staffObj.staffName }) });
                            await batch.commit();
                        } catch(err) { console.log(err); throw new Error('Personele ait bazı performanslar güncellenemedi') }
                        performances = await staff.ref.collection('performances')
                        .where('performanceStaffName', '==', staffObj.staffOriginalName).limit(500).get();
                    }
                }

                try { await staffs.doc(staffObj.staffID).update({ staffName: staffObj.staffName }) }
                catch(err) { throw new Error('Personel güncellenemedi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async updateStaffPassword({ state }, staffObj) {
            try {
                if(!staffObj.staffID || !staffObj.staffPassword)
                    throw new Error('Lütfen tüm alanları doldurun');

                if(staffObj.staffPassword.length < 6)
                    throw new Error('Şifre en az 6 karakter olmalı');
                
                const updateStaffResponse = await state.updateStaff(staffObj);
                if(updateStaffResponse.data.type === 'error')
                    throw new Error(updateStaffResponse.message);
            } catch(err) {
                throw new Error(err);
            }
        },
        async deleteStaff({ state }, staffID) {
            try {
                if(!staffID)
                    throw new Error("Personel ID'si boş");

                const deleteStaffResponse = await state.deleteStaff({ staffID });
                if(deleteStaffResponse.data.type === 'error')
                    throw new Error(deleteStaffResponse.data.message);
            
            } catch(err) {
                throw new Error(err);
            }
        },
        async getStaffByID(context, staffID) {
            try { return { staffID, ...(await staffs.doc(staffID).get()).data()} }
            catch { throw new Error('Personel bulunamadı') }
        },
    },
    getters: {
        staffs(state) { return state.staffs },
    }
}

export default staffsStore;