/* eslint-disable no-unused-vars */
import { db, staff } from '../firebase/firebase'
const forms = db.collection('forms_new');
const staffs = db.collection('staff_new');
const performance = db.collectionGroup('performance');

const staffInfoStore = {
    state: () => ({
        staffRecentOperations: [],
        staffPerformancesInfo: [],
        staffPerformanceInfo: { onRoad: 0, onService: 0, total: 0 },
        staffRecentOperationsSnapshot: null,
        staffPerformancesInfoSnapshot: null,
        staffInfoLimit: 5,
        staffPerformanceInfoLimit: 3
    }),
    mutations: {
        clearStaffRecentOperations(state) {
            if(state.staffRecentOperationsSnapshot)
                state.staffRecentOperationsSnapshot();
        
            state.staffRecentOperations = [];
        },
        clearStaffPerformancesInfo(state) {
            if(state.staffPerformancesInfoSnapshot)
                state.staffPerformancesInfoSnapshot();

            state.staffPerformancesInfo = [];
        },
        clearStaffPerformanceInfo(state) {
            state.staffPerformanceInfo = {
                onRoad: 0,
                onService: 0,
                total: 0
            }
        }
    },
    actions: {
        async readStaffRecentOperations({ state, commit }, queryObj = {}) {
            try {
                if(!queryObj.performanceStartDate || !queryObj.performanceEndDate || !queryObj.staff || !queryObj.staff.staffName || !queryObj.staff.staffID)
                    return;

                /* Constructing The Query */
                let query = forms.orderBy('formDate', 'desc')
                .where('formDate', '>=', queryObj.performanceStartDate)
                .where('formDate', '<=', queryObj.performanceEndDate)
                .where('formStaffID', '==', queryObj.staff.staffID);

                /* Pagination */
                if(queryObj.paginationType && state.staffRecentOperations.length > 0) {
                    const lastSeenDocument = queryObj.paginationType === 'next' ? 
                    state.staffRecentOperations[state.staffRecentOperations.length - 1].formRef : state.staffRecentOperations[0].formRef;
                    
                    if(queryObj.paginationType === 'next') query = query.startAfter(lastSeenDocument);
                    else if(queryObj.paginationType === 'prev') query = query.endBefore(lastSeenDocument);
                }

                if(!queryObj.paginationType || (queryObj.paginationType && !(await query.limit(1).get()).empty)) {
                    if(queryObj.paginationType === 'prev') query = query.limitToLast(state.staffInfoLimit);
                    else query = query.limit(state.staffInfoLimit);
                    
                    commit('clearStaffRecentOperations');
                    state.staffRecentOperationsSnapshot = query.onSnapshot(snapshot => {
                        snapshot.docChanges().forEach(async form => {
                            const formDoc = form.doc;

                            if(form.type === 'added') {
                                state.staffRecentOperations.push({
                                    formID: formDoc.id,
                                    formRef: formDoc,
                                    ...formDoc.data()
                                });
                            }
                        });
                    });
                }
            } catch(err) {
                console.log(err);
                throw new Error("Kullanıcı geçmişi okunamadı");
            }
        },
        async readStaffPerformancesInfo({ state, commit }, queryObj = {}) {
            try {
                if(!queryObj.performanceStartDate || !queryObj.performanceEndDate)
                    return;

                /* Constructing The Query */
                let query = performance.orderBy('performanceDate', 'desc')
                .where('performanceDate', '>=', queryObj.performanceStartDate)
                .where('performanceDate', '<=', queryObj.performanceEndDate);

                /* Pagination */
                if(queryObj.paginationType && state.staffPerformancesInfo.length > 0) {
                    const lastSeenDocument = queryObj.paginationType === 'next' ? 
                    state.staffPerformancesInfo[state.staffPerformancesInfo.length - 1].performanceRef : state.staffPerformancesInfo[0].performanceRef;
                    
                    if(queryObj.paginationType === 'next') query = query.startAfter(lastSeenDocument);
                    else if(queryObj.paginationType === 'prev') query = query.endBefore(lastSeenDocument);
                }

                if(!queryObj.paginationType || (queryObj.paginationType && !(await query.limit(1).get()).empty)) {
                    if(queryObj.paginationType === 'prev') query = query.limitToLast(state.staffPerformanceInfoLimit);
                    else query = query.limit(state.staffPerformanceInfoLimit);
                    
                    commit('clearStaffPerformancesInfo');
                    state.staffPerformancesInfoSnapshot = query.onSnapshot(snapshot => {
                        snapshot.docChanges().forEach(async performance => {
                            const performanceDoc = performance.doc;

                            if(performance.type === 'added') {
                                state.staffPerformancesInfo.push({
                                    performanceID: performanceDoc.id,
                                    performanceRef: performanceDoc,
                                    ...performanceDoc.data()
                                });
                            }
                        });
                    });
                }
            } catch(err) {
                console.log(err);
                throw new Error("Personel performansları okunamadı");
            }
        },
        async getSinglePerformance({ state, commit }, queryObj = {}) {
            if(!queryObj.performanceStartDate || !queryObj.performanceEndDate || !queryObj.staff || !queryObj.staff.staffName || !queryObj.staff.staffID)
                return;

            try {
                const performances = await staffs.doc(queryObj.staff.staffID).collection('performance').orderBy('performanceDate')
                .where('performanceDate', '>=', queryObj.performanceStartDate).where('performanceDate', '<=', queryObj.performanceEndDate).get();

                if(performances.empty) {
                    commit('clearStaffPerformanceInfo');
                    return;
                }
                
                let onRoad = 0, onService = 0;
                performances.forEach(performance => {
                    const performanceData = performance.data();
                    onRoad += parseInt(performanceData.performanceOnRoad);
                    onService += parseInt(performanceData.performanceOnService);
                });

                state.staffPerformanceInfo.onRoad = onRoad;
                state.staffPerformanceInfo.onService = onService;
                state.staffPerformanceInfo.total = onRoad + onService;
            } catch(err) {
                console.log(err);
                throw new Error('Kullanıcı performansı okunamadı');
            }
        },    
    },
    getters: {
        staffRecentOperations(state) { return state.staffRecentOperations },
        staffPerformanceInfo(state) { return state.staffPerformanceInfo },
        staffPerformancesInfo(state) { return state.staffPerformancesInfo }
    }
}

export default staffInfoStore;