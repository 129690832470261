import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

// Firebase Init
const firebaseConfig = {
    apiKey: "AIzaSyBretqtjiBs6NC05lROF-Zk2CFQy4Eh1mA",
    authDomain: "teknik-s.firebaseapp.com",
    projectId: "teknik-s",
    storageBucket: "teknik-s.appspot.com",
    messagingSenderId: "994482275989",
    appId: "1:994482275989:web:17d5ec6e6c55f64452fd9c",
    measurementId: "G-EBBMEF38HR"
};

firebase.initializeApp(firebaseConfig);

// Utils
const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();

// collection
const brands = db.collection('brands');
const changedEquipments = db.collection('changedEquipments');
const devices = db.collection('devices');
const equipments = db.collection('equipments');
const fixed = db.collection('fixed');
const forms = db.collection('forms');
const hospitals = db.collection('hospitals');
const models = db.collection('models');
const performances = db.collection('performances');
const regions = db.collection('regions');
const staff = db.collection('staff');
const inventory = db.collection('inventory');

export {
    db,
    auth,
    functions,
    brands,
    changedEquipments,
    devices,
    equipments,
    fixed,
    forms,
    hospitals,
    models,
    performances,
    regions,
    staff,
    inventory,
}