import gsap from 'gsap'

const popupStore = {
    state: () => ({
        popup: '',
        popupContainer: '',
        message: '',
        resolvePromise: undefined,
        rejectPromise: undefined
    }),
    mutations: {
        passPopupReference(state, refs) {
            state.popup = refs.popup;
            state.popupContainer = refs.popupContainer;
        },
        openPopup(state, message) {
            state.message = message;
            let tl = gsap.timeline();
            tl.to(state.popup, {duration: 0, display: 'flex'})
            .to(state.popup, {duration: .5, opacity: 1})
            .to(state.popupContainer, {duration: .5, opacity: 1, yPercent: -20}, '-=.4');
        },
        closePopup(state) {
            let tl = gsap.timeline();
            tl.to(state.popupContainer, {duration: .5, opacity: 0, yPercent: 20})
            .to(state.popup, {duration: .5, opacity: 0}, '-=.4')
            .to(state.popup, {duration: 0, display: 'none', onComplete: () => { 
                state.message = '';
                state.resolvePromise = undefined;
            }});
        }
    },
    actions: {
        triggerPopup({ state, commit }, message) {
            commit('openPopup', message);
            return new Promise((resolve, reject) => {
                state.resolvePromise = resolve;
                state.rejectPromise = reject;
            });
        },
        resolvePopup({ state, commit }) {
            state.resolvePromise(true);
            commit('closePopup');
        },
        rejectPopup({ state, commit }) {
            state.resolvePromise(false);
            commit('closePopup');
        },
    },
    getters: {
        getPopupMessage(state) {
            return state.message;
        }
    }
}

export default popupStore;