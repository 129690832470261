<template>
    <div class="dashboard-component dashboard-component--gray dashboard-component--middle">
        <div class="table">
            <div class="table-head">
                <div class="table-head__left">
                    <img :src="tableImg" class="table-head__img">
                    <h1 class="table-head__heading">{{ staff.staffName }}</h1>
                </div>
                <div class="table-head__right">
                    <input v-model="staffInfoDate" class="table-head__input table-head__input--middle" type="month" name="" id="">
                </div>
            </div>
            <div class="table-info">
                <div class="table-info__left">
                    <h1 class="table-info__text"><span>Yol&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>&nbsp;&nbsp;&nbsp;{{ staffPerformanceInfo.onRoad }} Saat</h1>
                    <h1 class="table-info__text"><span>Servis&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>&nbsp;&nbsp;&nbsp;{{ staffPerformanceInfo.onService }} Saat</h1>
                    <h1 class="table-info__text"><span>Toplam&nbsp;&nbsp;:</span>&nbsp;&nbsp;&nbsp;{{ staffPerformanceInfo.total }} Saat</h1>
                </div>
            </div>
            <transition name="fade" mode="out-in" v-if="staffRecentOperations.length > 0">
                <div>
                    <div class="table-content table-content--no-margin">
                        <div class="table-content__header table-content__header--small">
                            <div class="table-content__header-item">Tarih</div>
                            <div class="table-content__header-item">Müşteri</div>
                            <div class="table-content__header-item">Form</div>
                        </div>
                        <div v-for="staffRecentOperation in staffRecentOperations" :key="staffRecentOperation.formID" class="table-content__row table-content__header--small">
                            <div class="table-content__row-item">{{ normalizeDate(staffRecentOperation.formDate) }}</div>
                            <div class="table-content__row-item">{{ staffRecentOperation.formHospitalName }}</div>
                            <div class="table-content__row-item"><a @click.prevent="generateServiceFormPDF(staffRecentOperation)" href=""><img :src="downloadImg"></a></div>
                        </div>
                    </div>
                    <div class="pagination pagination--extra-margin">
                        <a @click.prevent="readStaffRecentOperations('prev')" href="" class="pagination__item pagination__item--left pagination__item--active">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.896" height="12.26" viewBox="0 0 6.896 12.26">
                                <path class="pagination__svg" d="M6.708,12.075a.622.622,0,0,0,.062-.821l-.062-.071L1.553,6.13,6.708,1.077A.622.622,0,0,0,6.77.255L6.708.185A.653.653,0,0,0,5.87.124L5.8.185l-5.61,5.5a.622.622,0,0,0-.062.821l.062.071,5.61,5.5A.652.652,0,0,0,6.708,12.075Z" fill="#1e202b"/>
                            </svg>
                            <div class="pagination__name">Önceki</div>
                        </a>
                        <a @click.prevent="readStaffRecentOperations('next')" href="" class="pagination__item pagination__item--right pagination__item--active">
                            <div class="pagination__name">Sonraki</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.896" height="12.26" viewBox="0 0 6.896 12.26">
                                <path class="pagination__svg" d="M.188,12.075a.622.622,0,0,1-.062-.821l.062-.071L5.343,6.13.188,1.077A.622.622,0,0,1,.126.255L.188.185A.653.653,0,0,1,1.026.124L1.1.185l5.61,5.5a.622.622,0,0,1,.062.821l-.062.071-5.61,5.5A.652.652,0,0,1,.188,12.075Z" fill="#1e202b"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </transition>
            <transition name="fade" mode="out-in" v-else>
                <div class="table-not-found" style="padding-top: 0" key="1">
                    <img class="table-not-found__img table-not-found__img--90p" :src="formNotFoundImg">
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'

export default {
    props: ['staff'],
    data() {
        return {
            staffInfoDate: '',

            tableImg: require('../../../assets/img/staff/staff.svg'),
            downArrowImg: require('../../../assets/img/util/down-arrow.svg'),
            downloadImg: require('../../../assets/img/util/download.svg'),
            formNotFoundImg: require('../../../assets/img/form/form-main.svg')
        }
    },
    created() {
        const currentDate = (new Date()).toISOString().split('T')[0].split('-');
        this.staffInfoDate = currentDate[0] + '-' + currentDate[1];
    },
    destroyed() {
        store.commit('clearStaffPerformanceInfo');
        store.commit('clearStaffRecentOperations');
    },
    computed: {
        staffRecentOperations() { return store.getters.staffRecentOperations },
        staffPerformanceInfo() { return store.getters.staffPerformanceInfo },
    },
    methods: {
        async readStaffRecentOperationsAndPerformanceInfo() {
            await this.readStaffPerformanceInfo();
            await this.readStaffRecentOperations();
        },
        async readStaffRecentOperations(paginationType = null) {
            const staffInfoDateSplitted = this.staffInfoDate.split('-');
            const performanceStartDate = new Date(staffInfoDateSplitted[0], staffInfoDateSplitted[1] - 1, 1),
            performanceEndDate = new Date(staffInfoDateSplitted[0], staffInfoDateSplitted[1], 1);

            try { 
                await store.dispatch('readStaffRecentOperations', {
                    staff: this.staff,
                    performanceStartDate,
                    performanceEndDate,
                    paginationType
                });
            }
            catch(err) {
                store.commit('triggerError', err)
            }
        },
        async readStaffPerformanceInfo() {
            const staffInfoDateSplitted = this.staffInfoDate.split('-');
            const performanceStartDate = new Date(staffInfoDateSplitted[0], staffInfoDateSplitted[1] - 1, 1),
            performanceEndDate = new Date(staffInfoDateSplitted[0], staffInfoDateSplitted[1], 1);

            try {
                await store.dispatch('getSinglePerformance', {
                    staff: this.staff,
                    performanceStartDate,
                    performanceEndDate
                });
            } catch(err) {
                store.commit('triggerError', err);
            }
        },
        generateServiceFormPDF(staffRecentOperation) {
            store.dispatch('generateServiceFormPDF', staffRecentOperation);
        },
        normalizeDate(date) {
            const normalizedDate = new Date(date.seconds * 1000).toISOString().slice(0, 10), normalizedDateSplitted = normalizedDate.split('-');
            const year = normalizedDateSplitted[0], month = normalizedDateSplitted[1], day = normalizedDateSplitted[2];
            return day + '.' + month + '.' + year;
        }
    },
    watch: {
        async staff() { await this.readStaffRecentOperationsAndPerformanceInfo() },
        async staffInfoDate() { await this.readStaffRecentOperationsAndPerformanceInfo() }
    }
}
</script>