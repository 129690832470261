<template>
    <div class="dashboard-component dashboard-component--center dashboard-component--dark-gray dashboard-component--small-height">
        <div class="table">
            <div class="table-head">
                <div class="table-head__left">
                    <img :src="tableImg" class="table-head__img">
                    <h1 class="table-head__heading table-head__heading--extra-margin">Cihaz / Demirbaş Ara<br></h1>
                </div>
            </div>
            <div class="table-filter table-filter--no-margin-responsive">
                <div class="table-head__selection-group table-head__selection-group--half">
                    <img :src="downArrowImg" alt="">
                    <select v-model="selectedType" class="table-head__selection">
                        <option value="-1">Tür Seç</option>
                        <option value="0">Cihaz</option>
                        <option value="1">Demirbaş</option>
                    </select>
                </div>
                <input v-model="serialNumber" type="text" class="table-head__input table-head__input--half" placeholder="Seri No.">
            </div>
            <div class="table-info">
                <div class="table-info__text"><span>Bulunduğu Yer:&nbsp;&nbsp;</span>{{ belongsTo }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'
import { db } from '../../../firebase/firebase'

export default {
    data() {
        return {
            /* Search Type */
            selectedType: -1,
            serialNumber: '',
            belongsTo: '-',

            /* Images */
            tableImg: require('../../../assets/img/device/device.svg'),
            downArrowImg: require('../../../assets/img/util/down-arrow.svg'),
        }
    },
    methods: {
        async searchDeviceOrFixed() {
            const hospitals = db.collectionGroup('hospitals');
            const devices = db.collectionGroup('devices');
            const fixeds = db.collection('fixeds_new');

            try {
                if(this.selectedType == 0) {
                    const device = await devices.where('deviceSerialNumber', '==', this.serialNumber).get();

                    if(device.empty)
                        return;

                    const deviceHospital = await hospitals.where('hospitalID', '==', device.docs[0].data().deviceHospital).get();

                    if(deviceHospital.empty) this.belongsTo = 'Cihaz Boşta';
                    else this.belongsTo = 'Cihaz - ' + deviceHospital.docs[0].data().hospitalName;
                } else if(this.selectedType == 1) {
                    const fixed = await fixeds.where('fixedSerialNumber', '==', this.serialNumber).get();
                    
                    if(fixed.empty)
                        return;

                    const fixedHospital = await hospitals.where('hospitalID', '==', fixed.docs[0].data().fixedHospital).get();

                    if(fixedHospital.empty) this.belongsTo = 'Demirbaş Boşta';
                    else this.belongsTo = 'Demirbaş - ' + fixedHospital.docs[0].data().hospitalName;
                }
            } catch(err) {
                console.log(err);
                store.commit('triggerError', err);
            }
        }
    },
    watch: {
        async selectedType() {
            this.belongsTo = '-';

            if(this.selectedType != -1 && this.serialNumber)
                await this.searchDeviceOrFixed();
        },
        async serialNumber() {
            this.belongsTo = '-';

            if(this.selectedType == -1) {
                store.commit('triggerError', 'Lütfen önce tür seçin');
                return;
            }

            if(this.serialNumber)
                await this.searchDeviceOrFixed();
        }
    }
}
</script>