<template>
    <div class="add-container">
        <div class="sidebyside">
            <div class="sidebyside__left">
                <div class="sidebyside__top sidebyside__top--center">
                    <div class="sidebyside__heading-container">
                        <img :src="brandImg" alt="" class="sidebyside__heading-img">
                        <h1 class="sidebyside__heading">{{ operationHeadingText }}</h1>
                    </div>
                    <a @click.prevent="openExcelImportPopup" v-if="operationType === 'create'" href="" class="sidebyside__import-excel">excel import</a>
                </div>
                <form action="" class="sidebyside__form">
                    <div class="sidebyside__form-group">
                        <input v-model="brandObj.brandName" type="text" name="create-brand-name" id="create-brand-name" placeholder="Marka Adı" autocomplete="off">
                    </div>
                    <button :class="{'disabled-btn': dbController}" @click.prevent="createOrUpdateBrand" class="sidebyside__submit"><div v-if="dbController" class="loading"></div>{{ operationButtonText }}</button>
                </form>
            </div>
            <div class="sidebyside__right">
                <img :src="brandMainImg" alt="Yeni Marka Ekle / Düzenle">
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'
import router from '../../../router/router'

export default {
    data() {
        return {
            dbController: false,

            /* Brand Operations */
            brandObj: {
                brandID: '',
                brandName: '',
                brandOriginalName: ''
            },

            /* Operation Type */
            operationType: '',

            /* Operation Type Texts */
            operationButtonText: '',
            operationHeadingText: '',

            /* Images */ 
            brandMainImg: require('../../../assets/img/brand/brand-main.svg'),
            brandImg: require('../../../assets/img/brand/brand.svg'),
        }
    },
    async created() {
        this.brandObj.brandID = router.currentRoute.query.brandID;

        if(this.brandObj.brandID) {
            try {
                this.operationType = 'update';
                this.brandObj.brandOriginalName = this.brandObj.brandName = (await store.dispatch('getBrandByID', this.brandObj.brandID)).brandName;
            } catch(err) {
                store.commit('triggerError', err);
                this.redirectToBrands();
            }
        }
        else this.operationType = 'create';
    },
    methods: {
        /* Excel Add */
        openExcelImportPopup() { 
            store.commit('updateJSONPopup', { 
                openDialog: true, 
                redirectFunction: this.redirectToBrands, 
                addFunction: this.createBrandWithoutRedirection
            });
        },
        async createBrandWithoutRedirection(brandObj) {
            try {
                if(!brandObj || !brandObj.brandName) {
                    store.commit('triggerError', 'Eklenecek markanın adı yok');
                    return;
                }

                await store.dispatch('createBrand', brandObj.brandName);
                store.commit('triggerSuccess', brandObj.brandName + ' markası eklendi');
            } catch(err) { store.commit('triggerError', brandObj.brandName + ' markası eklenemedi') }
        },
        /* Create Or Update */
        async createOrUpdateBrand() {
            this.dbController = true;
            if(this.operationType === 'create') await this.createBrand();
            else if(this.operationType === 'update') await this.updateBrand();
            this.dbController = false;
        },
        async createBrand() {
            try {
                await store.dispatch('createBrand', this.brandObj.brandName);
                this.redirectToBrands();
                store.commit('triggerSuccess', 'Marka eklendi');
            } catch(err) { store.commit('triggerError', err) }
        },
        async updateBrand() {
            try {
                await store.dispatch('updateBrand', this.brandObj);
                this.redirectToBrands();
                store.commit('triggerSuccess', 'Marka güncellendi');
            } catch(err) { store.commit('triggerError', err) }
        },
        redirectToBrands() {
            router.push('/superadmin/brands');
        },
    },
    watch: {
        operationType() {
            if(this.operationType === 'create') {
                this.operationButtonText = 'marka ekle';
                this.operationHeadingText = 'Marka Ekle';
            } else if(this.operationType === 'update') {
                this.operationButtonText = 'düzenlemeyi bitir';
                this.operationHeadingText = 'Marka Düzenle';
            }
        }
    }
}
</script>