<template>
    <div class="superadmin">
		<superadmin-excel-to-json></superadmin-excel-to-json>
		<transition name="fade" mode="out-in">
			<router-view></router-view>
		</transition>
		<div class="superadmin-btns">
			<router-link to="/superadmin" href="" class="superadmin-btn superadmin-btn--blue">
				<img :src="homeImg" alt="">
				<div class="superadmin-btn__description superadmin-btn__description--big">Superadmin</div>
			</router-link>
			<router-link to="/admin" class="superadmin-btn superadmin-btn--green">
				<img :src="dashboardImg" alt="">
				<div class="superadmin-btn__description">Admin</div>
			</router-link>
			<a href="" @click.prevent="signOut" class="superadmin-btn superadmin-btn--red">
				<img :src="logoutImg" alt="">
				<div class="superadmin-btn__description superadmin-btn__description--medium">Çıkış Yap</div>
			</a>
		</div>
    </div>
</template>

<script>
import store from '../../store/store'
import ExcelToJSON from './ExcelToJSON.vue'

export default {
	data() {
		return {
			homeImg: require('../../assets/img/util/home.svg'),
			dashboardImg: require('../../assets/img/util/dashboard.svg'),
			logoutImg: require('../../assets/img/util/logout.svg'),
		}
	},
	components: {
		'superadmin-excel-to-json': ExcelToJSON 
	},
	methods: {
		signOut() {
			store.dispatch('signOut');
		}
	}
}
</script>