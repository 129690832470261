/* Import Devices */
import DevicesView from '../../components/superadmin/devices/DevicesView.vue'
import Devices from '../../components/superadmin/devices/Devices.vue'
import DeviceOp from '../../components/superadmin/devices/DeviceOp.vue'

const routes = {
    path: 'devices',
    component: DevicesView,
    children: [
        {
            path: '/',
            component: Devices,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
        {
            path: 'device-op',
            component: DeviceOp,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
    ],
    meta: {
        requiresAuth: true,
        requiresAdmin: true
    }
}

export default routes;