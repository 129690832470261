<template>
    <div ref="alertBox" class="alert-box" :class="{'alert-box--success': message.type == 'success', 'alert-box--error': message.type == 'error'}">
        <p class="alert-box__message">{{ message.text }}</p>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$store.commit('passAlertBoxReference', this.$refs.alertBox);
    },
    computed: {
        message() {
            return this.$store.getters.getMessage;
        }
    }
}
</script>