/* eslint-disable no-unused-vars */
import { db, hospitals } from '../firebase/firebase'
const regions = db.collection('regions_new');

const regionsStore = {
    state: () => ({
        regions: [],
        hospitals: [],
        regionsSnapshot: null,
    }),
    mutations: {
        clearRegions(state) {
            if(state.regionsSnapshot)
                state.regionsSnapshot();
            
            state.regions.forEach(region => { 
                if(region.hospitalsSnapshot)
                    region.hospitalsSnapshot();
            });

            state.regions = [];
            state.hospitals = [];
        }
    },
    actions: {
        async readRegions({ dispatch, state }) {
            if(state.regionsSnapshot)
                state.regionsSnapshot();

            try {
                state.regionsSnapshot = regions.orderBy('regionName').onSnapshot(snapshot => {
                    snapshot.docChanges().forEach(async region => {
                        const regionDoc = region.doc;
                        if(region.type === 'added') {
                            const regionObj = {
                                regionID: regionDoc.id,
                                regionName: regionDoc.data().regionName,
                                hospitalsSnapshot: null,
                            }

                            state.regions.push(regionObj);
                            try { await dispatch('readHospitals', regionObj)}
                            catch { throw new Error('Bazı bölgelerin hastaneleri okunamadı') }
                        }

                        if(region.type === 'removed')
                            state.regions.splice(state.regions.findIndex(region => region.regionID === regionDoc.id), 1);

                        if(region.type === 'modified')
                            state.regions[state.regions.findIndex(region => region.regionID === regionDoc.id)].regionName = regionDoc.data().regionName;
                    });
                }); 
            } catch(err) {
                throw new Error("Bölgeler okunamadı");
            }
        },
        async createRegion({ dispatch }, regionName) {
            try {
                await dispatch('validateRegion', { regionName, type: 'create' });
                try { await regions.add({ regionName }) }
                catch { throw new Error('Bölge eklenemedi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async updateRegion({ dispatch }, regionObj) {
            try {
                regionObj.type = 'update';
                await dispatch('validateRegion', regionObj);

                try { await regions.doc(regionObj.regionID).update({ regionName: regionObj.regionName }) }
                catch { throw new Error('Bölge güncellenemedi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async validateRegion(context, regionObj) {
            try {
                if(!regionObj.regionName || (regionObj.type === 'update' && !regionObj.regionID))
                    throw new Error('Lütfen tüm alanları doldurun');

                const regionDuplicate = await regions.where('regionName', '==', regionObj.regionName).limit(1).get();

                if(!regionDuplicate.empty)
                    if(regionObj.type === 'create' || (regionObj.type === 'update' && regionDuplicate.docs[0].id !== regionObj.regionID))
                        throw new Error('Böyle bir bölge zaten var');
            } catch(err) { throw new Error(err) }
        },
        async deleteRegion(context, regionID) {
            try {
                if(!regionID)
                    throw new Error("Bölge ID'si boş");
                
                if(!(await regions.doc(regionID).get()).exists)
                    throw new Error('Bölge bulunamadı');

                let hospitals = await regions.doc(regionID).collection('hospitals').limit(500).get();
                while(hospitals && hospitals.size > 0) {
                    try {
                        let batch = db.batch();
                        hospitals.forEach(hospital => { batch.delete(hospital.ref) });
                        await batch.commit();
                    } catch(err) { throw new Error('Bazı hastaneler silinemedi') }
                    hospitals = await regions.doc(regionID).collection('hospitals').limit(500).get();
                }

                try { await regions.doc(regionID).delete() }
                catch { throw new Error('Bölge silinemedi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async getRegionByID(context, regionID) {
            try { return (await regions.doc(regionID).get()).data() }
            catch { throw new Error('Bölge bulunamadı') }
        },
        async readHospitals({ state }, region) {
            try {
                if(!region.regionID) throw new Error("Bölge ID'si boş");
                if(region.hospitalsSnapshot) {
                    region.hospitalsSnapshot();

                    hospitals.forEach((hospital, index) => {
                        if(hospital.regionID === region.regionID)
                            hospitals.splice(index, 1);
                    });
                }

                region.hospitalsSnapshot = regions.doc(region.regionID)
                .collection('hospitals').orderBy('hospitalName').onSnapshot(snapshot => {
                    snapshot.docChanges().forEach(async hospital => {
                        const hospitalDoc = hospital.doc;
                        if(hospital.type === 'added')
                            state.hospitals.push({
                                regionID: region.regionID,
                                hospitalID: hospitalDoc.id,
                                ...hospitalDoc.data()
                            });
                        
                        if(hospital.type === 'removed')
                            state.hospitals.splice(state.hospitals.findIndex(hospital => hospital.hospitalID === hospitalDoc.id), 1);

                        if(hospital.type === 'modified')
                            state.hospitals[state.hospitals.findIndex(hospital => hospital.hospitalID === hospitalDoc.id)].hospitalName = hospitalDoc.data().hospitalName;
                    });
                });
            } catch(err) {
                throw new Error("Hastaneler okunamadı");
            }
        },
        async createHospital({ dispatch }, hospitalObj) {
            try {
                hospitalObj.type = 'create';
                await dispatch('validateHospital', hospitalObj);

                try { 
                    const hospital = regions.doc(hospitalObj.regionID).collection('hospitals').doc();
                    await hospital.set({ hospitalID: hospital.id, hospitalName: hospitalObj.hospitalName }) }
                catch { throw new Error('Hastane eklenemedi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async updateHospital({ dispatch }, hospitalObj) {
            try {
                hospitalObj.type = 'update';
                await dispatch('validateHospital', hospitalObj);

                try { await regions.doc(hospitalObj.regionID).collection('hospitals')
                .doc(hospitalObj.hospitalID).update({ hospitalName: hospitalObj.hospitalName }) }
                catch { throw new Error('Hastane güncellenemedi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async validateHospital(context, hospitalObj) {
            try {
                if(!hospitalObj.regionID || !hospitalObj.hospitalName 
                || (hospitalObj.type === 'update' && !hospitalObj.hospitalID))
                    throw new Error('Lütfen tüm alanları doldurun');

                const hospitalDuplicate = await regions.doc(hospitalObj.regionID)
                .collection('hospitals').where('hospitalName', '==', hospitalObj.hospitalName).limit(1).get();

                if(!hospitalDuplicate.empty)
                    if(hospitalObj.type === 'create' || (hospitalObj.type === 'update' && hospitalDuplicate.docs[0].id !== hospitalObj.hospitalID))
                        throw new Error('Böyle bir hastane zaten var');
            } catch(err) { throw new Error(err) }
        },
        async deleteHospital(context, hospitalObj) {
            try {
                if(!hospitalObj.regionID || !hospitalObj.hospitalID)
                    throw new Error('Hastane bulunamadı');

                try { await regions.doc(hospitalObj.regionID)
                .collection('hospitals').doc(hospitalObj.hospitalID).delete() }
                catch { throw new Error('Hastane silinemedi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async getHospitalByID(context, hospitalObj) {
            try {
                return (await regions.doc(hospitalObj.regionID)
                .collection('hospitals').doc(hospitalObj.hospitalID).get()).data();
            } catch { throw new Error("Hastane bulunamadı") }
        },
        async getHospitalByHospitalID(context, hospitalID) {
            try {
                const hospital = await db.collectionGroup('hospitals').where('hospitalID', '==', hospitalID).get();
                if(hospital.empty)
                    throw new Error();
    
                return hospital.docs[0].data();
            } catch { throw new Error('Hastane bulunamadı') }
        }
    },
    getters: {
        regions(state) { return state.regions },
        hospitals(state) { return state.hospitals }
    }
}

export default regionsStore;