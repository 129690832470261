<template>
    <div class="flex-component flex-component--flex flex-component--responsive">
        <app-staff-info :staff="staff"></app-staff-info>
        <app-inventory-status :staff="staff"></app-inventory-status>
    </div>
</template>

<script>
import store from '../../../store/store'
import router from '../../../router/router'
import { db } from '../../../firebase/firebase'
import StaffInfo from './StaffInfo.vue'
import InventoryStatus from '../dashboards/InventoryStatus.vue'

export default {
    components: {
        'app-staff-info': StaffInfo,
        'app-inventory-status': InventoryStatus
    },
    data() {
        return {
            staff: { staffName: '' }
        }
    },
    async created() { await this.selectStaff(router.currentRoute.params.staffID) },
    watch: { $route(to) { this.selectStaff(to.params.staffID) } },
    computed: { staffs() { return store.getters.staffs } },
    methods: {
        async selectStaff(staffID) {
            const index = this.staffs.findIndex(staff => staff.staffID === staffID);
            if(index === -1) {
                const staff = await db.collection('staff_new').doc(staffID).get();
                if(!staff.exists)
                    return;

                this.staff = { staffID: staffID, ...staff.data() };
            } else this.staff = this.staffs[index];
        }
    }
}
</script>