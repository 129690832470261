/* Import Regions */
import RegionsView from '../../components/superadmin/regions/RegionsView.vue'
import Regions from '../../components/superadmin/regions/Regions.vue'
import RegionOp from '../../components/superadmin/regions/RegionOp.vue'
import HospitalOp from '../../components/superadmin/regions/HospitalOp.vue'

const routes = {
    path: 'regions',
    component: RegionsView,
    children: [
        {
            path: '/',
            component: Regions,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
        {
            path: 'region-op',
            component: RegionOp,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
        {
            path: 'hospital-op',
            component: HospitalOp,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
    ],
    meta: {
        requiresAuth: true,
        requiresAdmin: true
    }
}

export default routes;