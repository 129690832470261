<template>
    <div class="dashboard-component dashboard-component--gray dashboard-component--middle">
        <div class="table">
            <div class="table-head">
                <div class="table-head__left">
                    <img :src="tableImg" class="table-head__img">
                    <h1 class="table-head__heading table-head__heading--extra-margin">{{ hospital.hospitalName.length > 25 ? hospital.hospitalName.substring(0, 25) + '...' : hospital.hospitalName }}<br>{{ selectedTypeText }}</h1>
                </div>
                <div class="table-head__right">
                    <div class="table-head__selection-group table-head__selection-group--small">
                        <img :src="downArrowImg" alt="">
                        <select v-model="selectedType" class="table-head__selection">
                            <option value="0">Cihazlar</option>
                            <option value="1">Demirbaşlar</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="table-filter">
                <input v-model="searchString" type="text" class="table-filter__input" :placeholder="selectedTypeText + 'da Ara...'" autocomplete="off">
            </div>
            <transition name="fade" mode="out-in" v-if="(selectedType == 0 && devices.length > 0) || (selectedType == 1 && fixeds.length > 0)">
                <div>
                    <div class="table-content">
                        <div class="table-content__header table-content__header--small">
                            <div class="table-content__header-item">Marka</div>
                            <div class="table-content__header-item">Model</div>
                            <div class="table-content__header-item">Seri No</div>
                        </div>
                        <div v-if="selectedType == 0">
                            <div @click.prevent="selectDevice(device)" v-for="device in devices" :key="device.deviceID" :class="{'table-content__header--active': selectedDevice ? device.deviceID === selectedDevice.deviceID : false}" class="table-content__row table-content__row--hoverable table-content__header--small">
                                <div class="table-content__row-item">{{ device.brand.brandName }}</div>
                                <div class="table-content__row-item">{{ device.model.modelName }}</div>
                                <div class="table-content__row-item">{{ device.deviceSerialNumber }}</div>
                            </div>
                        </div>
                        <div v-if="selectedType == 1">
                            <div v-for="fixed in fixeds" :key="fixed.deviceID" class="table-content__row table-content__header--small">
                                <div class="table-content__row-item">{{ fixed.fixedBrand }}</div>
                                <div class="table-content__row-item">{{ fixed.fixedModel }}</div>
                                <div class="table-content__row-item">{{ fixed.fixedSerialNumber }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="pagination pagination--extra-margin">
                        <a @click.prevent="readDevicesOrFixeds('prev')" href="" class="pagination__item pagination__item--left pagination__item--active">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.896" height="12.26" viewBox="0 0 6.896 12.26">
                                <path class="pagination__svg" d="M6.708,12.075a.622.622,0,0,0,.062-.821l-.062-.071L1.553,6.13,6.708,1.077A.622.622,0,0,0,6.77.255L6.708.185A.653.653,0,0,0,5.87.124L5.8.185l-5.61,5.5a.622.622,0,0,0-.062.821l.062.071,5.61,5.5A.652.652,0,0,0,6.708,12.075Z" fill="#1e202b"/>
                            </svg>
                            <div class="pagination__name">Önceki</div>
                        </a>
                        <a @click.prevent="readDevicesOrFixeds('next')" href="" class="pagination__item pagination__item--right pagination__item--active">
                            <div class="pagination__name">Sonraki</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.896" height="12.26" viewBox="0 0 6.896 12.26">
                                <path class="pagination__svg" d="M.188,12.075a.622.622,0,0,1-.062-.821l.062-.071L5.343,6.13.188,1.077A.622.622,0,0,1,.126.255L.188.185A.653.653,0,0,1,1.026.124L1.1.185l5.61,5.5a.622.622,0,0,1,.062.821l-.062.071-5.61,5.5A.652.652,0,0,1,.188,12.075Z" fill="#1e202b"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </transition>
            <transition name="fade" mode="out-in" v-else>
                <div class="table-not-found" style="margin-top: 5rem" key="1">
                    <transition name="fade" mode="out-in" v-if="selectedType == 0">
                        <div key="2">
                            <img class="table-not-found__img table-not-found__img--90p" :src="devicesNotFoundImg">
                            <div class="table-not-found__text">:(</div>
                        </div>
                    </transition>
                    <transition name="fade" mode="out-in" v-else>
                        <div>
                            <img class="table-not-found__img table-not-found__img--90p" :src="fixedsNotFoundImg">
                            <div class="table-not-found__text">:(</div>
                        </div>
                    </transition>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'

export default {
    props: ['hospital'],
    data() {
        return {
            selectedType: 0,
            selectedTypeText: 'Cihazlar',
            searchString: '',

            /* Images */
            tableImg: require('../../../assets/img/hospital/hospital.svg'),
            downArrowImg: require('../../../assets/img/util/down-arrow.svg'),
            downloadImg: require('../../../assets/img/util/download.svg'),
            settingsImg: require('../../../assets/img/util/settings.svg'),
            devicesNotFoundImg: require('../../../assets/img/device/device-main.svg'),
            fixedsNotFoundImg: require('../../../assets/img/fixed/fixed-main.svg')
        }
    },
    async created() {
        await this.readDevicesOrFixeds();
    },
    destroyed() { 
        store.commit('clearFixeds');
        store.commit('clearDevices'); 
    },
    computed: {
        devices() { return store.getters.devices },
        fixeds() { return store.getters.fixeds },
        selectedDevice() { return store.getters.selectedDevice }
    },
    methods: {
        async readDevicesOrFixeds(paginationType = null) {
            this.selectedType == 0 ? await this.readDevices(paginationType) : await this.readFixeds(paginationType);
        },
        async readDevices(paginationType = null) {
            if(!this.hospital || !this.hospital.hospitalID)
                return;

            try { 
                await store.dispatch('readDevices', {
                    searchDevice: this.searchString,
                    hospitalID: this.hospital.hospitalID,
                    paginationType
                });
            }
            catch(err) {
                store.commit('triggerError', err)
            }
        },
        async readFixeds(paginationType = null) {
            if(!this.hospital || !this.hospital.hospitalID)
                return;

            try { 
                await store.dispatch('readFixeds', {
                    searchFixed: this.searchString,
                    hospitalID: this.hospital.hospitalID,
                    paginationType
                });
            }
            catch(err) {
                store.commit('triggerError', err)
            }
        },
        selectDevice(device) {
            store.commit('selectDevice', device);
        },
    },
    watch: {
        async hospital() {
            await this.readDevicesOrFixeds();
        },
        async selectedType() {
            this.searchString = '';
            this.selectedTypeText = this.selectedType == 0 ? 'Cihazlar' : 'Demirbaşlar';
            await this.readDevicesOrFixeds();
        },
        async searchString() {
            await this.readDevicesOrFixeds();
        }
    }
}
</script>