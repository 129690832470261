<template>
    <div class="dashboard-component" :class="{'dashboard-component--small': this.$router.currentRoute.path.search('/admin') !== -1, 'dashboard-component--middle': this.$router.currentRoute.path.search('/admin') === -1}">
        <div class="table">
            <div class="table-head">
                <div class="table-head__left">
                    <img :src="tableImg" class="table-head__img">
                    <h1 class="table-head__heading">Stok Durumu</h1>
                </div>
            </div>
            <div class="table-filter table-filter--no-margin-responsive">
                <div class="table-head__selection-group table-head__selection-group--half">
                    <img :src="downArrowImg" alt="">
                    <select v-model="selectedBrand" class="table-head__selection">
                        <option value="-1">Hepsi</option>
                        <option v-for="(brand, index) in brands" :key="brand.brandID" :value="index">{{ brand.brandName }}</option>
                    </select>
                </div>
                <div class="table-head__selection-group table-head__selection-group--half">
                    <img :src="downArrowImg" alt="">
                    <select v-model="selectedModel" class="table-head__selection">
                        <option value="-1">Hepsi</option>
                        <option v-for="(model, index) in currentModels" :key="model.modelID" :value="index">{{ model.modelName }}</option>
                    </select>
                </div>
            </div>
            <transition name="fade" mode="out-in" v-if="inventoryEquipments.length === 0">
                <div class="table-not-found" style="margin-top: 5rem" key="1">
                    <img class="table-not-found__img table-not-found__img--90p" :src="inventoryNotFoundImg" alt="Stoklar Bulunamadı">
                </div>
            </transition>
            <transition name="fade" mode="out-in" v-else>
                <div>
                    <div class="table-content">
                        <div class="table-content__header table-content__header--small">
                            <div class="table-content__header-item">Parça Kodu</div>
                            <div class="table-content__header-item">Parça Adı</div>
                            <div class="table-content__header-item">Adet</div>
                        </div>
                        <div v-for="inventoryEquipment in inventoryEquipments" :key="inventoryEquipment.inventorySerialNumber" class="table-content__row table-content__header--small">
                            <div class="table-content__row-item">{{ inventoryEquipment.equipment.equipmentCode }}</div>
                            <div class="table-content__row-item">{{ inventoryEquipment.equipment.equipmentName }}</div>
                            <div class="table-content__row-item">{{ inventoryEquipment.inventoryQuantity }}</div>
                        </div>
                    </div>
                    <div class="pagination pagination--extra-margin">
                        <a @click.prevent="readStaffInventory('prev')" href="" class="pagination__item pagination__item--left pagination__item--active">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.896" height="12.26" viewBox="0 0 6.896 12.26">
                                <path class="pagination__svg" d="M6.708,12.075a.622.622,0,0,0,.062-.821l-.062-.071L1.553,6.13,6.708,1.077A.622.622,0,0,0,6.77.255L6.708.185A.653.653,0,0,0,5.87.124L5.8.185l-5.61,5.5a.622.622,0,0,0-.062.821l.062.071,5.61,5.5A.652.652,0,0,0,6.708,12.075Z" fill="#1e202b"/>
                            </svg>
                            <div class="pagination__name">Önceki</div>
                        </a>
                        <a @click.prevent="readStaffInventory('next')" href="" class="pagination__item pagination__item--right pagination__item--active">
                            <div class="pagination__name">Sonraki</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.896" height="12.26" viewBox="0 0 6.896 12.26">
                                <path class="pagination__svg" d="M.188,12.075a.622.622,0,0,1-.062-.821l.062-.071L5.343,6.13.188,1.077A.622.622,0,0,1,.126.255L.188.185A.653.653,0,0,1,1.026.124L1.1.185l5.61,5.5a.622.622,0,0,1,.062.821l-.062.071-5.61,5.5A.652.652,0,0,1,.188,12.075Z" fill="#1e202b"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'

export default {
    props: ['staff'],
    data() {
        return {
            /* Brand */
            selectedBrand: -1,

            /* Model */
            selectedModel: -1,
            currentModels: [],

            /* Images */
            tableImg: require('../../../assets/img/inventory/inventory.svg'),
            downArrowImg: require('../../../assets/img/util/down-arrow.svg'),
            inventoryNotFoundImg: require('../../../assets/img/inventory/inventory-main.svg'),
        }
    },
    async created() {
        this.currentModels = this.models;
        await this.readStaffInventory();
    },
    destroyed() {
        store.commit('clearInventory');
    },
    computed: {
        brands() { return store.getters.brands },
        models() { return store.getters.models },
        inventoryEquipments() { return store.getters.inventoryEquipments },
        user() { return store.getters.user }
    },
    methods: {
        async readStaffInventory(paginationType = null) {
            try {
                await store.dispatch('readInventoryEquipments', {
                    inventoryGeneral: (this.user && this.user.admin) && !this.staff,
                    inventoryStaff: this.user.admin ? ((this.staff && this.staff.staffID) ? this.staff.staffID : null): this.user.uid,
                    inventoryBrand: this.selectedBrand == -1 ? null : this.brands[this.selectedBrand].brandID,
                    inventoryModel: this.selectedModel == -1 ? null : this.currentModels[this.selectedModel].modelID,
                    paginationType
                });
            } catch(err) { store.commit('triggerError', err) }
        }
    },
    watch: {
        async selectedBrand() {
            if(this.selectedBrand == -1) {
                this.currentModels = this.models;
                this.selectedModel = -1;
            } else {
                this.selectedModel = -1;
                this.currentModels = this.models.filter(model => model.brandID === this.brands[this.selectedBrand].brandID);
            }

            await this.readStaffInventory();
        },
        async selectedModel() {
            if(this.selectedModel != -1)
                await this.readStaffInventory();
        },
        async staff() {
            if(this.staff)
                await this.readStaffInventory();
        }
    }
}
</script>