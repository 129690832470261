<template>
    <div class="add-container">
        <div class="sidebyside">
            <div class="sidebyside__left sidebyside__left--big">
                <div class="sidebyside__top sidebyside__top--center">
                    <div class="sidebyside__heading-container">
                        <img :src="deviceImg" alt="" class="sidebyside__heading-img">
                        <h1 class="sidebyside__heading">{{ operationHeadingText }}</h1>
                    </div>
                    <a @click.prevent="openExcelImportPopup" v-if="operationType === 'create'" href="" class="sidebyside__import-excel">excel import</a>
                </div>
                <form action="" class="sidebyside__form">
                    <div class="sidebyside__form-group">
                        <input :disabled="operationType === 'update'" v-model="brandName" type="text" name="create-device-brand" id="create-device-brand" placeholder="Marka" autocomplete="off">
                        <div class="dropdown" :class="{'dropdown--active': brandsDropdown.length > 0}">
                            <div @click.prevent="selectBrand(brand)" v-for="brand in brandsDropdown" :key="brand.brandID" class="dropdown__item">{{ brand.brandName }}</div>
                        </div>
                    </div>
                    <div class="sidebyside__form-group">
                        <input :disabled="operationType === 'update'" v-model="modelName" type="text" name="create-device-model" id="create-device-model" placeholder="Model" autocomplete="off">
                        <div class="dropdown" :class="{'dropdown--active': modelsDropdown.length > 0}">
                            <div @click.prevent="selectModel(model)" v-for="model in modelsDropdown" :key="model.modelID" class="dropdown__item">{{ model.modelName }}</div>
                        </div>
                    </div>
                    <div class="sidebyside__form-group">
                        <input v-model="deviceObj.deviceSerialNumber" type="text" name="create-device-serial-no" id="create-device-serial-no" placeholder="Seri No." autocomplete="off">
                    </div>
                    <div class="sidebyside__form-group">
                        <input v-model="deviceObj.deviceProductionYear" type="number" name="create-device-production-year" id="create-device-production-year" placeholder="Üretim Yılı" autocomplete="off">
                    </div>
                    <div class="sidebyside__form-group">
                        <input v-model="hospitalName" type="text" name="create-device-hospital" id="create-device-hospital" placeholder="Bulunduğu Yer" autocomplete="off">
                        <div class="dropdown" :class="{'dropdown--active': hospitalsDropdown.length > 0}">
                            <div @click.prevent="selectHospital(hospital)" v-for="hospital in hospitalsDropdown" :key="hospital.hospitalID" class="dropdown__item">{{ hospital.hospitalName }}</div>
                        </div>
                    </div>
                    <button :class="{'disabled-btn': dbController}" @click.prevent="createUpdateDevice" class="sidebyside__submit"><div v-if="dbController" class="loading"></div>{{ operationButtonText }}</button>
                </form>
            </div>
            <div class="sidebyside__right">
                <img :src="deviceMainImg" alt="Yeni Demirbaş Ekle / Düzenle">
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'
import router from '../../../router/router'

export default {
    data() {
        return {
            dbController: false,

            /* Device */
            deviceObj: {
                deviceID: '',
                deviceSerialNumber: '',
                deviceProductionYear: '',
                brand: {
                    brandID: '',
                    brandName: ''
                },
                model: {
                    modelID: '',
                    modelName: ''
                },
                hospital: {
                    hospitalID: '',
                    hospitalName: ''
                }
            },

            /* Brands */
            brandsDropdown: [],
            brandName: '',

            /* Models */
            modelsDropdown: [],
            modelName: '',

            /* Hospitals */
            hospitalsDropdown: [],
            hospitalName: '',

            /* Operation Type */
            operationType: '',

            /* Operation Type Texts */
            operationButtonText: '',
            operationHeadingText: '',

            /* Images */
            deviceMainImg: require('../../../assets/img/device/device-main.svg'),
            deviceImg: require('../../../assets/img/device/device.svg'),
        }
    },
    async created() {
        this.deviceObj.deviceID = router.currentRoute.query.deviceID;
        if(this.deviceObj.deviceID) {
            try {
                this.operationType = 'update';
                this.deviceObj = (await store.dispatch('getDeviceByID', this.deviceObj.deviceID));
                this.deviceObj.deviceOriginalSerialNumber = this.deviceObj.deviceSerialNumber;
                this.brandName = this.deviceObj.brand.brandName;
                this.modelName = this.deviceObj.model.modelName;
                this.hospitalName = this.deviceObj.hospital.hospitalName;
            } catch(err) {
                store.commit('triggerError', err);
                this.redirectToDevices();
            }
        } else this.operationType = 'create';
    },
    computed: {
        brands() { return store.getters.brands },
        models() { return store.getters.models },
        hospitals() { return store.getters.hospitals }
    },
    methods: {
        createDropdownBrands() {
            if(!this.brandName || this.brandName === this.deviceObj.brand.brandName)
                this.brandsDropdown = [];
            else
                this.brandsDropdown = this.brands.filter(brand => brand.brandName.toLocaleLowerCase('tr-TR').search(this.brandName.toLocaleLowerCase('tr-TR')) > -1);
        },
        createDropdownModels() {
            if(!this.modelName || this.modelName === this.deviceObj.model.modelName)
                this.modelsDropdown = [];
            else
                this.modelsDropdown = this.models.filter(model => model.modelName.toLocaleLowerCase('tr-TR').search(this.modelName.toLocaleLowerCase('tr-TR')) > -1 && model.brandID === this.deviceObj.brand.brandID);
        },
        createDropdownHospitals() {
            if(!this.hospitalName || this.hospitalName === this.deviceObj.hospital.hospitalName)
                this.hospitalsDropdown = [];
            else
                this.hospitalsDropdown = this.hospitals.filter(hospital => hospital.hospitalName.toLocaleLowerCase('tr-TR').search(this.hospitalName.toLocaleLowerCase('tr-TR')) > -1);
        },
        selectBrand(selectedBrand) {
            if(!selectedBrand) return;
            this.deviceObj.brand = {};
            this.deviceObj.brand.brandID = selectedBrand.brandID;
            this.deviceObj.brand.brandName = selectedBrand.brandName;
            this.brandName = selectedBrand.brandName;
            this.brandsDropdown = [];
        },
        selectModel(selectedModel) {
            if(!selectedModel) return;
            this.deviceObj.model = {};
            this.deviceObj.model.modelID = selectedModel.modelID;
            this.deviceObj.model.modelName = selectedModel.modelName;
            this.modelName = selectedModel.modelName;
            this.modelsDropdown = [];
        },
        selectHospital(selectedHospital) {
            if(!selectedHospital) return;
            this.deviceObj.hospital = {};
            this.deviceObj.hospital.hospitalID = selectedHospital.hospitalID;
            this.deviceObj.hospital.hospitalName = selectedHospital.hospitalName;
            this.hospitalName = selectedHospital.hospitalName;
            this.hospitalsDropdown = [];
        },
        /* Excel Add */
        openExcelImportPopup() { 
            store.commit('updateJSONPopup', { 
                openDialog: true, 
                redirectFunction: this.redirectToDevices, 
                addFunction: this.createDeviceWithoutRedirection
            });
        },
        async createDeviceWithoutRedirection(deviceObj) {
            try {
                if(!deviceObj) {
                    store.commit('triggerError', 'Eklenecek cihaz bulunamadı');
                    return;
                }
                
                await store.dispatch('createDeviceJSON', deviceObj);
                store.commit('triggerSuccess', deviceObj.deviceSerialNumber + " seri no'lu cihaz eklendi");
            } catch(err) { store.commit('triggerError', deviceObj.deviceSerialNumber + " seri no'lu cihaz eklenemedi") }
        },
        /* Device Operations */
        async createUpdateDevice() {
            this.dbController = true;
            if(this.operationType === 'create') await this.createDevice();
            else if(this.operationType === 'update') await this.updateDevice();
            this.dbController = false;
        },
        async createDevice() {
            try {
                await store.dispatch('createDevice', this.deviceObj);
                this.redirectToDevices();
                store.commit('triggerSuccess', 'Cihaz eklendi');
            } catch(err) { store.commit('triggerError', err) }
        },
        async updateDevice() {
            try {
                await store.dispatch('updateDevice', this.deviceObj);
                this.redirectToDevices();
                store.commit('triggerSuccess', 'Cihaz güncellendi');
            } catch(err) { store.commit('triggerError', err) }
        },
        /* Redirection */
        redirectToDevices() {
            router.push('/superadmin/devices');
        }
    },
    watch: {
        operationType() {
            if(this.operationType === 'create') {
                this.operationButtonText = 'cihazı ekle';
                this.operationHeadingText = 'Cihaz Ekle';
            } else if(this.operationType === 'update') {
                this.operationButtonText = 'düzenlemeyi bitir';
                this.operationHeadingText = 'Cihaz Düzenle';
            }
        },
        brandName() {
            if(this.deviceObj.brand.brandName !== this.brandName) {
                this.deviceObj.brand.brandName = '';
                this.deviceObj.brand.brandID = '';
                this.deviceObj.modelName = '';
                this.deviceObj.modelID = '';
                this.modelName = '';
            }
            this.createDropdownBrands();
        },
        modelName() {
            this.createDropdownModels();
        },
        hospitalName() {
            this.createDropdownHospitals();
        }
    }
}
</script>