<template>
    <div class="dashboard-component dashboard-component--gray dashboard-component--middle-height">
        <div class="table">
            <div class="table-head">
                <div class="table-head__left">
                    <img :src="tableImg" class="table-head__img">
                    <h1 class="table-head__heading table-head__heading--extra-margin">Performans<br></h1>
                </div>
            </div>
            <div class="table-filter table-filter--no-margin-responsive table-filter--big">
                <input v-model="performanceStartDate" :max="performanceEndDate" class="table-head__input table-head__input--half" type="date" name="" id="">
                <input v-model="performanceEndDate" :min="performanceStartDate" class="table-head__input table-head__input--half" type="date" name="" id="">
            </div>
            <transition name="fade" mode="out-in" v-if="staffPerformancesInfo.length > 0">
                <div>
                    <div class="table-content">
                        <div class="table-content__header table-content__row--xlarge-four">
                            <div class="table-content__header-item">Tarih</div>
                            <div class="table-content__header-item">İsim</div>
                            <div class="table-content__header-item">Yol</div>
                            <div class="table-content__header-item">Servis</div>
                        </div>
                        <div v-for="staffPerformance in staffPerformancesInfo" :key="staffPerformance.performanceID" class="table-content__row table-content__row--xlarge-four">
                            <div class="table-content__row-item">{{ normalizeDate(staffPerformance.performanceDate) }}</div>
                            <div class="table-content__row-item">{{ staffPerformance.performanceStaffName.split(' ')[0] }}</div>
                            <div class="table-content__row-item">{{ staffPerformance.performanceOnRoad }}</div>
                            <div class="table-content__row-item">{{ staffPerformance.performanceOnService }}</div>
                        </div>
                    </div>
                    <div class="pagination">
                        <a @click.prevent="readStaffPerformancesInfo('prev')" href="" class="pagination__item pagination__item--left pagination__item--active">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.896" height="12.26" viewBox="0 0 6.896 12.26">
                                <path class="pagination__svg" d="M6.708,12.075a.622.622,0,0,0,.062-.821l-.062-.071L1.553,6.13,6.708,1.077A.622.622,0,0,0,6.77.255L6.708.185A.653.653,0,0,0,5.87.124L5.8.185l-5.61,5.5a.622.622,0,0,0-.062.821l.062.071,5.61,5.5A.652.652,0,0,0,6.708,12.075Z" fill="#1e202b"/>
                            </svg>
                            <div class="pagination__name">Önceki</div>
                        </a>
                        <a @click.prevent="readStaffPerformancesInfo('next')" href="" class="pagination__item pagination__item--right pagination__item--active">
                            <div class="pagination__name">Sonraki</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.896" height="12.26" viewBox="0 0 6.896 12.26">
                                <path class="pagination__svg" d="M.188,12.075a.622.622,0,0,1-.062-.821l.062-.071L5.343,6.13.188,1.077A.622.622,0,0,1,.126.255L.188.185A.653.653,0,0,1,1.026.124L1.1.185l5.61,5.5a.622.622,0,0,1,.062.821l-.062.071-5.61,5.5A.652.652,0,0,1,.188,12.075Z" fill="#1e202b"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </transition>
            <transition name="fade" mode="out-in" v-else>
                <div class="table-not-found" style="padding-top: 0" key="1">
                    <img class="table-not-found__img table-not-found__img--90p" :src="performancesNotFoundImg">
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'

export default {
    data() {
        return {
            /* Dates */
            performanceStartDate: '',
            performanceEndDate: '',

            /* Images */
            tableImg: require('../../../assets/img/staff/staff.svg'),
            downArrowImg: require('../../../assets/img/util/down-arrow.svg'),
            performancesNotFoundImg: require('../../../assets/img/performance/performance-main.svg')
        }
    },
    async created() {
        const startDate = new Date(), endDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        const startDateSplitted = startDate.toISOString().split('T')[0].split('-');
        const endDateSplitted = endDate.toISOString().split('T')[0].split('-');
        this.performanceStartDate = startDateSplitted[0] + '-' + startDateSplitted[1] + '-' + startDateSplitted[2];
        this.performanceEndDate = endDateSplitted[0] + '-' + endDateSplitted[1] + '-' + (parseInt(startDateSplitted[2]) + 1);
    },
    destroyed() {
        store.commit('clearStaffPerformancesInfo');
    },
    computed: {
        staffPerformancesInfo() { return store.getters.staffPerformancesInfo }
    },
    methods: {
        async readStaffPerformancesInfo(paginationType = null) {
            try {
                const performanceStartDate = new Date(this.performanceStartDate);
                const performanceEndDate = new Date(this.performanceEndDate);

                await store.dispatch('readStaffPerformancesInfo', {
                    performanceStartDate,
                    performanceEndDate,
                    paginationType
                });
            } catch(err) { store.commit('triggerAlert', err) }
        },
        normalizeDate(date) {
            const normalizedDate = new Date(date.seconds * 1000).toISOString().slice(0, 10), normalizedDateSplitted = normalizedDate.split('-');
            const year = normalizedDateSplitted[0], month = normalizedDateSplitted[1], day = normalizedDateSplitted[2];
            return day + '.' + month + '.' + year;
        }
    },
    watch: {
        performanceStartDate() { this.readStaffPerformancesInfo() },
        performanceEndDate() { this.readStaffPerformancesInfo() }
    }
}
</script>