/* Import Dashboard */
import Dashboard from '../../components/superadmin/dashboard/DashboardView.vue'

const routes = {
    path: '/',
    component: Dashboard,
    meta: {
        requiresAuth: true,
        requiresAdmin: true
    }
}

export default routes;