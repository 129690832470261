/* eslint-disable no-unused-vars */
import { db } from '../firebase/firebase'
const forms = db.collection('forms_new');
const brands = db.collection('brands_new');

const deviceInfoStore = {
    state: () => ({
        recentOperations: [],
        changedEquipments: [],
        recentOperationsSnapshot: null,
        changedEquipmentsSnapshot: null,
        selectedDevice: null,
        deviceInfoLimit: 5
    }),
    mutations: {
        selectDevice(state, device) {
            if(!device || !device.deviceID || !device.brand || !device.brand.brandID || !device.model || !device.model.modelID)
                return false;
            
            state.selectedDevice = device;
            return true;
        },
        clearSelectedDevice(state) {
            state.selectedDevice = null;
        },
        clearRecentOperations(state) {
            if(state.recentOperationsSnapshot)
                state.recentOperationsSnapshot();
        
            state.recentOperations = [];
        },
        clearChangedEquipments(state) {
            if(state.changedEquipmentsSnapshot)
                state.changedEquipmentsSnapshot();

            state.changedEquipments = [];
        }
    },
    actions: {
        async readRecentOperations({ state, commit }, queryObj = {}) {
            try {
                const device = state.selectedDevice;
                if(!device || !device.deviceID || !device.brand || !device.brand.brandID || !device.model || !device.model.modelID)
                    return;

                /* Constructing The Query */
                let query = forms.orderBy('formDate', 'desc')
                .where('formDeviceID', '==', state.selectedDevice.deviceID);

                if(queryObj.staffID)
                    query = query.where('formStaffID', '==', queryObj.staffID);

                /* Pagination */
                if(queryObj.paginationType && state.recentOperations.length > 0) {
                    const lastSeenDocument = queryObj.paginationType === 'next' ? 
                    state.recentOperations[state.recentOperations.length - 1].formRef : state.recentOperations[0].formRef;
                    
                    if(queryObj.paginationType === 'next') query = query.startAfter(lastSeenDocument);
                    else if(queryObj.paginationType === 'prev') query = query.endBefore(lastSeenDocument);
                }

                if(!queryObj.paginationType || (queryObj.paginationType && !(await query.limit(1).get()).empty)) {
                    if(queryObj.paginationType === 'prev') query = query.limitToLast(state.deviceInfoLimit);
                    else query = query.limit(state.deviceInfoLimit);
                    
                    commit('clearRecentOperations');
                    state.recentOperationsSnapshot = query.onSnapshot(snapshot => {
                        snapshot.docChanges().forEach(async form => {
                            const formDoc = form.doc;

                            if(form.type === 'added') {
                                state.recentOperations.push({
                                    formID: formDoc.id,
                                    formRef: formDoc,
                                    ...formDoc.data()
                                });
                            }
                        });
                    });
                }
            } catch(err) {
                console.log(err);
                throw new Error("Cihaz geçmişi okunamadı");
            }
        },
        async readChangedEquipments({ state, commit }, queryObj = {}) {
            try {
                const device = state.selectedDevice;
                if(!device || !device.deviceID || !device.brand || !device.brand.brandID || !device.model || !device.model.modelID)
                    return;

                /* Constructing The Query */
                let query = brands.doc(state.selectedDevice.brand.brandID).collection('models')
                .doc(state.selectedDevice.model.modelID).collection('devices').doc(state.selectedDevice.deviceID)
                .collection('changedEquipments').orderBy('changedEquipmentDate', 'desc')

                if(queryObj.staffID)
                    query = query.where('changedEquipmentStaffID', '==', queryObj.staffID);

                /* Pagination */
                if(queryObj.paginationType && state.changedEquipments.length > 0) {
                    const lastSeenDocument = queryObj.paginationType === 'next' ? 
                    state.changedEquipments[state.changedEquipments.length - 1].changedEquipmentRef : state.changedEquipments[0].changedEquipmentRef;
                    
                    if(queryObj.paginationType === 'next') query = query.startAfter(lastSeenDocument);
                    else if(queryObj.paginationType === 'prev') query = query.endBefore(lastSeenDocument);
                }

                if(!queryObj.paginationType || (queryObj.paginationType && !(await query.limit(1).get()).empty)) {
                    if(queryObj.paginationType === 'prev') query = query.limitToLast(state.deviceInfoLimit);
                    else query = query.limit(state.deviceInfoLimit);
                    
                    commit('clearChangedEquipments');
                    state.changedEquipmentsSnapshot = query.onSnapshot(snapshot => {
                        snapshot.docChanges().forEach(async changedEquipment => {
                            const changedEquipmentDoc = changedEquipment.doc;

                            if(changedEquipment.type === 'added') {
                                state.changedEquipments.push({
                                    changedEquipmentID: changedEquipmentDoc.id,
                                    changedEquipmentRef: changedEquipmentDoc,
                                    ...changedEquipmentDoc.data()
                                });
                            }
                        });
                    });
                }
            } catch(err) {
                console.log(err);
                throw new Error("Değişen parçalar okunamadı");
            }
        },        
    },
    getters: {
        selectedDevice(state) { return state.selectedDevice },
        recentOperations(state) { return state.recentOperations },
        changedEquipments(state) { return state.changedEquipments }
    }
}

export default deviceInfoStore;