/* eslint-disable no-unused-vars */
import { db } from '../firebase/firebase'
const brands = db.collection('brands_new');
const models = db.collectionGroup('models');
const equipments = db.collectionGroup('equipments');

const equipmentsStore = {
    state: () => ({
        equipments: [],
        equipmentsSnapshot: null,
        equipmentsLimit: 5
    }),
    mutations: {
        clearEquipments(state) {
            if(state.equipmentsSnapshot)
                state.equipmentsSnapshot();
            
            state.equipments = [];
            state.equipmentsSnapshot = null;
        }
    },
    actions: {
        async readEquipments({ commit, state }, queryObj = {}) {
            try {
                /* Constructing The Query */
                let query = equipments.orderBy('equipmentSerialNumber');               

                if(queryObj.searchEquipment)
                    query = query.where('equipmentSerialNumber', '>=', queryObj.searchEquipment)
                    .where('equipmentSerialNumber', '<=', queryObj.searchEquipment + '\uf8ff');

                /* Pagination */
                if(queryObj.paginationType && state.equipments.length > 0) {
                    const lastSeenDocument = queryObj.paginationType === 'next' ? 
                    state.equipments[state.equipments.length - 1].equipmentRef : state.equipments[0].equipmentRef;
                    
                    if(queryObj.paginationType === 'next') query = query.startAfter(lastSeenDocument);
                    else if(queryObj.paginationType === 'prev') query = query.endBefore(lastSeenDocument);
                }

                if(!queryObj.paginationType || (queryObj.paginationType && !(await query.limit(1).get()).empty)) {
                    if(queryObj.paginationType === 'prev') query = query.limitToLast(state.equipmentsLimit);
                    else query = query.limit(state.equipmentsLimit);
                    
                    commit('clearEquipments');
                    state.equipmentsSnapshot = query.onSnapshot(snapshot => {
                        snapshot.docChanges().forEach(async equipment => {
                            const equipmentDoc = equipment.doc;
                            if(equipment.type === 'added') {
                                const equipmentObj = {
                                    equipmentID: equipmentDoc.id,
                                    equipmentRef: equipmentDoc,
                                    ...equipmentDoc.data()
                                };
                                state.equipments.push(equipmentObj);
                            }
    
                            if(equipment.type === 'removed')
                                state.equipments.splice(state.equipments.findIndex(equipment => equipment.equipmentID === equipmentDoc.id), 1);
    
                            if(equipment.type === 'modified')
                                state.equipments[state.equipments.findIndex(equipment => equipment.equipmentID === equipmentDoc.id)] = {
                                    equipmentID: equipmentDoc.id,
                                    equipmentRef: equipmentDoc,
                                    ...equipmentDoc.data()
                                }
                        });
                    });
                }
            } catch {
                throw new Error("Parçalar okunamadı");
            }
        },
        async createEquipment({ dispatch }, equipmentObj) {
            try {
                equipmentObj.type = 'create';
                await dispatch('validateEquipment', equipmentObj);

                try { 
                    const equipment = brands.doc(equipmentObj.brand.brandID)
                    .collection('models').doc(equipmentObj.model.modelID).collection('equipments').doc();
                
                    equipment.set({
                        equipmentID: equipment.id,
                        equipmentCode: equipmentObj.equipmentCode,
                        equipmentName: equipmentObj.equipmentName,
                        equipmentSerialNumber: equipmentObj.equipmentSerialNumber
                }); }
                catch { throw new Error('Parça eklendi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async createEquipmentJSON({ dispatch }, equipmentObj) {
            try {
                const necessaryIDs = await dispatch('validateEquipmentJSON', equipmentObj);

                try { 
                    const equipment = brands.doc(necessaryIDs.brandID)
                    .collection('models').doc(necessaryIDs.modelID).collection('equipments').doc();
                
                    equipment.set({
                        equipmentID: equipment.id,
                        equipmentCode: equipmentObj.equipmentCode,
                        equipmentName: equipmentObj.equipmentName,
                        equipmentSerialNumber: equipmentObj.equipmentSerialNumber
                }); }
                catch { throw new Error('Parça eklendi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async updateEquipment({ dispatch }, equipmentObj) {
            try {
                equipmentObj.type = 'update';
                await dispatch('validateEquipment', equipmentObj);

                if(!equipmentObj.equipmentRef)
                    throw Error('Parça referansı bulunamadı');

                if(equipmentObj.equipmentOriginalSerialNumber !== equipmentObj.equipmentSerialNumber) {
                    let inventoryEquipments = await equipmentObj.equipmentRef.collection('inventory')
                    .where('inventorySerialNumber', '==', equipmentObj.equipmentOriginalSerialNumber).limit(500).get();
                    while(inventoryEquipments && inventoryEquipments.size > 0) {
                        try {
                            let batch = db.batch();
                            inventoryEquipments.forEach(inventoryEquipment => { batch.update(inventoryEquipment.ref, { inventorySerialNumber: equipmentObj.equipmentSerialNumber }) });
                            await batch.commit();
                        } catch(err) { console.log(err); throw new Error('Bazı stok seri numaraları güncellenemedi') }
                        inventoryEquipments = await equipmentObj.equipmentRef.collection('inventory')
                        .where('inventorySerialNumber', '==', equipmentObj.equipmentOriginalSerialNumber).limit(500).get();
                    }
                }
                
                try { await equipmentObj.equipmentRef.update({
                    equipmentID: equipmentObj.equipmentID,
                    equipmentCode: equipmentObj.equipmentCode,
                    equipmentName: equipmentObj.equipmentName,
                    equipmentSerialNumber: equipmentObj.equipmentSerialNumber
                }) }
                catch { throw new Error('Parça güncellenemedi') }
            } catch(err) { throw new Error(err) }
        },
        async validateEquipmentJSON(context, equipmentObj) {
            try {
                if(!equipmentObj.equipmentCode || !equipmentObj.equipmentName || !equipmentObj.equipmentSerialNumber || !equipmentObj.brandName || !equipmentObj.modelName)
                     throw new Error('Lütfen tüm alanları doldurun');
                
                const equipmentDuplicate = await equipments.where('equipmentSerialNumber', '==', equipmentObj.equipmentSerialNumber).limit(1).get();

                if(!equipmentDuplicate.empty)
                    throw new Error('Böyle bir parça zaten var');
    
                const equipmentBrand = await brands.where('brandName', '==', equipmentObj.brandName).get();
                const equipmentModel = await models.where('modelName', '==', equipmentObj.modelName).get();

                if(equipmentBrand.empty)
                    throw new Error('Marka bulunamadı');

                if(equipmentModel.empty)
                    throw new Error('Model bulunamadı');

                return { brandID: equipmentBrand.docs[0].id, modelID: equipmentModel.docs[0].id}
            } catch(err) { throw new Error(err) }
        },
        async validateEquipment(context, equipmentObj) {
            try {
                if((equipmentObj.type === 'update' && !equipmentObj.equipmentID) || !equipmentObj.equipmentCode || !equipmentObj.equipmentName 
                || !equipmentObj.equipmentSerialNumber || !equipmentObj.brand || !equipmentObj.brand.brandName || !equipmentObj.brand.brandID
                || !equipmentObj.model || !equipmentObj.model.modelName || !equipmentObj.model.modelID)
                     throw new Error('Lütfen tüm alanları doldurun');
                
                const equipmentDuplicate = await equipments.where('equipmentSerialNumber', '==', equipmentObj.equipmentSerialNumber).limit(1).get();

                if(!equipmentDuplicate.empty)
                    if(equipmentObj.type === 'create' || (equipmentObj.type === 'update' && equipmentDuplicate.docs[0].id !== equipmentObj.equipmentID))
                        throw new Error('Böyle bir parça zaten var');
    
                const equipmentBrand = await brands.doc(equipmentObj.brand.brandID).get();
                const equipmentModel = await models.where('modelID', '==', equipmentObj.model.modelID).get();

                if(!equipmentBrand.exists)
                    throw new Error('Marka bulunamadı');

                if(equipmentModel.empty)
                    throw new Error('Model bulunamadı');
            } catch(err) { throw new Error(err) }
        },
        async deleteInventory(context, equipmentRef) {
            try {
                let inventory = await equipmentRef.collection('inventory').limit(500).get();
                while(inventory && inventory.size > 0) {
                    try {
                        const batch = db.batch();
                        inventory.forEach(inventory => { batch.delete(inventory.ref) });
                        await batch.commit();
                    } catch(err) { throw new Error('Bazı stoklar silinemedi, lütfen bu işlemi daha sonra tekrar deneyin') }
                    inventory = await equipmentRef.collection('inventory').limit(500).get();
                }
            } catch(err) { throw new Error(err) }
        },
        async deleteEquipment({ dispatch, state }, equipmentID) {
            try {
                if(!equipmentID)
                    throw new Error("Parçanın ID'si boş");

                const equipment = await equipments.where('equipmentID', '==', equipmentID).limit(1).get();

                if(equipment.empty)
                    throw new Error('Parça bulunamadı');

                await dispatch('deleteInventory', equipment.docs[0].ref);

                try {
                    await equipment.docs[0].ref.parent.doc(equipmentID).delete();
                    if(state.equipments.length === 0) dispatch('readEquipments');
                }
                
                catch { throw new Error('Parça silinemedi') }
            } catch(err) { throw new Error(err) }
        },
        async deleteEquipments({ dispatch }, modelObj) {
            try {
                let equipments = await brands.doc(modelObj.brandID).collection('models').doc(modelObj.modelID).collection('equipments').limit(500).get();
                while(equipments && equipments.size > 0) {
                    equipments.forEach(async equipment => { await dispatch('deleteInventory', equipment.ref) });

                    try {
                        const batch = db.batch();
                        equipments.forEach(equipment => { batch.delete(equipment.ref) });
                        await batch.commit();
                    } catch(err) { throw new Error('Bazı parçalar silinemedi, lütfen bu işlemi daha sonra tekrar deneyin') }
                    equipments = await brands.doc(modelObj.brandID).collection('models').doc(modelObj.modelID).collection('equipments').limit(500).get();
                } 
            } catch(err) {
                throw new Error(err);
            }
        },
        async getEquipmentByID(context, equipmentID) {
            try {
                const equipmentDoc = equipments.where('equipmentID', '==', equipmentID).limit(1);
                let equipment, retObj = {};

                try { equipment = (await equipmentDoc.get()).docs[0] }
                catch { throw new Error('Parça okunamadı') }

                if(equipment.empty)
                    throw new Error('Parça bulunamadı');
                
                try { 
                    const equipmentData = equipment.data();
                    const equipmentModel = await equipment.ref.parent.parent.get();
                    const equipmentBrand = await equipment.ref.parent.parent.parent.parent.get();
                    retObj = { 
                        equipmentRef: equipment.ref,
                        equipmentID: equipment.id,
                        ...equipmentData,
                        brand: { 
                            brandID: equipmentBrand.id,
                            ...equipmentBrand.data()
                        }, model: {
                            modelID: equipmentModel.id,
                            ...equipmentModel.data()
                        }};
                } catch(err) {throw new Error("Parçanın ait olduğu marka veya model okunamadı") } 

                return retObj;
            }
            catch(err) { throw new Error(err) }
        }
    },
    getters: {
        equipments(state) { return state.equipments },
    }
}

export default equipmentsStore;