<template>
    <div class="add-container">
        <div class="sidebyside">
            <div class="sidebyside__left">
                <div class="sidebyside__top sidebyside__top--center">
                    <div class="sidebyside__heading-container">
                        <img :src="modelImg" alt="" class="sidebyside__heading-img">
                        <h1 class="sidebyside__heading">{{ operationHeadingText }}</h1>
                    </div>
                    <a @click.prevent="openExcelImportPopup" v-if="operationType === 'create'" href="" class="sidebyside__import-excel">excel import</a>
                </div>
                <form action="" class="sidebyside__form">
                    <div class="sidebyside__form-group">
                        <input v-model="modelObj.modelName" type="text" name="create-model-name" id="create-model-name" placeholder="Model Adı" autocomplete="off">
                    </div>
                    <button :class="{'disabled-btn': dbController}" @click.prevent="createOrUpdateModel" class="sidebyside__submit"><div v-if="dbController" class="loading"></div>{{ operationButtonText }}</button>
                </form>
            </div>
            <div class="sidebyside__right">
                <img :src="modelMainImg" alt="Yeni Model Ekle / Düzenle">
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'
import router from '../../../router/router'

export default {
    data() {
        return {
            dbController: false,

            /* Model Operations */
            modelObj: {
                brandID: '',
                modelID: '',
                modelName: '',
                modelOriginalName: ''
            },

            /* Brand Name */
            brandName: '',

            /* Operation Type */
            operationType: '',

            /* Operation Type Texts */
            operationButtonText: '',
            operationHeadingText: '',

            /* Images */ 
            modelMainImg: require('../../../assets/img/model/model-main.svg'),
            modelImg: require('../../../assets/img/model/model.svg'),
        }
    },
    async created() {
        this.modelObj.modelID = router.currentRoute.query.modelID;
        this.modelObj.brandID = router.currentRoute.query.brandID;
        this.brandName = router.currentRoute.query.brandName;

        if(this.modelObj.brandID && this.brandName && this.modelObj.modelID) {
            try {
                this.operationType = 'update';
                this.modelObj.modelOriginalName = this.modelObj.modelName = (await store.dispatch('getModelByID', this.modelObj)).modelName;
            } catch(err) {
                store.commit('triggerError', err);
                this.redirectToBrands();
            }
        }
        else if(this.modelObj.brandID && this.brandName) this.operationType = 'create';
        else this.operationType = 'unknown';
    },
    methods: {
        /* Excel Add */
        openExcelImportPopup() { 
            store.commit('updateJSONPopup', { 
                openDialog: true, 
                redirectFunction: this.redirectToBrands, 
                addFunction: this.createModelWithoutRedirection
            });
        },
        async createModelWithoutRedirection(modelObj) {
            try {
                if(!modelObj || !modelObj.modelName) {
                    store.commit('triggerError', 'Eklenecek modelin adı yok');
                    return;
                }

                await store.dispatch('createModel', { brandID: this.modelObj.brandID, modelName: modelObj.modelName });
                store.commit('triggerSuccess', modelObj.modelName + ' modeli eklendi');
            } catch(err) { store.commit('triggerError', modelObj.modelName + ' modeli eklenemedi') }
        },
        /* Create Or Update */
        async createOrUpdateModel() {
            this.dbController = true;
            if(this.operationType === 'create') await this.createModel();
            else if(this.operationType === 'update') await this.updateModel();
            else this.unknownOperationHandler();
            this.dbController = false;
        },
        async createModel() {
            try {
                await store.dispatch('createModel', this.modelObj);
                this.redirectToBrands();
                store.commit('triggerSuccess', 'Model eklendi');
            } catch(err) { store.commit('triggerError', err) }
        },
        async updateModel() {
            try {
                await store.dispatch('updateModel', this.modelObj);
                this.redirectToBrands();
                store.commit('triggerSuccess', 'Model güncellendi');
            } catch(err) { store.commit('triggerError', err) }
        },
        unknownOperationHandler() {
            store.commit('triggerError', 'Model bulunamadı');
            this.redirectToBrands();
        },
        redirectToBrands() {
            router.push('/superadmin/brands');
        },
    },
    watch: {
        operationType() {
            if(this.operationType === 'create') {
                this.operationButtonText = 'model ekle';
                this.operationHeadingText = this.brandName.toUpperCase() + '\nModel Ekle';
            } else if(this.operationType === 'update') {
                this.operationButtonText = 'düzenlemeyi bitir';
                this.operationHeadingText = this.brandName.toUpperCase() + '\nModel Düzenle';
            } else this.unknownOperationHandler();
        }
    }
}
</script>