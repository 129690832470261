<template>
    <div class="dashboard-component dashboard-component--middle">
        <div class="table">
            <div class="table-head">
                <div class="table-head__left">
                    <img :src="tableImg" class="table-head__img">
                    <h1 class="table-head__heading">{{ selectedDevice ? selectedDevice.deviceSerialNumber + ' / ' : '' }} Cihaz Bilgisi</h1>
                </div>
                <div class="table-head__right">
                    <div class="table-head__selection-group table-head__selection-group--big">
                        <img :src="downArrowImg" alt="">
                        <select v-model="selectedType" class="table-head__selection">
                            <option value="0" selected>Geçmiş İşlemler</option>
                            <option value="1">Değişen Parçalar</option>
                        </select>
                    </div>
                </div>
            </div>
            <transition name="fade" mode="out-in" v-if="(selectedType == 0 && recentOperations.length > 0) || (selectedType == 1 && changedEquipments.length > 0)">
                <div>
                    <div class="table-content">
                        <div v-if="selectedType == 0">
                            <div class="table-content__header table-content__header--small">
                                <div class="table-content__header-item">Tarih</div>
                                <div class="table-content__header-item">Teknik Servis</div>
                                <div class="table-content__header-item">Form</div>
                            </div>
                            <div v-for="recentOperation in recentOperations" :key="recentOperation.formID" class="table-content__row table-content__header--small">
                                <div class="table-content__row-item">{{ normalizeDate(recentOperation.formDate) }}</div>
                                <div class="table-content__row-item">{{ recentOperation.formStaffName }}</div>
                                <div class="table-content__row-item"><a @click.prevent="generateServiceFormPDF(recentOperation)" href=""><img :src="downloadImg"></a></div>
                            </div>
                        </div>
                        <div v-if="selectedType == 1">
                            <div class="table-content__header table-content__header--small">
                                <div class="table-content__header-item">Tarih</div>
                                <div class="table-content__header-item">Parça Adı</div>
                                <div class="table-content__header-item">Parça Miktarı</div>
                            </div>
                            <div v-for="changedEquipment in changedEquipments" :key="changedEquipment.changedEquipmentID" class="table-content__row table-content__header--small">
                                <div class="table-content__row-item">{{ normalizeDate(changedEquipment.changedEquipmentDate) }}</div>
                                <div class="table-content__row-item">{{ changedEquipment.changedEquipmentName }}</div>
                                <div class="table-content__row-item">{{ changedEquipment.changedEquipmentQuantity }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="pagination pagination--extra-margin">
                        <a @click.prevent="readRecentOperationsOrChangedEquipments('prev')" href="" class="pagination__item pagination__item--left pagination__item--active">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.896" height="12.26" viewBox="0 0 6.896 12.26">
                                <path class="pagination__svg" d="M6.708,12.075a.622.622,0,0,0,.062-.821l-.062-.071L1.553,6.13,6.708,1.077A.622.622,0,0,0,6.77.255L6.708.185A.653.653,0,0,0,5.87.124L5.8.185l-5.61,5.5a.622.622,0,0,0-.062.821l.062.071,5.61,5.5A.652.652,0,0,0,6.708,12.075Z" fill="#1e202b"/>
                            </svg>
                            <div class="pagination__name">Önceki</div>
                        </a>
                        <a @click.prevent="readRecentOperationsOrChangedEquipments('next')" href="" class="pagination__item pagination__item--right pagination__item--active">
                            <div class="pagination__name">Sonraki</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.896" height="12.26" viewBox="0 0 6.896 12.26">
                                <path class="pagination__svg" d="M.188,12.075a.622.622,0,0,1-.062-.821l.062-.071L5.343,6.13.188,1.077A.622.622,0,0,1,.126.255L.188.185A.653.653,0,0,1,1.026.124L1.1.185l5.61,5.5a.622.622,0,0,1,.062.821l-.062.071-5.61,5.5A.652.652,0,0,1,.188,12.075Z" fill="#1e202b"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </transition>
            <transition name="fade" mode="out-in" v-else>
                <div class="table-not-found" style="margin-top: 5rem" key="1">
                    <img class="table-not-found__img table-not-found__img--90p" :src="deviceNotFoundImg">
                    <div class="table-not-found__text">{{ selectedDevice ? (selectedType == 0 ? 'Geçmiş işlem yok' : 'Değişen parça yok') : 'Lütfen önce cihaz seçin' }}</div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'

export default {
    data() {
        return {
            selectedType: 0,

            /* Images */
            tableImg: require('../../../assets/img/inventory/inventory.svg'),
            downArrowImg: require('../../../assets/img/util/down-arrow.svg'),
            downloadImg: require('../../../assets/img/util/download.svg'),
            deviceNotFoundImg: require('../../../assets/img/device/device-main.svg')
        }
    },
    computed: {
        user() { return store.getters.user },
        selectedDevice() { return store.getters.selectedDevice },
        recentOperations() { return store.getters.recentOperations },
        changedEquipments() { return store.getters.changedEquipments }
    },
    destroyed() {
        store.commit('clearSelectedDevice');
        store.commit('clearRecentOperations');
        store.commit('clearChangedEquipments');
    },
    async created() {
        await this.readRecentOperationsOrChangedEquipments();
    },
    methods: {
        async readRecentOperationsOrChangedEquipments(paginationType = null) {
            try { 
                await store.dispatch(this.selectedType == 0 ? 'readRecentOperations' : 'readChangedEquipments', {
                    staffID: (this.user && this.user.admin) ? null : this.user.uid,
                    paginationType
                });
            }
            catch(err) {
                store.commit('triggerError', err)
            }
        },
        generateServiceFormPDF(recentOperation) {
            store.dispatch('generateServiceFormPDF', recentOperation);
        },
        normalizeDate(date) {
            const normalizedDate = new Date(date.seconds * 1000).toISOString().slice(0, 10), normalizedDateSplitted = normalizedDate.split('-');
            const year = normalizedDateSplitted[0], month = normalizedDateSplitted[1], day = normalizedDateSplitted[2];
            return day + '.' + month + '.' + year;
        }
    },
    watch: {
        async selectedDevice() { await this.readRecentOperationsOrChangedEquipments() },
        async selectedType() { await this.readRecentOperationsOrChangedEquipments() }
    }
}
</script>