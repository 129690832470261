<template>
    <div ref="popup" class="popup">
        <div ref="popupContainer" class="popup__container">
            <div class="popup__text">{{ message }}</div>
            <div class="popup__btn-container">
                <a @click="rejectPopup" class="popup__btn popup__btn--decline">Hayır</a>
                <a @click="resolvePopup" class="popup__btn popup__btn--approve">Evet</a>
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'

export default {
    mounted() {
        this.$store.commit('passPopupReference', {
            popup: this.$refs.popup,
            popupContainer: this.$refs.popupContainer
        });
    },
    methods: {
        resolvePopup() {
            store.dispatch('resolvePopup');
        },
        rejectPopup() {
            store.dispatch('rejectPopup');
        }
    },
    computed: {
        message() {
            return store.getters.getPopupMessage;
        }
    }
}
</script>