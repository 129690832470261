/* eslint-disable no-unused-vars */
import gsap from 'gsap'
import { Power4 } from 'gsap'

const alertStore = {
    state: () => ({
        alertBoxRef: '',
        message: '',
        messageType: '',
        animating: false
    }),
    mutations: {
        passAlertBoxReference(state, payload) {
            state.alertBoxRef = payload;
        },
        triggerError(state, message) {
            this.commit('triggerAlert', {
                message: message,
                messageType: 'error'
            });
        },
        triggerSuccess(state, message) {
            this.commit('triggerAlert', {
                message: message,
                messageType: 'success'
            });
        },
        triggerAlert(state, messageObj) {
            state.message = messageObj.message;
            state.messageType = messageObj.messageType;
            this.commit('animateAlert');
        },
        animateAlert(state) {
            if(state.animating || !state.alertBoxRef)
                return;
                
            state.animating = true;
            let tl = gsap.timeline();
            tl.to(state.alertBoxRef, {duration: 1, xPercent: -120, opacity: 1, ease: Power4.easeOut} )
            .to(state.alertBoxRef, {delay: 2, duration: .5, opacity: 0, ease: Power4.easeIn,
            xPercent: 120, onComplete: () => {state.animating = false}});
        }
    },
    getters: {
        getMessage(state) {
            return {
                text: state.message,
                type: state.messageType
            }
        }
    }
}

export default alertStore;