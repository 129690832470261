/* Import Store */
import store from '../store/store'

/* Import Vue Router */
import Vue from 'vue'
import VueRouter from 'vue-router'

/* Import Routes */
import loginRoutes from './login/routes'
import generalRoutes from './general/routes'
import superAdminRoutes from './superadmin/routes'

Vue.use(VueRouter);

/* Construct Routes */
const routes = {
    mode: 'history',
    routes: [
        loginRoutes,
        superAdminRoutes,
        ...generalRoutes,
    ],
    scrollBehavior() {
        return {x: 0, y: 0}
    }
}

const router = new VueRouter(routes);

const originalPush = router.push
router.push = function push(location, onResolve, onReject)
{
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
 
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            return err
        }
   
        return Promise.reject(err)
    })
}
/* Auth Guard */
router.beforeEach((to, from, next) => {
    const user = store.getters.user;
    let requiresAuth, requiresAdmin, requiresStaff;

    to.matched.some(route => {
        requiresAuth = route.meta.requiresAuth;
        requiresAdmin = route.meta.requiresAdmin;
        requiresStaff = route.meta.requiresStaff;
    });

    if(!user && to.path !== '/login') {
        next('/login');
        return;
    }

    if((!requiresAuth && user) || (requiresAuth && (to.path === '/') || (to.path === ''))) {
        next(user.admin ? '/admin' : '/staff');
        return;
    }

    if(requiresAuth && requiresStaff && user.admin) {
        next('/admin');
        return;
    }

    if(requiresAuth && requiresAdmin && !user.admin) {
        next('/staff');
        return;
    }

    next();
});

export default router;