/* Import Staff */
import StaffView from '../../components/superadmin/staff/StaffView.vue'
import Staff from '../../components/superadmin/staff/Staff.vue'
import StaffOp from '../../components/superadmin/staff/StaffOp.vue'

const routes = {
    path: 'staff',
    component: StaffView,
    children: [
        {
            path: '/',
            component: Staff,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
        {
            path: 'staff-op',
            component: StaffOp,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
    ],
    meta: {
        requiresAuth: true,
        requiresAdmin: true
    }
}

export default routes;