import Vue from 'vue'
import Vuex from 'vuex'
import authStore from './authStore';
import alertStore from './alertStore';
import popupStore from './popupStore';
import regionsStore from './regionsStore';
import fixedsStore from './fixedsStore';
import devicesStore from './devicesStore';
import equipmentsStore from './equipmentsStore';
import brandsStore from './brandsStore';
import staffsStore from './staffsStore';
import inventoryStore from './inventoryStore';
import deviceInfoStore from './deviceInfoStore';
import staffInfoStore from './staffInfoStore';
import serviceFormPDFStore from './serviceFormPDFStore';
import excelToJSON from './excelToJSON'

Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        alertStore,
        popupStore,
        authStore,
        regionsStore,
        fixedsStore,
        devicesStore,
        equipmentsStore,
        brandsStore,
        staffsStore,
        inventoryStore,
        deviceInfoStore,
        staffInfoStore,
        serviceFormPDFStore,
        excelToJSON
    }
});

export default store;