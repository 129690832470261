<template>
    <div class="flex-component flex-component--flex flex-component--responsive">
        <app-staff-menu></app-staff-menu>
        <app-inventory-status></app-inventory-status>
        <div class="flex-component--flex">
            <app-performance-info></app-performance-info>
            <app-search-device></app-search-device>
        </div>
    </div>
</template>

<script>
import StaffMenu from '../menus/StaffMenu.vue'
import InventoryStatus from './InventoryStatus.vue'
import PerformanceInfo from './PerformanceInfo.vue'
import SearchDevice from './SearchDevice.vue'

export default {
    components: {
        'app-staff-menu': StaffMenu,
        'app-inventory-status': InventoryStatus,
        'app-performance-info': PerformanceInfo,
        'app-search-device': SearchDevice
    },
}
</script>