/* eslint-disable no-unused-vars */
import { db } from '../firebase/firebase'
const fixeds = db.collection('fixeds_new');
const hospitals = db.collectionGroup('hospitals');

const fixedsStore = {
    state: () => ({
        fixeds: [],
        fixedsSnapshot: null,
        fixedsLimit: 5
    }),
    mutations: {
        clearFixeds(state) {
            if(state.fixedsSnapshot)
                state.fixedsSnapshot();
            
            state.fixeds = [];
            state.fixedsSnapshot = null;
        }
    },
    actions: {
        async readFixeds({ commit, state }, queryObj = {}) {
            try {
                /* Constructing The Query */
                let query = fixeds.orderBy('fixedSerialNumber');

                if(queryObj.hospitalID)
                    query = query.where('fixedHospital', '==', queryObj.hospitalID);

                if(queryObj.searchFixed)
                    query = query.where('fixedSerialNumber', '>=', queryObj.searchFixed)
                    .where('fixedSerialNumber', '<=', queryObj.searchFixed + '\uf8ff');

                /* Pagination */
                if(queryObj.paginationType && state.fixeds.length > 0) {
                    const lastSeenDocument = queryObj.paginationType === 'next' ? 
                    state.fixeds[state.fixeds.length - 1].fixedRef : state.fixeds[0].fixedRef;
                    
                    if(queryObj.paginationType === 'next') query = query.startAfter(lastSeenDocument);
                    else if(queryObj.paginationType === 'prev') query = query.endBefore(lastSeenDocument);
                }

                if(!queryObj.paginationType || (queryObj.paginationType && !(await query.limit(1).get()).empty)) {
                    if(queryObj.paginationType === 'prev') query = query.limitToLast(state.fixedsLimit);
                    else query = query.limit(state.fixedsLimit);
                    
                    commit('clearFixeds');
                    state.fixedsSnapshot = query.onSnapshot(snapshot => {
                        snapshot.docChanges().forEach(async fixed => {
                            const fixedDoc = fixed.doc;
                            if(fixed.type === 'added') {
                                const fixedObj = {
                                    fixedID: fixedDoc.id,
                                    fixedRef: fixedDoc,
                                    ...fixedDoc.data()
                                };
                                state.fixeds.push(fixedObj);
                            }
    
                            if(fixed.type === 'removed')
                                state.fixeds.splice(state.fixeds.findIndex(fixed => fixed.fixedID === fixedDoc.id), 1);
    
                            if(fixed.type === 'modified')
                                state.fixeds[state.fixeds.findIndex(fixed => fixed.fixedID === fixedDoc.id)] = {
                                    fixedID: fixedDoc.id,
                                    fixedRef: fixedDoc,
                                    ...fixedDoc.data()
                                }
                        });
                    });
                }
            } catch(err) {
                console.log(err);
                throw new Error("Demirbaşlar okunamadı");
            }
        },
        async createFixed({ dispatch }, fixedObj) {
            try {
                fixedObj.type = 'create';
                await dispatch('validateFixed', fixedObj);
                
                try { await fixeds.add({
                    fixedBrand: fixedObj.fixedBrand,
                    fixedModel: fixedObj.fixedModel,
                    fixedSerialNumber: fixedObj.fixedSerialNumber,
                    fixedHospital: fixedObj.hospital.hospitalID
                }); }
                catch { throw new Error('Demirbaş eklendi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async createFixedJSON({ dispatch }, fixedObj) {
            try {
                const hospitalID = await dispatch('validateFixedJSON', fixedObj);
                
                try { await fixeds.add({
                    fixedBrand: fixedObj.fixedBrand,
                    fixedModel: fixedObj.fixedModel,
                    fixedSerialNumber: fixedObj.fixedSerialNumber,
                    fixedHospital: hospitalID
                }); }
                catch { throw new Error('Demirbaş eklendi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async updateFixed({ dispatch }, fixedObj) {
            try {
                fixedObj.type = 'update';
                await dispatch('validateFixed', fixedObj);
                
                try { await fixeds.doc(fixedObj.fixedID).update({
                    fixedBrand: fixedObj.fixedBrand,
                    fixedModel: fixedObj.fixedModel,
                    fixedSerialNumber: fixedObj.fixedSerialNumber,
                    fixedHospital: fixedObj.hospital.hospitalID
                }); }
                catch { throw new Error('Demirbaş güncellenemedi') }
            } catch(err) { throw new Error(err) }
        },
        async validateFixedJSON(context, fixedObj) {
            try {
                if(!fixedObj.fixedBrand || !fixedObj.fixedModel || !fixedObj.fixedSerialNumber || !fixedObj.hospitalName)
                     throw new Error('Lütfen tüm alanları doldurun');
    
                const fixedHospital = await hospitals.where('hospitalName', '==', fixedObj.hospitalName).limit(1).get();
                const fixedDuplicate = await fixeds.where('fixedSerialNumber', '==', fixedObj.fixedSerialNumber).limit(1).get();
             
                if(fixedHospital.empty)
                    throw new Error('Girdiğiniz hastane bulunamadı');

                if(!fixedDuplicate.empty)
                    throw new Error('Böyle bir demirbaş zaten var');
    
                return fixedHospital.docs[0].id;
            } catch(err) { throw new Error(err) }
        },
        async validateFixed(context, fixedObj) {
            try {
                if((fixedObj.type === 'update' && !fixedObj.fixedID) || !fixedObj.fixedBrand || !fixedObj.fixedModel 
                || !fixedObj.fixedSerialNumber || !fixedObj.hospital || !fixedObj.hospital.hospitalName || !fixedObj.hospital.hospitalID)
                     throw new Error('Lütfen tüm alanları doldurun');
    
                const fixedHospital = await hospitals.where('hospitalName', '==', fixedObj.hospital.hospitalName).limit(1).get();
                const fixedDuplicate = await fixeds.where('fixedSerialNumber', '==', fixedObj.fixedSerialNumber).limit(1).get();
             
                if(fixedHospital.empty)
                    throw new Error('Girdiğiniz hastane bulunamadı');

                if(!fixedDuplicate.empty)
                    if(fixedObj.type === 'create' || (fixedObj.type === 'update' && fixedDuplicate.docs[0].id !== fixedObj.fixedID))
                        throw new Error('Böyle bir demirbaş zaten var');
    
                if(fixedHospital.docs[0].id !== fixedObj.hospital.hospitalID)
                    throw new Error("Hastane ismi ile ID'si uyuşmuyor");
            } catch(err) { throw new Error(err) }
        },
        async deleteFixed({ dispatch, state }, fixedID) {
            try {
                if(!fixedID) throw new Error("Demirbaş ID'si boş");
                if(!(await fixeds.doc(fixedID).get()).exists) throw new Error('Demirbaş bulunamadı');

                try {
                    await fixeds.doc(fixedID).delete();
                    if(state.fixeds.length === 0) dispatch('readFixeds');
                }
                catch { throw new Error('Demirbaş silinemedi') }
            } catch(err) { throw new Error(err) }
        },
        async getFixedByID(context, fixedID) {
            try {
                let fixed, fixedHospital, retObj = {};
                try { fixed = await fixeds.doc(fixedID).get() }
                catch { throw new Error('Demirbaş okunamadı') }

                if(fixed.empty) throw new Error('Demirbaş bulunamadı');
                
                try { fixedHospital = await hospitals.where('hospitalID', '==', fixed.data().fixedHospital).limit(1).get() }
                catch(err) {throw new Error("Demirbaş'ın hastanesi okunamadı") } 
                
                if(fixedHospital.empty) retObj = { fixedID: fixed.id, ...fixed.data(), hospital: { hospitalID: null, hospitalName: 'Boşta' }};
                else retObj = { fixedID: fixed.id, ...fixed.data(), hospital: { hospitalID: fixedHospital.docs[0].id, ...fixedHospital.docs[0].data() }};
                    
                return retObj;
            }
            catch(err) { throw new Error(err) }
        }
    },
    getters: {
        fixeds(state) { return state.fixeds },
    }
}

export default fixedsStore;