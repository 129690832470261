import { db, auth } from '../firebase/firebase'
import router from '../router/router';
import store from './store';

const authStore = {
    state: () => ({
        user: null
    }),
    actions: {
        async signIn({ state, commit, dispatch }, user) {
            if(!user || !user.email || !user.password) {
                commit('triggerError', 'Email veya şifre boş');
                return;
            }
            try {
                await dispatch('signInHelper', (await auth.signInWithEmailAndPassword(user.email, user.password)).user);
                commit('triggerSuccess', 'Giriş Yapıldı');
                router.push(state.user.admin ? '/admin' : '/staff');
            } catch {
                commit('triggerError', 'Giriş Yapılamadı');
            }
        },
        async signInHelper({ state, commit, dispatch }, user){
            if(!user) {
                dispatch('signOutHelper');
                return;
            }

            try {
                state.user = { uid: user.uid };
                state.user.admin = (await auth.currentUser.getIdTokenResult()).claims.admin;
            } catch {
                dispatch('signOut');
                store.commit('triggerError', 'Giriş yapılamadı');
            }
            
            try {
                await dispatch('readRegions');
                await dispatch('readBrands');
                if(state.user.admin) await dispatch('readStaffs');  
                else state.user.staffName = (await db.collection('staff_new').doc(state.user.uid).get()).data().staffName;
            } catch(err) {
                dispatch('signOut');
                commit('triggerError', err);
            }
        },
        async signOut({ commit, dispatch }) {
            try {
                auth.signOut();
                await dispatch('signOutHelper');
                if(router.currentRoute.path !== '/login')
                    router.push('/login');
                commit('triggerSuccess', 'Çıkış Yapıldı');
            } catch {
                commit('triggerError', 'Çıkış Yapılamadı');
            }
        },
        signOutHelper({ state, commit }) {
            state.user = null;
            router.push('/login');

            /* Clear Snapshots */
            commit('clearRegions');
            commit('clearBrands');
            commit('clearDevices');
            commit('clearEquipments');
            commit('clearFixeds');
            commit('clearInventory');
            commit('clearStaffs');
        }
    },
    getters: {
        user(state) { return state.user },
    }
}

export default authStore;