/* eslint-disable no-unused-vars */
import { db } from '../firebase/firebase'
const brands = db.collection('brands_new');
const models = db.collectionGroup('models');
const devices = db.collectionGroup('devices');
const hospitals = db.collectionGroup('hospitals');

const devicesStore = {
    state: () => ({
        devices: [],
        devicesSnapshot: null,
        devicesLimit: 5
    }),
    mutations: {
        clearDevices(state) {
            if(state.devicesSnapshot)
                state.devicesSnapshot();
            
            state.devices = [];
            state.devicesSnapshot = null;
        }
    },
    actions: {
        async readDevices({ commit, state }, queryObj = {}) {
            try {
                /* Constructing The Query */
                let query = devices.orderBy('deviceSerialNumber');

                if(queryObj.hospitalID)
                    query = devices.where('deviceHospital', '==', queryObj.hospitalID);

                if(queryObj.brandID)
                    query = query.where('deviceBrand', '==', queryObj.brandID);

                if(queryObj.modelID)
                    query = query.where('deviceModel', '==', queryObj.modelID);        
                    
                if(queryObj.searchDevice)
                    query = query.where('deviceSerialNumber', '>=', queryObj.searchDevice)
                    .where('deviceSerialNumber', '<=', queryObj.searchDevice + '\uf8ff');

                /* Pagination */
                if(queryObj.paginationType && state.devices.length > 0) {
                    const lastSeenDocument = queryObj.paginationType === 'next' ? 
                    state.devices[state.devices.length - 1].deviceRef : state.devices[0].deviceRef;
                    
                    if(queryObj.paginationType === 'next') query = query.startAfter(lastSeenDocument);
                    else if(queryObj.paginationType === 'prev') query = query.endBefore(lastSeenDocument);
                }

                if(!queryObj.paginationType || (queryObj.paginationType && !(await query.limit(1).get()).empty)) {
                    if(queryObj.paginationType === 'prev') query = query.limitToLast(state.devicesLimit);
                    else query = query.limit(state.devicesLimit);
                    
                    commit('clearDevices');
                    state.devicesSnapshot = query.onSnapshot(snapshot => {
                        snapshot.docChanges().forEach(async device => {
                            const deviceDoc = device.doc;

                            if(device.type === 'added') {
                                state.devices.push({
                                    deviceID: deviceDoc.id,
                                    deviceRef: deviceDoc,
                                    ...deviceDoc.data(),
                                    brand: {
                                        brandID: deviceDoc.ref.parent.parent.parent.parent.id,
                                        ...(await deviceDoc.ref.parent.parent.parent.parent.get()).data()
                                    },
                                    model: {
                                        modelID: deviceDoc.ref.parent.parent.id,
                                        ...(await deviceDoc.ref.parent.parent.get()).data()
                                    }
                                });
                            }
    
                            if(device.type === 'removed')
                                state.devices.splice(state.devices.findIndex(device => device.deviceID === deviceDoc.id), 1);
    
                            if(device.type === 'modified')
                                state.devices[state.devices.findIndex(device => device.deviceID === deviceDoc.id)] = {
                                    deviceID: deviceDoc.id,
                                    deviceRef: deviceDoc,
                                    ...deviceDoc.data(),
                                    brand: {
                                        brandID: deviceDoc.ref.parent.parent.parent.parent.id,
                                        ...(await deviceDoc.ref.parent.parent.parent.parent.get()).data()
                                    },
                                    model: {
                                        modelID: deviceDoc.ref.parent.parent.id,
                                        ...(await deviceDoc.ref.parent.parent.get()).data()
                                    }
                                }
                        });
                    });
                }
            } catch(err) {
                console.log(err);
                throw new Error("Cihazlar okunamadı");
            }
        },
        async createDevice({ dispatch }, deviceObj) {
            try {
                deviceObj.type = 'create';
                await dispatch('validateDevice', deviceObj);

                try { 
                    const device = brands.doc(deviceObj.brand.brandID)
                    .collection('models').doc(deviceObj.model.modelID).collection('devices').doc();
                    
                    device.set({
                        deviceID: device.id,
                        deviceProductionYear: deviceObj.deviceProductionYear,
                        deviceSerialNumber: deviceObj.deviceSerialNumber,
                        deviceHospital: deviceObj.hospital.hospitalID,
                        deviceBrand: deviceObj.brand.brandID,
                        deviceModel: deviceObj.model.modelID
                }); }
                catch { throw new Error('Cihaz eklendi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async createDeviceJSON({ dispatch }, deviceObj) {
            try {
                const necessaryIDs = await dispatch('validateDeviceJSON', deviceObj);

                try { 
                    const device = brands.doc(necessaryIDs.brandID)
                    .collection('models').doc(necessaryIDs.modelID).collection('devices').doc();
                    
                    device.set({
                        deviceID: device.id,
                        deviceProductionYear: deviceObj.deviceProductionYear,
                        deviceSerialNumber: deviceObj.deviceSerialNumber,
                        deviceHospital: necessaryIDs.hospitalID,
                        deviceBrand: necessaryIDs.brandID,
                        deviceModel: necessaryIDs.modelID
                }); }
                catch { throw new Error('Cihaz eklendi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async updateDevice({ dispatch }, deviceObj) {
            try {
                deviceObj.type = 'update';
                await dispatch('validateDevice', deviceObj);

                try { (await devices.where('deviceID', '==', deviceObj.deviceID).limit(1).get()).docs[0].ref.update({
                    deviceID: deviceObj.deviceID,
                    deviceProductionYear: deviceObj.deviceProductionYear,
                    deviceSerialNumber: deviceObj.deviceSerialNumber,
                    deviceHospital: deviceObj.hospital.hospitalID
                }); }
                catch { throw new Error('Cihaz güncellenemedi') }
            } catch(err) { throw new Error(err) }
        },
        async validateDeviceJSON(context, deviceObj) {
            try {
                if(!deviceObj.deviceProductionYear || !deviceObj.deviceSerialNumber || !deviceObj.hospitalName || !deviceObj.brandName || !deviceObj.modelName)
                     throw new Error('Lütfen tüm alanları doldurun');

                if(isNaN(parseInt(deviceObj.deviceProductionYear)))
                    throw new Error('Lütfen üretim yılını doğru girin');
    
                const deviceDuplicate = await devices.where('deviceSerialNumber', '==', deviceObj.deviceSerialNumber).limit(1).get();

                if(!deviceDuplicate.empty)
                    throw new Error('Böyle bir cihaz zaten var');

                const deviceBrand = await brands.where('brandName', '==', deviceObj.brandName).get();
                const deviceModel = await models.where('modelName', '==', deviceObj.modelName).get();
                const hospital = await hospitals.where('hospitalName', '==', deviceObj.hospitalName).get();

                if(deviceBrand.empty)
                    throw new Error('Marka bulunamadı');

                if(deviceModel.empty)
                    throw new Error('Model bulunamadı');

                if(hospital.empty)
                    throw new Error('Hastane bulunamadı');

                return { brandID: deviceBrand.docs[0].id, modelID: deviceModel.docs[0].id, hospitalID: hospital.docs[0].id}
            } catch(err) { throw new Error(err) }
        },
        async validateDevice(context, deviceObj) {
            try {
                if((deviceObj.type === 'update' && !deviceObj.deviceID) || !deviceObj.deviceProductionYear || !deviceObj.deviceSerialNumber 
                || !deviceObj.hospital || !deviceObj.hospital.hospitalName || !deviceObj.hospital.hospitalID
                || !deviceObj.brand || !deviceObj.brand.brandName || !deviceObj.brand.brandID
                || !deviceObj.model || !deviceObj.model.modelName || !deviceObj.model.modelID)
                     throw new Error('Lütfen tüm alanları doldurun');
    
                const deviceDuplicate = await devices.where('deviceSerialNumber', '==', deviceObj.deviceSerialNumber).limit(1).get();

                if(!deviceDuplicate.empty)
                    if(deviceObj.type === 'create' || (deviceObj.type === 'update' && deviceDuplicate.docs[0].id !== deviceObj.deviceID))
                        throw new Error('Böyle bir cihaz zaten var');

                const deviceBrand = await brands.doc(deviceObj.brand.brandID).get();
                const deviceModel = await models.where('modelID', '==', deviceObj.model.modelID).get();
                const hospital = await hospitals.where('hospitalID', '==', deviceObj.hospital.hospitalID).get();

                if(!deviceBrand.exists)
                    throw new Error('Marka bulunamadı');

                if(deviceModel.empty)
                    throw new Error('Model bulunamadı');

                if(hospital.empty)
                    throw new Error('Hastane bulunamadı');
            } catch(err) { throw new Error(err) }
        },
        async deleteChangedEquipments(context, deviceRef) {
            try {
                let changedEquipments = await deviceRef.collection('changedEquipments').limit(500).get();
                while(changedEquipments && changedEquipments.size > 0) {
                    try {
                        const batch = db.batch();
                        changedEquipments.forEach(changedEquipment => { batch.delete(changedEquipment.ref) });
                        await batch.commit();
                    } catch(err) { throw new Error('Bazı değişen parça bilgileri silinemedi, lütfen bu işlemi daha sonra tekrar deneyin') }
                    changedEquipments = await deviceRef.collection('changedEquipments').limit(500).get()
                }
            } catch(err) { throw new Error(err) }
        },
        async deleteDevice({ dispatch, state }, deviceID) {
            try {
                if(!deviceID)
                    throw new Error("Cihaz ID'si boş");

                const device = await devices.where('deviceID', '==', deviceID).limit(1).get();

                if(device.empty)
                    throw new Error('Cihaz bulunamadı');

                await dispatch('deleteChangedEquipments', device.docs[0].ref);

                try {
                    await device.docs[0].ref.parent.doc(deviceID).delete();
                    if(state.devices.length === 0) dispatch('readDevices');
                }
                catch { throw new Error('Cihaz silinemedi') }
            } catch(err) { throw new Error(err) }
        },
        async deleteDevices({ dispatch }, modelObj) {
            try {
                let devices = await brands.doc(modelObj.brandID).collection('models').doc(modelObj.modelID).collection('devices').limit(500).get();
                while(devices && devices.size > 0) {
                    devices.forEach(async device => { await dispatch('deleteChangedEquipments', device.ref) });

                    try {
                        const batch = db.batch();
                        devices.forEach(device => { batch.delete(device.ref) });
                        await batch.commit();
                    } catch(err) { throw new Error('Bazı cihazlar silinemedi, lütfen bu işlemi daha sonra tekrar deneyin') }
                    devices = await brands.doc(modelObj.brandID).collection('models').doc(modelObj.modelID).collection('devices').limit(500).get();
                }
            } catch(err) {
                throw new Error(err);
            }
        },
        async getDeviceByID(context, deviceID) {
            try {
                const deviceDoc = devices.where('deviceID', '==', deviceID).limit(1);
                let device, retObj = {};

                try { device = (await deviceDoc.get()).docs[0] }
                catch { throw new Error('Cihaz okunamadı') }

                if(device.empty)
                    throw new Error('Cihaz bulunamadı');
                
                try { 
                    const deviceData = device.data();
                    const deviceModel = await device.ref.parent.parent.get();
                    const deviceBrand = await device.ref.parent.parent.parent.parent.get();
                    const hospital = await hospitals.where('hospitalID', '==', device.data().deviceHospital).limit(1).get();
                    let hospitalObj = {};

                    if(hospital.empty) hospitalObj = { hospital: { hospitalID: null, hospitalName: 'Boşta' }};
                    else hospitalObj = { hospital: { hospitalID: hospital.docs[0].id, ...hospital.docs[0].data() }};

                    retObj = { 
                        deviceID: device.id,
                        ...deviceData,
                        brand: { 
                            brandID: deviceBrand.id,
                            ...deviceBrand.data()
                        }, model: {
                            modelID: deviceModel.id,
                            ...deviceModel.data()
                        }, ...hospitalObj};
                } catch(err) {throw new Error("Cihazın ait olduğu hastane, marka veya model okunamadı") } 

                return retObj;
            }
            catch(err) { throw new Error(err) }
        }
    },
    getters: {
        devices(state) { return state.devices },
    }
}

export default devicesStore;