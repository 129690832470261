<template>
    <div class="add-container">
        <div class="sidebyside">
            <div class="sidebyside__left">
                <div class="sidebyside__top sidebyside__top--center">
                    <div class="sidebyside__heading-container">
                        <img :src="regionImg" alt="" class="sidebyside__heading-img">
                        <h1 class="sidebyside__heading">{{ operationHeadingText }}</h1>
                    </div>
                    <a @click.prevent="openExcelImportPopup" v-if="operationType === 'create'" href="" class="sidebyside__import-excel">excel import</a>
                </div>
                <form action="" class="sidebyside__form">
                    <div class="sidebyside__form-group">
                        <input v-model="regionObj.regionName" type="text" name="create-region-name" id="create-region-name" placeholder="Bölge Adı" autocomplete="off">
                    </div>
                    <button :class="{'disabled-btn': dbController}" @click.prevent="createOrUpdateRegion" class="sidebyside__submit"><div v-if="dbController" class="loading"></div>{{ operationButtonText }}</button>
                </form>
            </div>
            <div class="sidebyside__right">
                <img :src="regionMainImg" alt="Yeni Bölge Ekle / Düzenle">
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'
import router from '../../../router/router'

export default {
    data() {
        return {
            fileName: '',
            dbController: false,

            /* Region Operations */
            regionObj: {
                regionID: '',
                regionName: '',
                regionOriginalName: ''
            },

            /* Operation Type */
            operationType: '',

            /* Operation Type Texts */
            operationButtonText: '',
            operationHeadingText: '',

            /* Images */ 
            regionMainImg: require('../../../assets/img/region/region-main.svg'),
            regionImg: require('../../../assets/img/region/region.svg'),
        }
    },
    async created() {
        this.regionObj.regionID = router.currentRoute.query.regionID;

        if(this.regionObj.regionID) {
            try {
                this.operationType = 'update';
                this.regionObj.regionOriginalName = this.regionObj.regionName = (await store.dispatch('getRegionByID', this.regionObj.regionID)).regionName;
            } catch(err) {
                store.commit('triggerError', err);
                this.redirectToRegions();
            }
        }
        else this.operationType = 'create';
    },
    methods: {
        /* Excel Add */
        openExcelImportPopup() { 
            store.commit('updateJSONPopup', { 
                openDialog: true, 
                redirectFunction: this.redirectToRegions, 
                addFunction: this.createRegionWithoutRedirection
            });
        },
        async createRegionWithoutRedirection(regionObj) {
            try {
                if(!regionObj || !regionObj.regionName) {
                    store.commit('triggerError', 'Eklenecek bölgenin adı yok');
                    return;
                }

                await store.dispatch('createRegion', regionObj.regionName);
                store.commit('triggerSuccess', regionObj.regionName + ' bölgesi eklendi');
            } catch(err) { store.commit('triggerError', regionObj.regionName + ' bölgesi eklenemedi') }
        },
        /* Create Or Update */
        async createOrUpdateRegion() {
            this.dbController = true;
            if(this.operationType === 'create') await this.createRegion();
            else if(this.operationType === 'update') await this.updateRegion();
            this.dbController = false;
        },
        async createRegion() {
            try {
                await store.dispatch('createRegion', this.regionObj.regionName);
                this.redirectToRegions();
                store.commit('triggerSuccess', 'Bölge eklendi');
            } catch(err) { store.commit('triggerError', err) }
        },
        async updateRegion() {
            try {
                await store.dispatch('updateRegion', this.regionObj);
                this.redirectToRegions();
                store.commit('triggerSuccess', 'Bölge güncellendi');
            } catch(err) { store.commit('triggerError', err) }
        },
        redirectToRegions() {
            router.push('/superadmin/regions');
        },
    },
    watch: {
        operationType() {
            if(this.operationType === 'create') {
                this.operationButtonText = 'bölge ekle';
                this.operationHeadingText = 'Bölge Ekle';
            } else if(this.operationType === 'update') {
                this.operationButtonText = 'düzenlemeyi bitir';
                this.operationHeadingText = 'Bölge Düzenle';
            }
        }
    }
}
</script>