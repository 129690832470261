<template>
    <div class="devices">
        <div class="table">
            <div class="table-head">
                <div class="table-head__left">
                    <img :src="tableImg" class="table-head__img">
                    <h1 class="table-head__heading">Cihazlar <a @click.prevent="createDevice"><img :src="addImg" alt=""></a></h1>
                </div>
                <div class="table-head__right">
                    <input type="text" class="table-head__input" v-model="searchDevice" placeholder="Ara...">
                </div>
            </div>
            <div class="table-content">
                <div class="table-content__header table-content__header--big">
                    <div class="table-content__header-item">Marka</div>
                    <div class="table-content__header-item">Model</div>
                    <div class="table-content__header-item">Seri No.</div>
                    <div class="table-content__header-item">Üretim Yılı</div>
                    <div class="table-content__header-item">İşlemler</div>
                </div>
                <transition name="fade" mode="out-in" v-if="devices.length === 0">
                    <div class="table-not-found" key="1">
                        <img class="table-not-found__img" :src="deviceNotFoundImg" alt="Demirbaşlar Bulunamadı">
                        <p class="table-not-found__text">Cihaz eklemek için cihazlar yanındaki artıya basın</p>
                    </div>
                </transition>
                <transition name="fade" mode="out-in" v-else>
                    <div>
                        <div v-for="device in devices" :key="device.deviceID" class="table-content__row table-content__row--big">
                            <div class="table-content__row-item">{{ device.brand.brandName }}</div>
                            <div class="table-content__row-item">{{ device.model.modelName }}</div>
                            <div class="table-content__row-item">{{ device.deviceSerialNumber }}</div>
                            <div class="table-content__row-item">{{ device.deviceProductionYear }}</div>
                            <div class="table-content__row-item">
                                <a @click.prevent="deleteDevice(device)" href="" class="pocket__action-item pocket__action-item--red">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.89" height="17.654" viewBox="0 0 15.89 17.654">
                                        <path class="pocket__svg" d="M1711.531-8588.383a2.573,2.573,0,0,1-2.6-2.5l-.112-1.112-.2-2.155-.2-2.367-.229-2.84a.654.654,0,0,1,.592-.713.645.645,0,0,1,.68.516l.028.262.216,2.663.235,2.761c.106,1.194.2,2.161.27,2.84a1.282,1.282,0,0,0,1.343,1.319c2.164.048,4.393.05,6.713.007a1.3,1.3,0,0,0,1.4-1.333l.112-1.105c.032-.339.067-.715.106-1.125l.235-2.713.283-3.486a.656.656,0,0,1,.609-.606l.086,0a.653.653,0,0,1,.594.622l-.009.182-.279,3.4-.289,3.317c-.063.663-.117,1.223-.164,1.661a2.592,2.592,0,0,1-2.661,2.507q-1.711.032-3.36.032Q1713.2-8588.347,1711.531-8588.383Zm7.9-13.214h-11.784a.654.654,0,0,1-.646-.661.658.658,0,0,1,.557-.656l.089-.007h2.812a.774.774,0,0,0,.732-.537l.026-.1.218-1.114a1.759,1.759,0,0,1,1.551-1.324l.138-.007h3.644a1.755,1.755,0,0,1,1.654,1.229l.043.143.21,1.073a.78.78,0,0,0,.659.629l.1.006h2.814a.656.656,0,0,1,.646.663.661.661,0,0,1-.559.657l-.086,0Zm-1.913-1.324a2.088,2.088,0,0,1-.076-.23l-.032-.146-.2-1.032a.454.454,0,0,0-.365-.339l-.076-.009h-3.644a.468.468,0,0,0-.41.246l-.022.061-.209,1.073a2.122,2.122,0,0,1-.109.376Z" transform="translate(-1707 8606.001)" fill="#8d909d"/>
                                    </svg>
                                </a>
                                <a @click.prevent="updateDevice(device)" href="" class="pocket__action-item pocket__action-item--blue">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                        <path class="pocket__svg" d="M1716.889-8589a.635.635,0,0,1-.622-.646.638.638,0,0,1,.539-.639l.084,0h6.489a.632.632,0,0,1,.622.644.638.638,0,0,1-.536.642l-.086,0Zm-9.267,0a.6.6,0,0,1-.449-.2.65.65,0,0,1-.173-.462l.1-3.942a2.942,2.942,0,0,1,.82-1.98l9.153-9.5a2.943,2.943,0,0,1,2.132-.916,2.943,2.943,0,0,1,2.132.916l1.222,1.271a3.168,3.168,0,0,1,.885,2.214,3.168,3.168,0,0,1-.885,2.214l-9.187,9.535a2.73,2.73,0,0,1-1.981.851Zm1.173-5.671a1.645,1.645,0,0,0-.455,1.1l-.08,3.278h3.125a1.53,1.53,0,0,0,1-.378l.1-.095,7.6-7.888-3.729-3.869Zm12.172-4.892.71-.737a1.869,1.869,0,0,0,.518-1.3,1.866,1.866,0,0,0-.518-1.3l-1.224-1.268a1.727,1.727,0,0,0-1.252-.54,1.728,1.728,0,0,0-1.253.54l-.71.737Z" transform="translate(-1707 8606.001)" fill="#8d909d"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div class="pagination pagination--extra-margin">
                            <a @click.prevent="readDevices('prev')" href="" class="pagination__item pagination__item--active pagination__item--left">
                                <svg xmlns="http://www.w3.org/2000/svg" width="6.896" height="12.26" viewBox="0 0 6.896 12.26">
                                    <path class="pagination__svg" d="M6.708,12.075a.622.622,0,0,0,.062-.821l-.062-.071L1.553,6.13,6.708,1.077A.622.622,0,0,0,6.77.255L6.708.185A.653.653,0,0,0,5.87.124L5.8.185l-5.61,5.5a.622.622,0,0,0-.062.821l.062.071,5.61,5.5A.652.652,0,0,0,6.708,12.075Z" fill="#1e202b"/>
                                </svg>
                                <div class="pagination__name">Önceki</div>
                            </a>
                            <a @click.prevent="readDevices('next')" href="" class="pagination__item pagination__item--active pagination__item--right">
                                <div class="pagination__name">Sonraki</div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6.896" height="12.26" viewBox="0 0 6.896 12.26">
                                    <path class="pagination__svg" d="M.188,12.075a.622.622,0,0,1-.062-.821l.062-.071L5.343,6.13.188,1.077A.622.622,0,0,1,.126.255L.188.185A.653.653,0,0,1,1.026.124L1.1.185l5.61,5.5a.622.622,0,0,1,.062.821l-.062.071-5.61,5.5A.652.652,0,0,1,.188,12.075Z" fill="#1e202b"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'
import router from '../../../router/router'

export default {
    data() {
        return {
            /* Devices */
            searchDevice: '',

            /* Images */
            tableImg: require('../../../assets/img/device/device.svg'),
            addImg: require('../../../assets/img/util/add-circle.svg'),
            deviceNotFoundImg: require('../../../assets/img/device/device-main.svg'),
        }
    },
    /* Lifecycle */
    async created() { await this.readDevices() },
    destroyed() { store.commit('clearDevices') },
    /* Devices Operations */
    computed: { devices() { return store.getters.devices } },
    methods: {
        async readDevices(paginationType = null) {
            try { 
                await store.dispatch('readDevices', {
                    searchDevice: this.searchDevice,
                    paginationType
                });
            }
            catch(err) {
                store.commit('triggerError', err)
            }
        },
        createDevice() {
            router.push({ path: '/superadmin/devices/device-op' });
        },
        updateDevice(device) {
            router.push({ 
                path: '/superadmin/devices/device-op',
                query: { deviceID: device.deviceID }
            });
        },
        async deleteDevice(device) {
            try {
                if(!await store.dispatch('triggerPopup', 
                device.deviceSerialNumber + " seri no'lu cihazı silmek istiyor musunuz?"))
                    return;

                await store.dispatch('deleteDevice', device.deviceID);
                store.commit('triggerSuccess', 'Cihaz silindi');
            } catch(err) {
                store.commit('triggerError', err);
            }
        },
    },
    watch: {
        async searchDevice() {
            await this.readDevices();
        }
    }
}
</script>