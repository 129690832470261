<template>
    <div class="staff-menu menu menu--gray">
        <a @click.prevent="closeStaffMenu" class="menu__close" href=""><img :src="menuClose" alt=""></a>
        <div class="menu__header">
            <img :src="headerImg" class="menu__header-img">
            <h1 class="menu__header-heading">Teknik Personel</h1>
        </div>
        <ul class="menu__list menu__list--no-margin">
            <li v-for="staff in staffs" :key="staff.staffID" class="menu__list-item menu__list-item--large">
                <router-link :to="'/staff/' + staff.staffID" href="" class="menu__list-link">
                    <img class="menu__list-img" :src="profileImg"> {{ staff.staffName }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import store from '../../../store/store'

export default {
    props: ['isMenu'],
    data() {
        return {
            /* Images */
            homeImg: require('../../../assets/img/util/home.svg'),
            headerImg: require('../../../assets/img/staff/staff.svg'),
            profileImg: require('../../../assets/img/util/staff.svg'),
            logoutImg: require('../../../assets/img/util/logout-dark.svg'),
            menuClose: require('../../../assets/img/util/close.svg')
        }
    },
    computed: {
        staffs() { return store.getters.staffs }
    },
    methods: {
		signOut() {
			store.dispatch('signOut');
		},
        closeStaffMenu() {
            const staffMenu = document.querySelector('.staff-menu');
            if(!staffMenu) return;
            staffMenu.classList.remove('menu--active');
        },
    }
}
</script>