<template>
    <transition name="fade" mode="out-in" v-if="menuType == 0">
        <div key="1">
            <div class="menu">
                <a @click.prevent="closeMenu" class="menu__close" href=""><img :src="menuClose" alt=""></a>
                <div class="menu__header">
                    <img :src="headerRegionImg" class="menu__header-img">
                    <h1 class="menu__header-heading">Bölgeler</h1>
                </div>
                <ul class="menu__nested-list">
                    <div v-for="(region, regionIndex) in regions" :key="region.regionID" class="menu__nested-list-item">
                        <div :class="{'menu__nested-list-header--active': regionIndex === selectedRegion}" class="menu__nested-list-header">
                            <img :src="locationImg" alt="" class="menu__nested-list-img">
                            <a @click.prevent="selectedRegion = selectedRegion === regionIndex ? -1 : regionIndex" href="" class="menu__nested-list-link">{{ region.regionName }}</a>
                            <svg class="menu__nested-list-arrow" xmlns="http://www.w3.org/2000/svg" width="8.79" height="5.809" viewBox="0 0 8.79 5.809">
                                <path d="M0,0,3.688,3.688,0,7.375" transform="translate(8.083 0.707) rotate(90)" fill="none" stroke="#1E202B" stroke-width="2"/>
                            </svg>
                        </div>
                        <ul v-if="selectedRegion === regionIndex" class="menu__list menu__list--nested">
                            <li tag="li" v-for="hospital in hospitals.filter(hospital => hospital.regionID === region.regionID)" :key="hospital.hospitalID" class="menu__list-item">
                                <a @click.prevent="goToHospital(hospital.hospitalID)" href="" :class="{'menu__list-link--active': selectedHospital === hospital.hospitalID}" class="menu__list-link">
                                    {{ hospital.hospitalName }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </ul>
                <div class="menu__bottom">
                    <a @click.prevent="goTo('/admin')" to="/admin" class="menu-bottom-btn menu-bottom-btn--margin-right menu-bottom-btn--small menu-bottom-btn--green">
                        <img :src="dashboardImg" alt="">
                        <div class="menu-bottom-btn__description menu-bottom-btn__description--top-medium">Anasayfa</div>
                    </a>
                    <a @click.prevent="goTo('/superadmin')" v-if="user && user.admin" href="" class="menu-bottom-btn menu-bottom-btn--margin-right menu-bottom-btn--small menu-bottom-btn--blue">
                        <img :src="homeImg" alt="">
                        <div class="menu-bottom-btn__description menu-bottom-btn__description--top-big menu-bottom-btn__description--big">Superadmin</div>
                    </a>
                    <a href="" @click.prevent="signOut" class="menu-bottom-btn menu-bottom-btn--margin-right menu-bottom-btn--small menu-bottom-btn--red">
                        <img :src="logoutImg" alt="">
                        <div class="menu-bottom-btn__description menu-bottom-btn__description--top menu-bottom-btn__description--medium">Çıkış</div>
                    </a>
                </div>
            </div>
        </div>
    </transition>
    <transition name="fade" mode="out-in" v-else>
        <div>
            <div class="menu" key="2">
                <a @click.prevent="closeMenu" class="menu__close" href=""><img :src="menuClose" alt=""></a>
                <div class="menu__header">
                    <img :src="headerStaffImg" class="menu__header-img">
                    <h1 class="menu__header-heading">Teknik Personel</h1>
                </div>
                <ul class="menu__list menu__list--no-margin">
                    <li v-for="staff in staffs" :key="staff.staffID" class="menu__list-item menu__list-item--large">
                        <a @click.prevent="goToStaff(staff.staffID)" class="menu__list-link" :class="{'menu__list-link--active': selectedStaff === staff.staffID}">
                            <img :class="{'menu__list-img--active': selectedStaff === staff.staffID}" class="menu__list-img" :src="profileImg">
                            {{ staff.staffName }}
                        </a>
                    </li>
                </ul>
                <div class="menu__bottom">
                    <a @click.prevent="goTo('/admin')" to="/admin" class="menu-bottom-btn menu-bottom-btn--margin-right menu-bottom-btn--small menu-bottom-btn--green">
                        <img :src="dashboardImg" alt="">
                        <div class="menu-bottom-btn__description menu-bottom-btn__description--top-medium">Anasayfa</div>
                    </a>
                    <a @click.prevent="goTo('/superadmin')" v-if="user && user.admin" href="" class="menu-bottom-btn menu-bottom-btn--margin-right menu-bottom-btn--small menu-bottom-btn--blue">
                        <img :src="homeImg" alt="">
                        <div class="menu-bottom-btn__description menu-bottom-btn__description--top-big menu-bottom-btn__description--big">Superadmin</div>
                    </a>
                    <a href="" @click.prevent="signOut" class="menu-bottom-btn menu-bottom-btn--margin-right menu-bottom-btn--small menu-bottom-btn--red">
                        <img :src="logoutImg" alt="">
                        <div class="menu-bottom-btn__description menu-bottom-btn__description--top menu-bottom-btn__description--medium">Çıkış</div>
                    </a>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { db } from '../../../firebase/firebase'
import store from '../../../store/store'
import router from '../../../router/router'

export default {
    data() {
        return {
            /* MenuType */
            menuType: 0,
            
            /* Staff */
            selectedStaff: -1,

            /* Region and Hospital */
            selectedRegion: -1,
            selectedHospital: -1,

            /* Images */
            headerRegionImg: require('../../../assets/img/region/region.svg'),
            headerStaffImg: require('../../../assets/img/staff/staff.svg'),
            locationImg: require('../../../assets/img/util/location.svg'),
            profileImg: require('../../../assets/img/util/staff.svg'),
            // logoutImg: require('../../../assets/img/util/logout-dark.svg'),
			homeImg: require('../../../assets/img/util/home.svg'),
			dashboardImg: require('../../../assets/img/util/dashboard.svg'),
			logoutImg: require('../../../assets/img/util/logout.svg'),
            menuClose: require('../../../assets/img/util/close.svg')
        }
    },
    async created() {
        if(router.currentRoute.path.search('/staff/') !== -1)
            this.menuType = 1;

        try {
            if(this.menuType == 0) {
                /* Reading Hospital */
                if(router.currentRoute.path.search('hospital') === -1)
                    return;

                const hospital = await db.collectionGroup('hospitals').where('hospitalID', '==', router.currentRoute.params.hospitalID).get();
                if(hospital.empty)
                    throw new Error();

                this.selectedHospital = router.currentRoute.params.hospitalID;
            } else if(this.menuType == 1) {
                /* Reading Staff */
                if(router.currentRoute.path.search('/staff/') === -1)
                    return;

                const staff = await db.collection('staff_new').doc(router.currentRoute.params.staffID).get();
                if(!staff.exists)
                    router.push((this.user && this.user.admin) ? '/admin' : '/staff');

                this.selectedStaff = router.currentRoute.params.staffID;
            }
        } catch { this.clearSelectedHospital() }
    },
    computed: { 
        staffs() { return store.getters.staffs },
        regions() { return store.getters.regions },
        hospitals() { return store.getters.hospitals },
        user() { return store.getters.user }
    },
    methods: {
		signOut() {
			store.dispatch('signOut');
		},
        goTo(path) {
            router.push(path);
            this.closeMenu();
        },
        goToHospital(hospitalID) {
            router.push('/hospital/' + hospitalID);
            this.closeMenu();
        },
        goToStaff(staffID) {
            router.push('/staff/' + staffID);
            this.closeMenu();
        },
        closeMenu() {
            const menu = document.querySelector('.menu');
            if(!menu) return;
            menu.classList.remove('menu--active');
        },
        assignStaff(staffID) {
            if(!staffID) {
                this.redirectToDashboard();
                return;
            }

            const selectedStaffIndex = this.staffs.findIndex(staff => staff.staffID == staffID)
            if(selectedStaffIndex === -1) {
                this.redirectToDashboard();
                return;
            }

            this.selectedStaff = this.staffs[selectedStaffIndex].staffID;
        },
        assignHospital(hospitalID) {
            if(!hospitalID) {
                this.clearSelectedHospital();
                return;
            }

            const selectedHospitalIndex = this.hospitals.findIndex(hospital => hospital.hospitalID == hospitalID)
            if(selectedHospitalIndex === -1) {
                this.clearSelectedHospital();
                return;
            }

            this.selectedHospital = this.hospitals[selectedHospitalIndex].hospitalID;
        },
        clearSelectedHospital() {
            this.selectedHospital = -1;
            this.redirectToDashboard();
        },
        redirectToDashboard() {
            if((this.user && this.user.admin) && router.currentRoute.path !== '/admin')
                router.push('/admin');

            if(!(this.user && this.user.admin) && router.currentRoute.path !== '/staff')
                router.push('/staff');
        }
    },
    watch: {
        $route(to){
            if(router.currentRoute.path.search('/staff/') !== -1) {
                this.menuType = 1;
                this.assignStaff(to.params.staffID);
            }
            else { 
                this.menuType = 0;
                this.assignHospital(to.params.hospitalID);
            }
        }
    }
}
</script>