<template>
    <div class="flex-component flex-component--flex flex-component--responsive">
        <app-service-form></app-service-form>
        <app-inventory-status></app-inventory-status>
    </div>
</template>

<script>
import ServiceForm from './ServiceForm.vue'
import InventoryStatus from './InventoryStatus.vue'

export default {
    components: {
        'app-service-form': ServiceForm,
        'app-inventory-status': InventoryStatus
    }
}
</script>