import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import { auth } from './firebase/firebase'
import store from './store/store'

Vue.config.productionTip = false;

let app;

auth.onAuthStateChanged(async user => {
	if(user) { await store.dispatch('signInHelper', user) }
	else { await store.dispatch('signOutHelper') }

	if(!app) {
		app = new Vue({
			store,
			router,
			render: h => h(App)
		});
		
		app.$mount('#app');
	}
});
