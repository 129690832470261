/* Import SuperAdminView */
import SuperAdminView from '../../components/superadmin/SuperAdminView.vue'

/* Import Children Routes */
import dashboardRoutes from './dashboardRoutes'
import devicesRoutes from './devicesRoutes'
import equipmentsRoutes from './equipmentsRoutes'
import fixedRoutes from './fixedRoutes'
import inventoryRoutes from './inventoryRoutes'
import regionsRoutes from './regionsRoutes'
import staffRoutes from './staffRoutes'
import brandsRoutes from './brandsRoutes'

const routes = {
    path: '/superadmin',
    component: SuperAdminView,
    children: [
        dashboardRoutes,
        devicesRoutes,
        equipmentsRoutes,
        fixedRoutes,
        inventoryRoutes,
        regionsRoutes,
        staffRoutes,
        brandsRoutes
    ],
    meta: {
        requiresAuth: true,
        requiresAdmin: true
    }
}

export default routes;
