<template>
    <div class="inventory">
        <div class="table">
            <div class="table-head">
                <div class="table-head__left">
                    <img :src="tableImg" class="table-head__img">
                    <h1 class="table-head__heading">Stoklar <a @click.prevent="addTransferInventory" href=""><img :src="addImg" alt=""></a></h1>
                </div>
                <div class="table-head__right">
                    <input type="text" class="table-head__input" v-model="searchInventory" placeholder="Ara...">
                </div>
            </div>
            <div class="table-content">
                <div class="table-content__header">
                    <div class="table-content__header-item">Parça Kodu</div>
                    <div class="table-content__header-item">Parça Adı</div>
                    <div class="table-content__header-item">Seri No.</div>
                    <div class="table-content__header-item">Stok Miktarı</div>
                </div>
                <transition name="fade" mode="out-in" v-if="inventoryEquipments.length === 0">
                    <div class="table-not-found" key="1">
                        <img class="table-not-found__img" :src="inventoryNotFoundImg" alt="Stoklar Bulunamadı">
                        <p class="table-not-found__text">Stok eklemek için stokların yanındaki artıya basın</p>
                    </div>
                </transition>
                <transition name="fade" mode="out-in" v-else>
                    <div>
                        <div v-for="inventoryEquipment in inventoryEquipments" :key="inventoryEquipment.equipment.equipmentSerialNumber" class="table-content__row">
                            <div class="table-content__row-item">{{ inventoryEquipment.equipment.equipmentCode }}</div>
                            <div class="table-content__row-item">{{ inventoryEquipment.equipment.equipmentName }}</div>
                            <div class="table-content__row-item">{{ inventoryEquipment.equipment.equipmentSerialNumber }}</div>
                            <div class="table-content__row-item">{{ inventoryEquipment.inventoryQuantity }}</div>
                        </div>
                        <div class="pagination pagination--extra-margin">
                            <a @click.prevent="readInventoryEquipments('prev')" href="" class="pagination__item pagination__item--active pagination__item--left">
                                <svg xmlns="http://www.w3.org/2000/svg" width="6.896" height="12.26" viewBox="0 0 6.896 12.26">
                                    <path class="pagination__svg" d="M6.708,12.075a.622.622,0,0,0,.062-.821l-.062-.071L1.553,6.13,6.708,1.077A.622.622,0,0,0,6.77.255L6.708.185A.653.653,0,0,0,5.87.124L5.8.185l-5.61,5.5a.622.622,0,0,0-.062.821l.062.071,5.61,5.5A.652.652,0,0,0,6.708,12.075Z" fill="#1e202b"/>
                                </svg>
                                <div class="pagination__name">Önceki</div>
                            </a>
                            <a @click.prevent="readInventoryEquipments('next')" href="" class="pagination__item pagination__item--active pagination__item--right">
                                <div class="pagination__name">Sonraki</div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6.896" height="12.26" viewBox="0 0 6.896 12.26">
                                    <path class="pagination__svg" d="M.188,12.075a.622.622,0,0,1-.062-.821l.062-.071L5.343,6.13.188,1.077A.622.622,0,0,1,.126.255L.188.185A.653.653,0,0,1,1.026.124L1.1.185l5.61,5.5a.622.622,0,0,1,.062.821l-.062.071-5.61,5.5A.652.652,0,0,1,.188,12.075Z" fill="#1e202b"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'
import router from '../../../router/router'

export default {
    data() {
        return {
            /* Search Inventory */
            searchInventory: '',

            /* Images */
            tableImg: require('../../../assets/img/inventory/inventory.svg'),
            addImg: require('../../../assets/img/util/add-circle.svg'),
            downArrowImg: require('../../../assets/img/util/down-arrow.svg'),
            inventoryNotFoundImg: require('../../../assets/img/inventory/inventory-main.svg'),
        }
    },
    async created() {
        await this.readInventoryEquipments();
    },
    destroyed() {
        store.commit('clearInventory');
    },
    computed: {
        inventoryEquipments() { return store.getters.inventoryEquipments }
    },
    methods: {
        async readInventoryEquipments(paginationType = null) {
            try { 
                await store.dispatch('readInventoryEquipments', {
                    inventoryGeneral: true,
                    searchInventory: this.searchInventory,
                    paginationType
                });
            }
            catch(err) {
                router.push('/superadmin');
                store.commit('triggerError', err);
            }
        },
        addTransferInventory() {
            router.push('/superadmin/inventory/inventory-op');
        },
    },
    watch: {
        async searchInventory() {
            await this.readInventoryEquipments();
        }
    }
}
</script>