<template>
    <div class="add-container">
        <div class="sidebyside">
            <div class="sidebyside__left sidebyside__left--big">
                <div class="sidebyside__top sidebyside__top--center">
                    <div class="sidebyside__heading-container">
                        <img :src="equipmentImg" alt="" class="sidebyside__heading-img">
                        <h1 class="sidebyside__heading">{{ operationHeadingText }}</h1>
                    </div>
                    <a @click.prevent="openExcelImportPopup" v-if="operationType === 'create'" href="" class="sidebyside__import-excel">excel import</a>
                </div>
                <form action="" class="sidebyside__form">
                    <div class="sidebyside__form-group">
                        <input :disabled="operationType === 'update'" v-model="brandName" type="text" name="create-equipment-brand" id="create-equipment-brand" placeholder="Marka" autocomplete="off">
                        <div class="dropdown" :class="{'dropdown--active': brandsDropdown.length > 0}">
                            <div @click.prevent="selectBrand(brand)" v-for="brand in brandsDropdown" :key="brand.brandID" class="dropdown__item">{{ brand.brandName }}</div>
                        </div>
                    </div>
                    <div class="sidebyside__form-group">
                        <input :disabled="operationType === 'update'" v-model="modelName" type="text" name="create-equipment-model" id="create-equipment-model" placeholder="Model" autocomplete="off">
                        <div class="dropdown" :class="{'dropdown--active': modelsDropdown.length > 0}">
                            <div @click.prevent="selectModel(model)" v-for="model in modelsDropdown" :key="model.modelID" class="dropdown__item">{{ model.modelName }}</div>
                        </div>
                    </div>
                    <div class="sidebyside__form-group">
                        <input v-model="equipmentObj.equipmentName" type="text" name="create-equipment-name" id="create-equipment-name" placeholder="Parça Adı" autocomplete="off">
                    </div>
                    <div class="sidebyside__form-group">
                        <input v-model="equipmentObj.equipmentCode" type="text" name="create-equipment-code" id="create-equipment-code" placeholder="Parça Kodu" autocomplete="off">
                    </div>
                    <div class="sidebyside__form-group">
                        <input v-model="equipmentObj.equipmentSerialNumber" type="text" name="create-equipment-serial-number" id="create-equipment-serial-number" placeholder="Parça Seri No." autocomplete="off">
                    </div>
                    <button :class="{'disabled-btn': dbController}" @click.prevent="createUpdateEquipment" class="sidebyside__submit"><div v-if="dbController" class="loading"></div>{{ operationButtonText }}</button>
                </form>
            </div>
            <div class="sidebyside__right">
                <img :src="equipmentMainImg" alt="Yeni Parça Ekle / Düzenle">
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'
import router from '../../../router/router'

export default {
    data() {
        return {
            dbController: false,

            /* Equipment */
            equipmentObj: {
                equipmentRef: '',
                equipmentID: '',
                equipmentCode: '',
                equipmentName: '',
                equipmentSerialNumber: '',
                brand: {
                    brandID: '',
                    brandName: ''
                },
                model: {
                    modelID: '',
                    modelName: ''
                },
            },

            /* Brands */
            brandsDropdown: [],
            brandName: '',

            /* Models */
            modelsDropdown: [],
            modelName: '',

            /* Operation Type */
            operationType: '',

            /* Operation Type Texts */
            operationButtonText: '',
            operationHeadingText: '',

            /* Images */
            equipmentMainImg: require('../../../assets/img/equipment/equipment-main.svg'),
            equipmentImg: require('../../../assets/img/equipment/equipment.svg'),
        }
    },
    async created() {
        this.equipmentObj.equipmentID = router.currentRoute.query.equipmentID;
        if(this.equipmentObj.equipmentID) {
            try {
                this.operationType = 'update';
                this.equipmentObj = (await store.dispatch('getEquipmentByID', this.equipmentObj.equipmentID));
                this.equipmentObj.equipmentOriginalSerialNumber = this.equipmentObj.equipmentSerialNumber;
                this.brandName = this.equipmentObj.brand.brandName;
                this.modelName = this.equipmentObj.model.modelName;
            } catch(err) {
                store.commit('triggerError', err);
                this.redirectToEquipments();
            }
        } else this.operationType = 'create';
    },
    computed: {
        brands() { return store.getters.brands },
        models() { return store.getters.models },
    },
    methods: {
        createDropdownBrands() {
            if(!this.brandName || this.brandName === this.equipmentObj.brand.brandName)
                this.brandsDropdown = [];
            else
                this.brandsDropdown = this.brands.filter(brand => brand.brandName.toLocaleLowerCase('tr-TR').search(this.brandName.toLocaleLowerCase('tr-TR')) > -1);
        },
        createDropdownModels() {
            if(!this.modelName || this.modelName === this.equipmentObj.model.modelName)
                this.modelsDropdown = [];
            else
                this.modelsDropdown = this.models.filter(model => model.modelName.toLocaleLowerCase('tr-TR').search(this.modelName.toLocaleLowerCase('tr-TR')) > -1 && model.brandID === this.equipmentObj.brand.brandID);
        },
        selectBrand(selectedBrand) {
            if(!selectedBrand) return;
            this.equipmentObj.brand = {};
            this.equipmentObj.brand.brandID = selectedBrand.brandID;
            this.equipmentObj.brand.brandName = selectedBrand.brandName;
            this.brandName = selectedBrand.brandName;
            this.brandsDropdown = [];
        },
        selectModel(selectedModel) {
            if(!selectedModel) return;
            this.equipmentObj.model = {};
            this.equipmentObj.model.modelID = selectedModel.modelID;
            this.equipmentObj.model.modelName = selectedModel.modelName;
            this.modelName = selectedModel.modelName;
            this.modelsDropdown = [];
        },
        /* Excel Add */
        openExcelImportPopup() { 
            store.commit('updateJSONPopup', { 
                openDialog: true, 
                redirectFunction: this.redirectToEquipments, 
                addFunction: this.createEquipmentWithoutRedirection
            });
        },
        async createEquipmentWithoutRedirection(equipmentObj) {
            try {
                if(!equipmentObj) {
                    store.commit('triggerError', 'Eklenecek parça bulunamadı');
                    return;
                }
                
                await store.dispatch('createEquipmentJSON', equipmentObj);
                store.commit('triggerSuccess', equipmentObj.equipmentSerialNumber + " seri no'lu parça eklendi");
            } catch(err) { store.commit('triggerError', equipmentObj.equipmentSerialNumber + " seri no'lu parça eklenemedi") }
        },
        /* Equipment Operations */
        async createUpdateEquipment() {
            this.dbController = true;
            if(this.operationType === 'create') await this.createEquipment();
            else if(this.operationType === 'update') await this.updateEquipment();
            this.dbController = false;
        },
        async createEquipment() {
            try {
                await store.dispatch('createEquipment', this.equipmentObj);
                this.redirectToEquipments();
                store.commit('triggerSuccess', 'Parça eklendi');
            } catch(err) { store.commit('triggerError', err) }
        },
        async updateEquipment() {
            try {
                await store.dispatch('updateEquipment', this.equipmentObj);
                this.redirectToEquipments();
                store.commit('triggerSuccess', 'Parça güncellendi');
            } catch(err) { store.commit('triggerError', err) }
        },
        /* Redirection */
        redirectToEquipments() {
            router.push('/superadmin/equipments');
        }
    },
    watch: {
        operationType() {
            if(this.operationType === 'create') {
                this.operationButtonText = 'parçayı ekle';
                this.operationHeadingText = 'Parça Ekle';
            } else if(this.operationType === 'update') {
                this.operationButtonText = 'düzenlemeyi bitir';
                this.operationHeadingText = 'Parça Düzenle';
            }
        },
        brandName() {
            if(this.equipmentObj.brand.brandName !== this.brandName) {
                this.equipmentObj.brand.brandName = '';
                this.equipmentObj.brand.brandID = '';
                this.equipmentObj.modelName = '';
                this.equipmentObj.modelID = '';
                this.modelName = '';
            }
            this.createDropdownBrands();
        },
        modelName() {
            this.createDropdownModels();
        },
    }
}
</script>