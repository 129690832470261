<template>
    <div class="add-container">
        <div class="sidebyside">
            <div class="sidebyside__left">
                <div class="sidebyside__top sidebyside__top--center">
                    <div class="sidebyside__heading-container">
                        <img :src="hospitalImg" alt="" class="sidebyside__heading-img">
                        <h1 class="sidebyside__heading">{{ operationHeadingText }}</h1>
                    </div>
                    <a @click.prevent="openExcelImportPopup" v-if="operationType === 'create'" href="" class="sidebyside__import-excel">excel import</a>
                </div>
                <form action="" class="sidebyside__form">
                    <div class="sidebyside__form-group">
                        <input v-model="hospitalObj.hospitalName" type="text" name="create-hospital-name" id="create-hospital-name" placeholder="Hastane Adı" autocomplete="off">
                    </div>
                    <button :class="{'disabled-btn': dbController}" @click.prevent="createOrUpdateHospital" class="sidebyside__submit"><div v-if="dbController" class="loading"></div>{{ operationButtonText }}</button>
                </form>
            </div>
            <div class="sidebyside__right">
                <img :src="hospitalMainImg" alt="Yeni Hastane Ekle / Düzenle">
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'
import router from '../../../router/router'

export default {
    data() {
        return {
            dbController: false,

            /* Hospital Operations */
            hospitalObj: {
                regionID: '',
                hospitalID: '',
                hospitalName: '',
                hospitalOriginalName: ''
            },

            /* Region Name */
            regionName: '',

            /* Operation Type */
            operationType: '',

            /* Operation Type Texts */
            operationButtonText: '',
            operationHeadingText: '',

            /* Images */ 
            hospitalMainImg: require('../../../assets/img/hospital/hospital-main.svg'),
            hospitalImg: require('../../../assets/img/hospital/hospital.svg'),
        }
    },
    async created() {
        this.hospitalObj.hospitalID = router.currentRoute.query.hospitalID;
        this.hospitalObj.regionID = router.currentRoute.query.regionID;
        this.regionName = router.currentRoute.query.regionName;

        if(this.hospitalObj.regionID && this.regionName && this.hospitalObj.hospitalID) {
            try {
                this.operationType = 'update';
                this.hospitalObj.hospitalOriginalName = this.hospitalObj.hospitalName = (await store.dispatch('getHospitalByID', this.hospitalObj)).hospitalName;
            } catch(err) {
                store.commit('triggerError', err);
                this.redirectToRegions();
            }
        }
        else if(this.hospitalObj.regionID && this.regionName) this.operationType = 'create';
        else this.operationType = 'unknown';
    },
    methods: {
        /* Excel Add */
        openExcelImportPopup() { 
            store.commit('updateJSONPopup', { 
                openDialog: true, 
                redirectFunction: this.redirectToRegions, 
                addFunction: this.createHospitalWithoutRedirection
            });
        },
        async createHospitalWithoutRedirection(hospitalObj) {
            try {
                if(!hospitalObj || !hospitalObj.hospitalName) {
                    store.commit('triggerError', 'Eklenecek hastanenin adı yok');
                    return;
                }

                await store.dispatch('createHospital', { regionID: this.hospitalObj.regionID, hospitalName: hospitalObj.hospitalName });
                store.commit('triggerSuccess', hospitalObj.hospitalName + ' hastanesi eklendi');
            } catch(err) { store.commit('triggerError', hospitalObj.hospitalName + ' hastanesi eklenemedi') }
        },
        /* Create Or Update */
        async createOrUpdateHospital() {
            this.dbController = true;
            if(this.operationType === 'create') await this.createHospital();
            else if(this.operationType === 'update') await this.updateHospital();
            else this.unknownOperationHandler();
            this.dbController = false;
        },
        async createHospital() {
            try {
                await store.dispatch('createHospital', this.hospitalObj);
                this.redirectToRegions();
                store.commit('triggerSuccess', 'Hastane eklendi');
            } catch(err) { store.commit('triggerError', err) }
        },
        async updateHospital() {
            try {
                await store.dispatch('updateHospital', this.hospitalObj);
                this.redirectToRegions();
                store.commit('triggerSuccess', 'Hastane güncellendi');
            } catch(err) { store.commit('triggerError', err) }
        },
        unknownOperationHandler() {
            store.commit('triggerError', 'Hastane bulunamadı');
            this.redirectToRegions();
        },
        redirectToRegions() {
            router.push('/superadmin/regions');
        },
    },
    watch: {
        operationType() {
            if(this.operationType === 'create') {
                this.operationButtonText = 'hastane ekle';
                this.operationHeadingText = this.regionName.toUpperCase() + '\nHastane Ekle';
            } else if(this.operationType === 'update') {
                this.operationButtonText = 'düzenlemeyi bitir';
                this.operationHeadingText = this.regionName.toUpperCase() + '\nHastane Düzenle';
            } else this.unknownOperationHandler();
        }
    }
}
</script>