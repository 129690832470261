<template>
    <div class="flex-component flex-component--flex flex-component--responsive">
        <app-hospitals-devices-and-fixeds-info :hospital="hospital"></app-hospitals-devices-and-fixeds-info>
        <app-hospital-devices-and-fixeds :hospital="hospital"></app-hospital-devices-and-fixeds>
    </div>
</template>

<script>
import store from '../../../store/store'
import router from '../../../router/router'
import { db } from '../../../firebase/firebase'
import HospitalDevicesAndFixeds from './HospitalDevicesAndFixeds.vue'
import HospitalDevicesAndFixedsInfo from './HospitalDevicesAndFixedsInfo.vue'

export default {
    components: {
        'app-hospital-devices-and-fixeds': HospitalDevicesAndFixeds,
        'app-hospitals-devices-and-fixeds-info': HospitalDevicesAndFixedsInfo,
    },
    data() {
        return {
            hospital: { hospitalName: '' }
        }
    },
    async created() { await this.selectHospital(router.currentRoute.params.hospitalID) },
    watch: { $route(to) { this.selectHospital(to.params.hospitalID) } },
    computed: { hospitals() { return store.getters.hospitals } },
    methods: {
        async selectHospital(hospitalID) {
            const index = this.hospitals.findIndex(hospital => hospital.hospitalID === hospitalID);
            if(index === -1) {
                const hospital = await db.collectionGroup('hospitals').where('hospitalID', '==', hospitalID).get();
                if(hospital.empty)
                    return;

                this.hospital = hospital.docs[0].data();
            } else this.hospital = this.hospitals[index];
        }
    }
}
</script>