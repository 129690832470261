<template>
    <div ref="ExcelToJSONPopup" class="popup popup--center">
        <div ref="ExcelToJSONPopupContainer" class="popup__container">
            <a @click.prevent="closeExcelImportPopup" href="" class="popup__close-btn"><img :src="closeBtnImg" alt=""></a>
            <h1 class="popup__text popup__text--heading">Excel Import'a Hoşgeldiniz</h1>
            <p class="popup__paragraph">Lütfen girilen alanların doğru olduğundan emin olun</p>
            <label for="excel-to-json-file" class="popup__excel-import">
                <img :src="uploadImg" alt="">
                <p class="popup__paragraph">{{ fileName ? fileName : 'Dosya Seç' }}</p>
            </label>
            <input ref="ExcelToJSONFile" :disabled="JSONController" @change="toJSON" style="display: none" type="file" accept=".xls, .xlsx" name="excel-to-json-file" id="excel-to-json-file">
            <a :class="{'disabled-btn': JSONController}" @click.prevent="addFromJSON" class="popup__btn popup__btn--top-margin" href=""><div v-if="JSONController" class="loading"></div>Excel'den Yükle</a>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap'
import store from '../../store/store'

export default {
    data() {
        return {
            fileName: '',
            JSONController: false,

            /* Images */
            closeBtnImg: require('../../assets/img/util/close-btn.svg'),
            uploadImg: require('../../assets/img/util/upload.svg')
        }
    },
    destroyed() { store.commit('clearJSONFile') },
    computed: { 
        JSONFile() { return store.getters.JSONFile },
        JSONPopup() { return store.getters.JSONPopup },
    },
    methods: {
        /* Excel Import Dialog */
        openExcelImportPopup() {
            let tl = gsap.timeline();
            tl.to(this.$refs.ExcelToJSONPopup, {duration: 0, display: 'flex'})
            .to(this.$refs.ExcelToJSONPopup, {duration: .5, opacity: 1})
            .to(this.$refs.ExcelToJSONPopupContainer, {duration: .5, opacity: 1, yPercent: -20}, '-=.4');
        },
        closeExcelImportPopup() {
            if(this.JSONController)
                return;
            
            this.fileName = '';
            this.$refs.ExcelToJSONFile.value = '';
            store.commit('clearJSONFile');
            let tl = gsap.timeline();
            tl.to(this.$refs.ExcelToJSONPopupContainer, { duration: .5, opacity: 0, yPercent: 20 })
            .to(this.$refs.ExcelToJSONPopup, { duration: .5, opacity: 0 }, '-=.4')
            .to(this.$refs.ExcelToJSONPopup, { duration: 0, display: 'none' });
        },
        toJSON(e) {
            this.fileName = e.target.files[0].name;
            store.dispatch('excelToJSON', e);
        },
        async addFromJSON() {
            if(!this.JSONFile) {
                store.commit('triggerError', 'Dosya seçilmedi veya seçilen dosya boş');
                return;
            }

            if(this.JSONFile.length > 500) {
                store.commit('triggerError', "Eklenecek boyut 500'ü geçmemeli");
                return;
            }

            this.JSONController = true;
            for(let i = 0; i < this.JSONFile.length; i++) {
                let createObj = {};
                const JSONFile = this.JSONFile[i];

                for(const key of Object.keys(JSONFile)) {
                    const objKey = key.split('/')[1];
                    createObj[objKey] = JSONFile[key];
                }
                
                await this.JSONPopup.addFunction(createObj);
            }

            this.JSONController = false;
            this.JSONPopup.redirectFunction();
            this.closeExcelImportPopup();
        },
    },
    watch: {
        'JSONPopup.openDialog'() {
            if(this.JSONPopup.openDialog)
                this.openExcelImportPopup()
        }
    }
}
</script>