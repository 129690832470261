<template>
    <div class="flex-component">
        <div class="menu-btns">
            <a @click.prevent="openMenu" class="menu-btn">
                <img :src="menuImg" alt="" class="menu-btn__img">
            </a>
            <a v-if="user && user.admin && this.$router.currentRoute.path.search('/admin') > -1" @click.prevent="openStaffMenu" class="menu-btn">
                <img :src="staffImg" alt="" class="menu-btn__img">
            </a>
        </div>
        <app-general-menu></app-general-menu>
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
import store from '../../../store/store'
import generalMenu from '../menus/GeneralMenu.vue'

export default {
    components: {
        'app-general-menu': generalMenu
    },
    data() {
        return {
            staffImg: require('../../../assets/img/util/staff.svg'),
            menuImg: require('../../../assets/img/util/menu.svg')
        }
    },
    computed: {
         user() { return store.getters.user },
    },
    methods: {
        openMenu() {
            const menu = document.querySelector('.menu');
            if(!menu) return;
            menu.classList.add('menu--active');
        },
        openStaffMenu() {
            const staffMenu = document.querySelector('.staff-menu');
            if(!staffMenu) return;
            staffMenu.classList.add('menu--active');
        }
    }
}
</script>