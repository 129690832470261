/* eslint-disable no-unused-vars */
import { db, equipments, hospitals, models } from '../firebase/firebase'
const brands = db.collection('brands_new');

const brandsStore = {
    state: () => ({
        brands: [],
        models: [],
        brandsSnapshot: null,
    }),
    mutations: {
        clearBrands(state) {
            if(state.brandsSnapshot)
                state.brandsSnapshot();
            
            state.brands.forEach(brand => { 
                if(brand.modelsSnapshot)
                    brand.modelsSnapshot();
            });

            state.brands = [];
            state.models = [];
        }
    },
    actions: {
        async readBrands({ dispatch, state }) {
            if(state.brandsSnapshot)
                state.brandsSnapshot();

            try {
                state.brandsSnapshot = brands.orderBy('brandName').onSnapshot(snapshot => {
                    snapshot.docChanges().forEach(async brand => {
                        const brandDoc = brand.doc;
                        if(brand.type === 'added') {
                            const brandObj = {
                                brandID: brandDoc.id,
                                brandName: brandDoc.data().brandName,
                                modelsSnapshot: null,
                            }

                            state.brands.push(brandObj);
                            try { await dispatch('readModels', brandObj)}
                            catch { throw new Error('Bazı markaların modelleri okunamadı') }
                        }

                        if(brand.type === 'removed')
                            state.brands.splice(state.brands.findIndex(brand => brand.brandID === brandDoc.id), 1);

                        if(brand.type === 'modified')
                            state.brands[state.brands.findIndex(brand => brand.brandID === brandDoc.id)].brandName = brandDoc.data().brandName;
                    });
                }); 
            } catch(err) {
                throw new Error("Markalar okunamadı");
            }
        },
        async createBrand({ dispatch }, brandName) {
            try {
                await dispatch('validateBrand', { brandName, type: 'create' });
                try {
                    const brand = brands.doc();
                    await brand.set({ brandID: brand.id, brandName })
                } catch { throw new Error('Marka eklenemedi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async updateBrand({ dispatch }, brandObj) {
            try {
                brandObj.type = 'update';
                await dispatch('validateBrand', brandObj);

                try { await brands.doc(brandObj.brandID).update({ brandName: brandObj.brandName }) }
                catch { throw new Error('Marka güncellenemedi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async validateBrand(context, brandObj) {
            try {
                if(!brandObj.brandName || (brandObj.type === 'update' && !brandObj.brandID))
                    throw new Error('Lütfen tüm alanları doldurun');

                const brandDuplicate = await brands.where('brandName', '==', brandObj.brandName).limit(1).get();

                if(!brandDuplicate.empty)
                    if(brandObj.type === 'create' || (brandObj.type === 'update' && brandDuplicate.docs[0].id !== brandObj.brandID))
                        throw new Error('Böyle bir marka zaten var');
            } catch(err) { throw new Error(err) }
        },
        async deleteBrand({ dispatch }, brandID) {
            try {
                if(!brandID)
                    throw new Error("Marka ID'si boş");
                
                if(!(await brands.doc(brandID).get()).exists)
                    throw new Error('Marka bulunamadı');

                let models = await brands.doc(brandID).collection('models').limit(500).get();
                while(models && models.size > 0) {
                    try { models.docs.forEach(async model => {  await dispatch('deleteModel', { brandID, ...model.data()}) }) }
                    catch(err) { throw new Error(err) }
                    models = await brands.doc(brandID).collection('models').limit(500).get();
                }

                try { await brands.doc(brandID).delete() }
                catch { throw new Error('Marka silinemedi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async getBrandByID(context, brandID) {
            try { return (await brands.doc(brandID).get()).data() }
            catch { throw new Error('Marka bulunamadı') }
        },
        async readModels({ state }, brand) {
            try {
                if(!brand.brandID) throw new Error("Marka ID'si boş");
                if(brand.modelsSnapshot) {
                    brand.modelsSnapshot();

                    models.forEach((model, index) => {
                        if(model.brandID === brand.brandID)
                            models.splice(index, 1);
                    });
                }

                brand.modelsSnapshot = brands.doc(brand.brandID)
                .collection('models').orderBy('modelName').onSnapshot(snapshot => {
                    snapshot.docChanges().forEach(async model => {
                        const modelDoc = model.doc;
                        if(model.type === 'added')
                            state.models.push({
                                brandID: brand.brandID,
                                modelID: modelDoc.id,
                                ...modelDoc.data()
                            });
                        
                        if(model.type === 'removed')
                            state.models.splice(state.models.findIndex(model => model.modelID === modelDoc.id), 1);

                        if(model.type === 'modified')
                            state.models[state.models.findIndex(model => model.modelID === modelDoc.id)].modelName = modelDoc.data().modelName;
                    });
                });
            } catch(err) {
                throw new Error("Modeller okunamadı");
            }
        },
        async createModel({ dispatch }, modelObj) {
            try {
                modelObj.type = 'create';
                await dispatch('validateModel', modelObj);

                try { 
                    const model = brands.doc(modelObj.brandID).collection('models').doc();
                    await model.set({ modelID: model.id, modelName: modelObj.modelName }) }
                catch { throw new Error('Model eklenemedi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async updateModel({ dispatch }, modelObj) {
            try {
                modelObj.type = 'update';
                await dispatch('validateModel', modelObj);

                try { await brands.doc(modelObj.brandID).collection('models')
                .doc(modelObj.modelID).update({ modelName: modelObj.modelName }) }
                catch { throw new Error('Model güncellenemedi') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async validateModel(context, modelObj) {
            try {
                if(!modelObj.brandID || !modelObj.modelName 
                || (modelObj.type === 'update' && !modelObj.modelID))
                    throw new Error('Lütfen tüm alanları doldurun');

                const modelDuplicate = await brands.doc(modelObj.brandID)
                .collection('models').where('modelName', '==', modelObj.modelName).limit(1).get();

                if(!modelDuplicate.empty)
                    if(modelObj.type === 'create' || (modelObj.type === 'update' && modelDuplicate.docs[0].id !== modelObj.modelID))
                        throw new Error('Böyle bir model zaten var');
            } catch(err) { throw new Error(err) }
        },
        async deleteModel({ dispatch }, modelObj) {
            try {
                if(!modelObj || !modelObj.modelID || !modelObj.brandID)
                    throw new Error("Model ID'si veya Marka ID'si boş");
                
                if(!(await brands.doc(modelObj.brandID).collection('models').doc(modelObj.modelID).get()))
                    throw new Error('Model bulunamadı');

                await dispatch('deleteEquipments', modelObj);
                await dispatch('deleteDevices', modelObj);

                try { await brands.doc(modelObj.brandID).collection('models').doc(modelObj.modelID).delete() }
                catch { throw new Error('Model silinemedi, lütfen tekrar deneyin') }
            } catch(err) {
                throw new Error(err);
            }
        },
        async getModelByID({ state }, modelObj) {
            try {
                return (await brands.doc(modelObj.brandID)
                .collection('models').doc(modelObj.modelID).get()).data();
            } catch { throw new Error("Model bulunamadı") }
        }
    },
    getters: {
        brands(state) { return state.brands },
        models(state) { return state.models }
    }
}

export default brandsStore;