/* Import Fixed */
import FixedView from '../../components/superadmin/fixed/FixedView.vue'
import Fixed from '../../components/superadmin/fixed/Fixed.vue'
import FixedOp from '../../components/superadmin/fixed/FixedOp.vue'

const routes = {
    path: 'fixed',
    component: FixedView,
    children: [
        {
            path: '/',
            component: Fixed,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
        {
            path: 'fixed-op',
            component: FixedOp,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
    ],
    meta: {
        requiresAuth: true,
        requiresAdmin: true
    }
}

export default routes;