<template>
    <div class="add-container">
        <div class="sidebyside">
            <div class="sidebyside__left">
                <div class="sidebyside__top sidebyside__top--center">
                    <div class="sidebyside__heading-container">
                        <img :src="staffImg" alt="" class="sidebyside__heading-img">
                        <h1 class="sidebyside__heading">{{ operationHeadingText }}</h1>
                    </div>
                    <a @click.prevent="openExcelImportPopup" v-if="operationType === 'create'" href="" class="sidebyside__import-excel">excel import</a>
                </div>
                <form action="" class="sidebyside__form">
                    <div class="sidebyside__form-group">
                        <input v-model="staffObj.staffName" type="text" name="create-staff-name" id="create-staff-name" placeholder="Personel Adı" autocomplete="off">
                    </div>
                    <div class="sidebyside__form-group">
                        <input :disabled="operationType === 'update'" v-model="staffObj.staffEmail" type="email" name="create-staff-email" id="create-staff-email" placeholder="Personel Emaili" autocomplete="off">
                    </div>
                    <div class="sidebyside__form-group">
                        <input v-model="staffObj.staffPassword" type="password" name="create-staff-password" id="create-staff-password" placeholder="Personel Şifresi" autocomplete="off">
                    </div>
                    <button :class="{'disabled-btn': dbController}" @click.prevent="createOrUpdateStaff" class="sidebyside__submit"><div v-if="dbController" class="loading"></div>{{ operationButtonText }}</button>
                </form>
            </div>
            <div class="sidebyside__right">
                <img :src="staffMainImg" alt="Yeni Personel Ekle / Düzenle">
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'
import router from '../../../router/router'

export default {
    data() {
        return {
            dbController: false,

            /* Region Operations */
            staffObj: {
                staffID: '',
                staffName: '',
                staffOriginalName: '',
                staffEmail: '',
                staffPassword: ''
            },

            staffNameChanged: false,
            staffPasswordChanged: false,

            /* Operation Type */
            operationType: '',

            /* Operation Type Texts */
            operationButtonText: '',
            operationHeadingText: '',

            /* Images */ 
            staffMainImg: require('../../../assets/img/staff/staff-main.svg'),
            staffImg: require('../../../assets/img/staff/staff.svg'),
        }
    },
    async created() {
        this.staffObj.staffID = router.currentRoute.query.staffID;

        if(this.staffObj.staffID) {
            try {
                this.operationType = 'update';
                this.staffObj = await store.dispatch('getStaffByID', this.staffObj.staffID);
                this.staffObj.staffOriginalName = this.staffObj.staffName;
            } catch(err) {
                store.commit('triggerError', err);
                this.redirectToRegions();
            }
        }
        else this.operationType = 'create';
    },
    methods: {
        /* Excel Add */
        openExcelImportPopup() { 
            store.commit('updateJSONPopup', { 
                openDialog: true, 
                redirectFunction: this.redirectToStaffs, 
                addFunction: this.createStaffWithoutRedirection
            });
        },
        async createStaffWithoutRedirection(staffObj) {
            try {
                if(!staffObj) {
                    store.commit('triggerError', 'Eklenecek personel bulunamadı');
                    return;
                }
                
                await store.dispatch('createStaff', staffObj);
                store.commit('triggerSuccess', staffObj.staffName + " isimli personel eklendi");
            } catch(err) { store.commit('triggerError', staffObj.staffName + " isimli personel eklenemedi") }
        },
        /* Create Or Update */
        async createOrUpdateStaff() {
            this.dbController = true;
            if(this.operationType === 'create') await this.createStaff();
            else if(this.operationType === 'update') await this.updateStaff();
            this.dbController = false;
        },
        async createStaff() {
            try {
                await store.dispatch('createStaff', this.staffObj);
                this.redirectToStaffs();
                store.commit('triggerSuccess', 'Personel eklendi');
            } catch(err) { store.commit('triggerError', err) }
        },
        async updateStaff() {
            try {
                if(this.staffNameChanged)
                    await store.dispatch('updateStaffName', this.staffObj);

                if(this.staffPasswordChanged)
                    await store.dispatch('updateStaffPassword', this.staffObj);

                this.redirectToStaffs();
                store.commit('triggerSuccess', 'Personel güncellendi');
            } catch(err) { store.commit('triggerError', err) }
        },
        constructUpdateText() {
            if(this.staffNameChanged && this.staffPasswordChanged)
                this.operationButtonText = 'personel adını ve parolasını güncelle';
            else if(this.staffNameChanged)
                this.operationButtonText = 'personel adını güncelle';
            else if(this.staffPasswordChanged)
                this.operationButtonText = 'personel şifresini güncelle';
            else
                this.operationButtonText = 'güncellemeyi bitir';
        },
        redirectToStaffs() {
            router.push('/superadmin/staff');
        },
    },
    watch: {
        operationType() {
            if(this.operationType === 'create') {
                this.operationButtonText = 'personel ekle';
                this.operationHeadingText = 'Teknik Personel Ekle';
            } else if(this.operationType === 'update') {
                this.operationButtonText = 'düzenlemeyi bitir';
                this.operationHeadingText = 'Teknik Personel\nDüzenle';
            }
        },
        'staffObj.staffName'() {
            if(this.operationType === 'create')
                return;

            this.staffObj.staffName === this.staffObj.staffOriginalName ? 
            this.staffNameChanged = false : this.staffNameChanged = true;
        },
        'staffObj.staffPassword'() {
            if(this.operationType === 'create')
                return;

            this.staffObj.staffPassword ? this.staffPasswordChanged = true : this.staffPasswordChanged = false;
        },
        staffNameChanged() {
            if(this.operationType === 'create')
                return;

            this.constructUpdateText();
        },
        staffPasswordChanged() {
            if(this.operationType === 'create')
                return;

            this.constructUpdateText();
        }
    }
}
</script>