<template>
    <div class="add-container">
        <div class="sidebyside">
            <div class="sidebyside__left">
                <div class="sidebyside__top sidebyside__top--center">
                    <div class="sidebyside__heading-container">
                        <img :src="inventoryImg" alt="" class="sidebyside__heading-img">
                        <h1 class="sidebyside__heading">{{ operationHeadingText }}</h1>
                    </div>
                </div>
                <div class="sidebyside__btn-container">
                    <a @click.prevent="operationType = 'add'" href="" :class="{'sidebyside__btn--inactive': operationType === 'transfer'}" class="sidebyside__btn">Stok Ekle</a>
                    <a @click.prevent="operationType = 'transfer'" href="" :class="{'sidebyside__btn--inactive': operationType === 'add'}" class="sidebyside__btn">Stok Aktar</a>
                </div>
                <form action="" class="sidebyside__form">
                    <div v-if="operationType === 'transfer'" class="sidebyside__form-group">
                        <input v-model="staffName" type="text" name="add-inventory-staff-name" id="add-inventory-staff-name" placeholder="Gönderilecek Personel" autocomplete="off">
                        <div class="dropdown" :class="{'dropdown--active': staffsDropdown.length > 0}">
                            <div @click.prevent="selectStaff(staff)" v-for="staff in staffsDropdown" :key="staff.staffID" class="dropdown__item">{{ staff.staffName }}</div>
                        </div>
                    </div>
                    <div class="sidebyside__form-group">
                        <input v-model="equipmentSerialNumber" type="text" name="add-inventory-serial-number" id="add-inventory-serial-number" placeholder="Parça Seri No" autocomplete="off">
                        <div class="dropdown" :class="{'dropdown--active': equipmentsDropdown.length > 0}">
                            <div @click.prevent="selectEquipment(equipment)" v-for="equipment in equipments" :key="equipment.equipmentID" class="dropdown__item">{{ equipment.equipmentSerialNumber }} / {{ equipment.equipmentName }}</div>
                        </div>
                    </div>
                    <div class="sidebyside__form-group">
                        <input v-model="inventoryObj.inventoryQuantity" type="number" name="add-inventory-equipment-quantity" id="add-inventory-equipment-quantity" placeholder="Miktar" autocomplete="off">
                    </div>
                    <button :class="{'disabled-btn': dbController}" @click.prevent="addTransferInventory" class="sidebyside__submit"><div v-if="dbController" class="loading"></div>{{ operationButtonText }}</button>
                </form>
            </div>
            <div class="sidebyside__right">
                <img :src="inventoryMainImg" alt="Yeni Stok Ekle">
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../../store/store'
import router from '../../../router/router'

export default {
    props: ['initialize', 'generalInventory'],
    data() {
        return {
            dbController: false,

            /* Inventory */
            inventoryObj: {
                inventoryQuantity: null,
                inventoryStaff: null,
                inventoryStaffName: '',
                equipment: {
                    equipmentID: '',
                    equipmentSerialNumber: '',
                    equipmentCode: '',
                    equipmentName: ''
                }
            },

            /* Staff */
            staffsDropdown: [],
            staffName: '',

            /* Equipments */
            equipmentsDropdown: [],
            equipmentSerialNumber: '',

            /* Operation Type */
            operationType: '',

            /* Operation Type Texts */
            operationButtonText: '',
            operationHeadingText: '',

            /* Images */
            inventoryMainImg: require('../../../assets/img/inventory/inventory-main.svg'),
            inventoryImg: require('../../../assets/img/inventory/inventory.svg'),
        }
    },
    created() {
        this.operationType = 'add';
    },
    destroyed() {
        store.commit('clearEquipments');
    },
    computed: { 
        staffs() { return store.getters.staffs },
        equipments() { return store.getters.equipments }
    },
    methods: {
        async addTransferInventory() {
            this.dbController = true;
            if(this.operationType === 'add') await this.addInventory();
            else if(this.operationType === 'transfer') await this.transferInventory();
            this.dbController = false;
        },
        /* Inventory Operations */
        async addInventory() {
            try {
                await store.dispatch('inboundInventoryOperation', this.inventoryObj);
                store.commit('triggerSuccess', 'Stok eklendi');
                this.redirectToInventory();
            } catch(err) {
                store.commit('triggerError', err);
            }
        },
        async transferInventory() {
            try {
                await store.dispatch('outboundInventoryOperation', this.inventoryObj);
                store.commit('triggerSuccess', 'Stok aktarıldı');
                this.redirectToInventory();
            } catch(err) {
                store.commit('triggerError', err);
            }
        },
        /* Selecting Staff And Equipment */
        selectEquipment(selectedEquipment) {
            if(!selectedEquipment) return;
            this.inventoryObj.equipment = { ...selectedEquipment };
            this.equipmentSerialNumber = selectedEquipment.equipmentSerialNumber;
            this.equipmentsDropdown = [];
        },
        selectStaff(selectedStaff) {
            if(!selectedStaff) return;
            this.inventoryObj.inventoryStaff = selectedStaff.staffID;
            this.inventoryObj.inventoryStaffName = selectedStaff.staffName;
            this.staffName = selectedStaff.staffName;
            this.staffsDropdown = [];
        },
        /* Creating Staff and Equipment Dropdown */
        async createDropdownEquipments() {           
            try {
                if(!this.equipmentSerialNumber || this.equipmentSerialNumber === this.inventoryObj.equipment.equipmentSerialNumber) {
                    this.equipmentsDropdown = [];
                    return;
                }

                await store.dispatch('readEquipments', { searchEquipment: this.equipmentSerialNumber });
                this.equipmentsDropdown = this.equipments;
            } catch { store.commit('triggerError', 'Parçalar okunamadı') }
        },
        createDropdownStaffs() {
            if(!this.staffName || this.staffName === this.inventoryObj.inventoryStaffName)
                this.staffsDropdown = [];
            else
                this.staffsDropdown = this.staffs.filter(staff => staff.staffName.toLocaleLowerCase('tr-TR').search(this.staffName.toLocaleLowerCase('tr-TR')) > -1);
        },
        /* Redirection */
        redirectToInventory() {
            router.push('/superadmin/inventory');
        }
    },
    watch: {
        operationType() {
            if(this.operationType === 'add') {
                this.operationButtonText = 'stok ekle';
                this.operationHeadingText = 'Stok Ekle';
            } else if(this.operationType === 'transfer') {
                this.operationButtonText = 'stok aktar';
                this.operationHeadingText = 'Stok Aktar';
            }
        },
        staffName() {
            this.createDropdownStaffs();
        },
        async equipmentSerialNumber() {
            await this.createDropdownEquipments();
        }
    }
}
</script>l