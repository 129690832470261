<template>
    <div id="login">
        <div class="sidebyside">
            <div class="sidebyside__left">
                <div class="sidebyside__heading-container">
                    <img :src="techSupportImg" alt="" class="sidebyside__heading-img">
                    <h1 class="sidebyside__subheading">
                        <img :src="turmedImg" alt="TURMED" class="sidebyside__heading-logo">
                        Teknik Servis
                    </h1>
                </div>
                <form action="" class="sidebyside__form">
                    <div class="sidebyside__form-group sidebyside__form-group--img">
                        <img :src="userImg" alt="">
                        <input v-model="email" type="email" name="login-email" id="login-email" placeholder="Email Adresi" autocomplete="off">
                    </div>
                    <div class="sidebyside__form-group sidebyside__form-group--img">
                        <img :src="passwordImg" alt="">
                        <input v-model="password" type="password" name="login-password" id="login-password" placeholder="Şifre" autocomplete="off">
                    </div>
                    <button :class="{'disabled-btn': dbController}" class="sidebyside__submit" @click.prevent="signIn"><div v-if="dbController" class="loading"></div>giriş yap</button>
                </form>
            </div>
            <div class="sidebyside__right">
                <img :src="loginImg" alt="Giriş Yap">
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../store/store'

export default {
    data() {
        return {
            dbController: false,

            email: '',
            password: '',
            loginImg: require('../../assets/img/login/main.svg'),
            userImg: require('../../assets/img/login/user.svg'),
            passwordImg: require('../../assets/img/login/password.svg'),
            turmedImg: require('../../assets/img/login/turmed-logo.svg'),
            techSupportImg: require('../../assets/img/login/tech-support.svg'),
        }
    },
    methods: {
        async signIn() {
            this.dbController = true;
            await store.dispatch('signIn', {
                email: this.email,
                password: this.password
            });
            this.dbController = false;
        },
    },
}
</script>