/* eslint-disable no-unused-vars */
import XLSX from 'xlsx'
import store from './store';

const excelToJSONStore = {
    state: () => ({
        JSONFile: null,
        JSONPopup: { 
            openDialog: false,
            redirectFunction: null, 
            addFunction: null
        }
    }),
    mutations: {
        updateJSONPopup(state, JSONPopup) {
            state.JSONPopup = { ...JSONPopup };
        },
        clearJSONFile(state) {
            state.JSONFile = null;
            state.JSONPopup = {
                openDialog: false,
                redirectFunction: null, 
                addFunction: null
            };
        }
    },
    actions: {
        excelToJSON({ state }, e) {
            const selectedFile = e.target.files[0];
            const fileReader = new FileReader();

            fileReader.onload = e => {
                const excelFile =  XLSX.read(e.target.result, { type: 'binary' });
                state.JSONFile = XLSX.utils.sheet_to_row_object_array(excelFile.Sheets[excelFile.SheetNames[0]]);
            };
        
            fileReader.onerror = () => { store.commit('triggerError', 'Dosya okunamadı') };
            fileReader.readAsBinaryString(selectedFile);
        }
    },
    getters: {
        JSONFile(state) { return state.JSONFile },
        JSONPopup(state) { return state.JSONPopup }
    }
}

export default excelToJSONStore;